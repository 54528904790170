import React from "react"
import {
  LineChart,
  AreaChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts"

const FXWidgetChart = ({ country, fxRate, launchDateIndex }) => {
  const fxRates = country.fxRates
    .map(({ value, date }) => {
      return {
        date,
        value: Math.round(value * 1000, 3) / 1000,
      }
    })
    .filter((_, idx) => idx > launchDateIndex)

  const CustomLabel = ({ label, color, viewBox: { x, y, height }, axis }) => {
    let xLoc = x + 5
    let yLoc = height

    if (axis === "Y") {
      xLoc = 40
      yLoc = y - 5
    }

    return (
      <g>
        <text
          x={xLoc}
          y={yLoc}
          fill={color}
          textAnchor="start"
          className="text-xs"
        >
          {label}
        </text>
      </g>
    )
  }

  const AxisTick = ({ x, y, payload: { value }, axis = "X" }) => {
    let label
    let dy = 16
    let textAnchor = "middle"

    if (axis === "X") {
      const date = new Date(value)

      if (date.getDate() === 1 && date.getMonth() === 0) {
        label = date.getFullYear()
      } else {
        label =
          date.toLocaleDateString("en", { month: "short" }) +
          " " +
          date.getDate()
      }
    } else {
      label = value
      textAnchor = "end"
      dy = 4
    }

    return (
      <g>
        <text
          x={x}
          y={y}
          dy={dy}
          textAnchor={textAnchor}
          className="text-xs"
          fill="#9CA3AF"
        >
          {label}
        </text>
      </g>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={300}
        data={fxRates}
        syncId="anyId"
        margin={{
          top: 5,
          right: 0,
          left: -20,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke="#E5E7EB" />
        <XAxis dataKey="date" scale="auto" tick={<AxisTick axis="X" />} />
        <YAxis
          domain={[country.min, country.max]}
          type="number"
          tick={<AxisTick axis="Y" />}
        />
        <ReferenceLine
          y={fxRate}
          stroke="#B91C1C"
          strokeDasharray={[5, 5]}
          label={
            <CustomLabel
              label="Exchange rate used in your price"
              color="#B91C1C"
              axis="Y"
            />
          }
        />
        <Tooltip />
        {/* <Legend /> */}
        <Area
          type="monotone"
          dataKey="value"
          dot={false}
          fill="url(#gradient)"
          fillOpacity={1}
          stroke="#1E3A8A"
        />

        <Brush
          dataKey="date"
          height={30}
          stroke="#1E3A8A"
          tickFormatter={d => {
            const date = new Date(d)
            return (
              date.toLocaleDateString("en", {
                month: "short",
              }) +
              " " +
              date.getDate()
            )
          }}
        />

        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default FXWidgetChart
