import React from "react"
import _ from "lodash"
import classNames from "classnames"

const Metric = ({ value, label }) => {
  return (
    <div>
      <span className="block pb-1 text-white text-5xl font-bold">
        {Math.abs(value)}%
      </span>
      <span
        className="block text-white text-base font-bold opacity-90 uppercase"
        dangerouslySetInnerHTML={{ __html: label }}
      ></span>
    </div>
  )
}

const NoRateMessage = () => (
  <div className="flex flex-col gap-y-4 py-16">
    <span className="text-center text-gray-50 text-5xl font-bold">
      Almost there!
    </span>
    <span className="text-center text-gray-300 text-2xl font-bold">
      Enter your product details to see your pricing analysis
    </span>
  </div>
)

const FXWidgetResults = ({
  market,
  fxRate,
  usMSRP,
  localMSRP,
  launchDateIndex,
}) => {
  const round = Math.round
  const rates = market.fxRates.filter((item, idx) => idx > launchDateIndex)
  const lowerRates = rates.filter(({ value }) => value < fxRate)

  const avgExchangeRate = _.sumBy(rates, "value") / rates.length
  const exchangeRateDifferential = round(100 * (fxRate / avgExchangeRate - 1))
  const exchangeRateDifferentialToday = round(
    100 * (fxRate / rates[rates.length - 1]?.value - 1)
  )
  const willingnessToPay = round(1.75 * exchangeRateDifferentialToday)
  const spentWithLowerExchangeRate = round(
    (lowerRates.length / rates.length) * 100
  )
  const moreExpensiveRelativetoPPP = round(
    exchangeRateDifferentialToday / market.ppp
  )

  const adjective = exchangeRateDifferential > 0 ? "Higher" : "Lower"
  const adverb = exchangeRateDifferential > 0 ? "More" : "Less"

  const ResultHeader = ({ children }) => (
    <div className="flex items-center justify-center w-full">
      <div
        className={classNames([
          "px-4 py-1 text-sm font-bold rounded-full uppercase transition duration-500 text-center w-full",
          fxRate && exchangeRateDifferential <= 0
            ? "text-green-900 bg-green-300"
            : "",
          fxRate && exchangeRateDifferential > 0
            ? "text-red-900 bg-red-300"
            : "",
          !fxRate || fxRate === 0 ? "text-gray-900 bg-gray-300" : "",
        ])}
      >
        {children}
      </div>
    </div>
  )

  return (
    <div
      className={classNames([
        "flex justify-center mt-8 px-0.5 w-full bg-gradient-to-b border rounded-lg shadow-2xl md:-mb-8 md:-ml-4 md:-mt-8 md:w-1/3 transition duration-500",
        fxRate && exchangeRateDifferential <= 0
          ? "from-green-600 to-green-900"
          : "",
        fxRate && exchangeRateDifferential > 0 ? "from-red-600 to-red-900" : "",
        !fxRate || fxRate === 0 ? "from-gray-600 to-gray-900" : "",
      ])}
    >
      <div
        className={classNames([
          "flex flex-col justify-center px-4 w-full bg-gradient-to-t rounded-lg md:px-6 transition duration-500",
          fxRate && exchangeRateDifferential <= 0
            ? "from-green-800 to-green-600"
            : "",
          fxRate && exchangeRateDifferential > 0
            ? "from-red-800 to-red-600"
            : "",
          !fxRate || fxRate === 0 ? "from-gray-800 to-gray-600" : "",
        ])}
      >
        {fxRate && (
          <div className="space-y-12">
            <div className="pt-8 space-y-4">
              <ResultHeader>The exchange rate in your price is</ResultHeader>
              <Metric
                value={exchangeRateDifferential}
                label={`${adjective} than an average rate since your launch`}
                isFirst
              />
              <Metric
                value={exchangeRateDifferentialToday}
                label={`${adjective} than the exchange rate today`}
              />
            </div>
            <div className="space-y-4">
              <ResultHeader>
                Actual exchange rate has been{" "}
                {exchangeRateDifferential < 0 ? "Higher" : "Lower"}
              </ResultHeader>

              <Metric
                value={spentWithLowerExchangeRate}
                label={"Of days since your launch"}
              />
            </div>
            <div className="pb-8 space-y-4">
              <ResultHeader>
                Your potential demand in {market.name} is
              </ResultHeader>
              <Metric
                value={willingnessToPay}
                label={`${
                  exchangeRateDifferential < 0 ? "Higher" : "Lower"
                } than potential demand in the US`}
              />
              <Metric
                value={moreExpensiveRelativetoPPP}
                label={`${
                  exchangeRateDifferential < 0 ? "Higher" : "Lower"
                } than potential demand in the US adjusted for <abbr title="Purchasing Power Parity">PPP</abbr>`}
                isLast
              />
            </div>
          </div>
        )}
        {!fxRate && <NoRateMessage />}
      </div>
    </div>
  )
}

export default FXWidgetResults
