const markets = [
  {
    name: "Canada",
    flag: "🇨🇦",
    currency: "CAD",
    currencySymbol: "C$",
    placeholderValue: 149,
    min: 1.1,
    max: 1.5,
    ppp: 1.02,
    vat: 0,
    fxRates: [
      {
        date: "2019-01-02",
        value: 1.364130911643415,
      },
      {
        date: "2019-01-03",
        value: 1.3563623545999295,
      },
      {
        date: "2019-01-04",
        value: 1.3442076646496535,
      },
      {
        date: "2019-01-07",
        value: 1.3351681957186543,
      },
      {
        date: "2019-01-08",
        value: 1.3293706293706293,
      },
      {
        date: "2019-01-09",
        value: 1.3244871235268443,
      },
      {
        date: "2019-01-10",
        value: 1.3221499783268313,
      },
      {
        date: "2019-01-11",
        value: 1.32203242868291,
      },
      {
        date: "2019-01-14",
        value: 1.3278102380744745,
      },
      {
        date: "2019-01-15",
        value: 1.3265056022408963,
      },
      {
        date: "2019-01-16",
        value: 1.3259285275265607,
      },
      {
        date: "2019-01-17",
        value: 1.3296770796770798,
      },
      {
        date: "2019-01-18",
        value: 1.327310998070514,
      },
      {
        date: "2019-01-21",
        value: 1.3304875902129907,
      },
      {
        date: "2019-01-22",
        value: 1.3323938700017615,
      },
      {
        date: "2019-01-23",
        value: 1.331221958300343,
      },
      {
        date: "2019-01-24",
        value: 1.3367427916409487,
      },
      {
        date: "2019-01-25",
        value: 1.3314824607791291,
      },
      {
        date: "2019-01-28",
        value: 1.3237870029777545,
      },
      {
        date: "2019-01-29",
        value: 1.3256872701803535,
      },
      {
        date: "2019-01-30",
        value: 1.3221629188905417,
      },
      {
        date: "2019-01-31",
        value: 1.3151984679665736,
      },
      {
        date: "2019-02-01",
        value: 1.3141835934094674,
      },
      {
        date: "2019-02-04",
        value: 1.3115771079073832,
      },
      {
        date: "2019-02-05",
        value: 1.312789985117745,
      },
      {
        date: "2019-02-06",
        value: 1.3182376689485695,
      },
      {
        date: "2019-02-07",
        value: 1.3265755839576905,
      },
      {
        date: "2019-02-08",
        value: 1.3306892296844703,
      },
      {
        date: "2019-02-11",
        value: 1.3268193474224068,
      },
      {
        date: "2019-02-12",
        value: 1.3251593484419264,
      },
      {
        date: "2019-02-13",
        value: 1.3243697478991596,
      },
      {
        date: "2019-02-14",
        value: 1.3266773162939296,
      },
      {
        date: "2019-02-15",
        value: 1.3285079928952044,
      },
      {
        date: "2019-02-18",
        value: 1.323446327683616,
      },
      {
        date: "2019-02-19",
        value: 1.3246856738091022,
      },
      {
        date: "2019-02-20",
        value: 1.3186386880620702,
      },
      {
        date: "2019-02-21",
        value: 1.3168046503434914,
      },
      {
        date: "2019-02-22",
        value: 1.3223841059602648,
      },
      {
        date: "2019-02-25",
        value: 1.314310876265962,
      },
      {
        date: "2019-02-26",
        value: 1.3224188011618694,
      },
      {
        date: "2019-02-27",
        value: 1.3140699104163007,
      },
      {
        date: "2019-02-28",
        value: 1.3176243868255082,
      },
      {
        date: "2019-03-01",
        value: 1.3152068874637617,
      },
      {
        date: "2019-03-04",
        value: 1.3312163711740321,
      },
      {
        date: "2019-03-05",
        value: 1.3342748698031601,
      },
      {
        date: "2019-03-06",
        value: 1.3381689517912427,
      },
      {
        date: "2019-03-07",
        value: 1.3424718303611036,
      },
      {
        date: "2019-03-08",
        value: 1.3454820887542327,
      },
      {
        date: "2019-03-11",
        value: 1.342315901814301,
      },
      {
        date: "2019-03-12",
        value: 1.3402217294900223,
      },
      {
        date: "2019-03-13",
        value: 1.3347783774219233,
      },
      {
        date: "2019-03-14",
        value: 1.334572819831784,
      },
      {
        date: "2019-03-15",
        value: 1.336929607357623,
      },
      {
        date: "2019-03-18",
        value: 1.3335095603136842,
      },
      {
        date: "2019-03-19",
        value: 1.3259376650818806,
      },
      {
        date: "2019-03-20",
        value: 1.332129645939757,
      },
      {
        date: "2019-03-21",
        value: 1.333889523140423,
      },
      {
        date: "2019-03-22",
        value: 1.3409131127234117,
      },
      {
        date: "2019-03-25",
        value: 1.3425165562913906,
      },
      {
        date: "2019-03-26",
        value: 1.3401824461960854,
      },
      {
        date: "2019-03-27",
        value: 1.3409999111979396,
      },
      {
        date: "2019-03-28",
        value: 1.3430201461936175,
      },
      {
        date: "2019-03-29",
        value: 1.335113484646195,
      },
      {
        date: "2019-04-01",
        value: 1.3355286578853685,
      },
      {
        date: "2019-04-02",
        value: 1.3320535714285713,
      },
      {
        date: "2019-04-03",
        value: 1.3309614871475584,
      },
      {
        date: "2019-04-04",
        value: 1.3358588109457172,
      },
      {
        date: "2019-04-05",
        value: 1.3380218997596367,
      },
      {
        date: "2019-04-08",
        value: 1.3375422372399075,
      },
      {
        date: "2019-04-09",
        value: 1.3287221778841891,
      },
      {
        date: "2019-04-10",
        value: 1.3327422643851408,
      },
      {
        date: "2019-04-11",
        value: 1.337624289772727,
      },
      {
        date: "2019-04-12",
        value: 1.3322144686865116,
      },
      {
        date: "2019-04-15",
        value: 1.3312118801378945,
      },
      {
        date: "2019-04-16",
        value: 1.3365767359575407,
      },
      {
        date: "2019-04-17",
        value: 1.333510308822228,
      },
      {
        date: "2019-04-18",
        value: 1.3391111111111111,
      },
      {
        date: "2019-04-23",
        value: 1.3389951089373053,
      },
      {
        date: "2019-04-24",
        value: 1.3451690605763227,
      },
      {
        date: "2019-04-25",
        value: 1.3506248314303695,
      },
      {
        date: "2019-04-26",
        value: 1.3491421898859248,
      },
      {
        date: "2019-04-29",
        value: 1.3478026905829597,
      },
      {
        date: "2019-04-30",
        value: 1.3438224282403282,
      },
      {
        date: "2019-05-02",
        value: 1.3435604709240099,
      },
      {
        date: "2019-05-03",
        value: 1.3463917525773197,
      },
      {
        date: "2019-05-06",
        value: 1.347888204303956,
      },
      {
        date: "2019-05-07",
        value: 1.3468931604827894,
      },
      {
        date: "2019-05-08",
        value: 1.3456525620424924,
      },
      {
        date: "2019-05-09",
        value: 1.348432055749129,
      },
      {
        date: "2019-05-10",
        value: 1.3474621549421195,
      },
      {
        date: "2019-05-13",
        value: 1.3443308136949754,
      },
      {
        date: "2019-05-14",
        value: 1.3466060929983965,
      },
      {
        date: "2019-05-15",
        value: 1.3480282571760709,
      },
      {
        date: "2019-05-16",
        value: 1.3424082834954922,
      },
      {
        date: "2019-05-17",
        value: 1.3492660221983532,
      },
      {
        date: "2019-05-20",
        value: 1.3432434852690964,
      },
      {
        date: "2019-05-21",
        value: 1.3409192724666248,
      },
      {
        date: "2019-05-22",
        value: 1.3384656700384927,
      },
      {
        date: "2019-05-23",
        value: 1.3466199838405604,
      },
      {
        date: "2019-05-24",
        value: 1.3455796907124342,
      },
      {
        date: "2019-05-27",
        value: 1.3449723164850866,
      },
      {
        date: "2019-05-28",
        value: 1.3465868477483918,
      },
      {
        date: "2019-05-29",
        value: 1.3503944065973468,
      },
      {
        date: "2019-05-30",
        value: 1.3498293515358362,
      },
      {
        date: "2019-05-31",
        value: 1.355483813110932,
      },
      {
        date: "2019-06-03",
        value: 1.3498435404559679,
      },
      {
        date: "2019-06-04",
        value: 1.3439167556029883,
      },
      {
        date: "2019-06-05",
        value: 1.33872257262148,
      },
      {
        date: "2019-06-06",
        value: 1.339960944434582,
      },
      {
        date: "2019-06-07",
        value: 1.3360241284485053,
      },
      {
        date: "2019-06-10",
        value: 1.3276701176887,
      },
      {
        date: "2019-06-11",
        value: 1.3252650176678447,
      },
      {
        date: "2019-06-12",
        value: 1.3299478936677558,
      },
      {
        date: "2019-06-13",
        value: 1.3305872973691204,
      },
      {
        date: "2019-06-14",
        value: 1.333155792276964,
      },
      {
        date: "2019-06-17",
        value: 1.3413743991454512,
      },
      {
        date: "2019-06-18",
        value: 1.3422722803253777,
      },
      {
        date: "2019-06-19",
        value: 1.3375568840903007,
      },
      {
        date: "2019-06-20",
        value: 1.3165295834438842,
      },
      {
        date: "2019-06-21",
        value: 1.3191940615058324,
      },
      {
        date: "2019-06-24",
        value: 1.3191153238546602,
      },
      {
        date: "2019-06-25",
        value: 1.3172637864418686,
      },
      {
        date: "2019-06-26",
        value: 1.3155254356627353,
      },
      {
        date: "2019-06-27",
        value: 1.312928759894459,
      },
      {
        date: "2019-06-28",
        value: 1.308699472759227,
      },
      {
        date: "2019-07-01",
        value: 1.3098951449466913,
      },
      {
        date: "2019-07-02",
        value: 1.311299884965932,
      },
      {
        date: "2019-07-03",
        value: 1.31001505357301,
      },
      {
        date: "2019-07-04",
        value: 1.3066087880935506,
      },
      {
        date: "2019-07-05",
        value: 1.3065719360568386,
      },
      {
        date: "2019-07-08",
        value: 1.3055728934462774,
      },
      {
        date: "2019-07-09",
        value: 1.312449799196787,
      },
      {
        date: "2019-07-10",
        value: 1.311319073083779,
      },
      {
        date: "2019-07-11",
        value: 1.305715551617191,
      },
      {
        date: "2019-07-12",
        value: 1.3033857637963209,
      },
      {
        date: "2019-07-15",
        value: 1.3030437483361432,
      },
      {
        date: "2019-07-16",
        value: 1.304731355252606,
      },
      {
        date: "2019-07-17",
        value: 1.3066428889879627,
      },
      {
        date: "2019-07-18",
        value: 1.3061697574893012,
      },
      {
        date: "2019-07-19",
        value: 1.3054516301443078,
      },
      {
        date: "2019-07-22",
        value: 1.3076237182345074,
      },
      {
        date: "2019-07-23",
        value: 1.3148661952922225,
      },
      {
        date: "2019-07-24",
        value: 1.3130161579892279,
      },
      {
        date: "2019-07-25",
        value: 1.3127305443094919,
      },
      {
        date: "2019-07-26",
        value: 1.3180104147961933,
      },
      {
        date: "2019-07-29",
        value: 1.317114848457595,
      },
      {
        date: "2019-07-30",
        value: 1.317105970952125,
      },
      {
        date: "2019-07-31",
        value: 1.3148596538427046,
      },
      {
        date: "2019-08-01",
        value: 1.3230044396122136,
      },
      {
        date: "2019-08-02",
        value: 1.3234287772375293,
      },
      {
        date: "2019-08-05",
        value: 1.3213199785369343,
      },
      {
        date: "2019-08-06",
        value: 1.321623312773755,
      },
      {
        date: "2019-08-07",
        value: 1.3319050169612567,
      },
      {
        date: "2019-08-08",
        value: 1.3288662556955242,
      },
      {
        date: "2019-08-09",
        value: 1.3213073763171996,
      },
      {
        date: "2019-08-12",
        value: 1.3227621940325174,
      },
      {
        date: "2019-08-13",
        value: 1.3267688469078593,
      },
      {
        date: "2019-08-14",
        value: 1.3292813728995352,
      },
      {
        date: "2019-08-15",
        value: 1.3310313901345292,
      },
      {
        date: "2019-08-16",
        value: 1.329902491874323,
      },
      {
        date: "2019-08-19",
        value: 1.3265784022336307,
      },
      {
        date: "2019-08-20",
        value: 1.3327916215240159,
      },
      {
        date: "2019-08-21",
        value: 1.3293407780979827,
      },
      {
        date: "2019-08-22",
        value: 1.3283407019759992,
      },
      {
        date: "2019-08-23",
        value: 1.3322187076366923,
      },
      {
        date: "2019-08-26",
        value: 1.3303346527527888,
      },
      {
        date: "2019-08-27",
        value: 1.3228566282420748,
      },
      {
        date: "2019-08-28",
        value: 1.331228006857349,
      },
      {
        date: "2019-08-29",
        value: 1.3275830924855492,
      },
      {
        date: "2019-08-30",
        value: 1.3281986226893803,
      },
      {
        date: "2019-09-02",
        value: 1.3340627279358133,
      },
      {
        date: "2019-09-03",
        value: 1.3373868519703758,
      },
      {
        date: "2019-09-04",
        value: 1.3322744599745873,
      },
      {
        date: "2019-09-05",
        value: 1.3204919515283053,
      },
      {
        date: "2019-09-06",
        value: 1.322481182551918,
      },
      {
        date: "2019-09-09",
        value: 1.3155986585697454,
      },
      {
        date: "2019-09-10",
        value: 1.3166666666666667,
      },
      {
        date: "2019-09-11",
        value: 1.3149141143324548,
      },
      {
        date: "2019-09-12",
        value: 1.3197117577305482,
      },
      {
        date: "2019-09-13",
        value: 1.3214671953857247,
      },
      {
        date: "2019-09-16",
        value: 1.3252651618166984,
      },
      {
        date: "2019-09-17",
        value: 1.325956829312534,
      },
      {
        date: "2019-09-18",
        value: 1.3249796435356918,
      },
      {
        date: "2019-09-19",
        value: 1.3270082226438962,
      },
      {
        date: "2019-09-20",
        value: 1.3269265639165913,
      },
      {
        date: "2019-09-23",
        value: 1.3278106508875738,
      },
      {
        date: "2019-09-24",
        value: 1.3256384622375714,
      },
      {
        date: "2019-09-25",
        value: 1.327627026042615,
      },
      {
        date: "2019-09-26",
        value: 1.3240080453464982,
      },
      {
        date: "2019-09-27",
        value: 1.325377229080933,
      },
      {
        date: "2019-09-30",
        value: 1.3248232160896318,
      },
      {
        date: "2019-10-01",
        value: 1.327399522848229,
      },
      {
        date: "2019-10-02",
        value: 1.3242105263157895,
      },
      {
        date: "2019-10-03",
        value: 1.3337594740206373,
      },
      {
        date: "2019-10-04",
        value: 1.3309044539575552,
      },
      {
        date: "2019-10-07",
        value: 1.3311198035113254,
      },
      {
        date: "2019-10-08",
        value: 1.3310577098124885,
      },
      {
        date: "2019-10-09",
        value: 1.33020672069939,
      },
      {
        date: "2019-10-10",
        value: 1.330734360834089,
      },
      {
        date: "2019-10-11",
        value: 1.3292583537082314,
      },
      {
        date: "2019-10-14",
        value: 1.3213670564772007,
      },
      {
        date: "2019-10-15",
        value: 1.3229762878168438,
      },
      {
        date: "2019-10-16",
        value: 1.320907029478458,
      },
      {
        date: "2019-10-17",
        value: 1.31773598488257,
      },
      {
        date: "2019-10-18",
        value: 1.3136216798277098,
      },
      {
        date: "2019-10-21",
        value: 1.3105701244070527,
      },
      {
        date: "2019-10-22",
        value: 1.310062893081761,
      },
      {
        date: "2019-10-23",
        value: 1.3088195630675177,
      },
      {
        date: "2019-10-24",
        value: 1.3070632638389648,
      },
      {
        date: "2019-10-25",
        value: 1.3064733951562078,
      },
      {
        date: "2019-10-28",
        value: 1.3066654640570037,
      },
      {
        date: "2019-10-29",
        value: 1.305633168093736,
      },
      {
        date: "2019-10-30",
        value: 1.308661984512876,
      },
      {
        date: "2019-10-31",
        value: 1.3154922001075848,
      },
      {
        date: "2019-11-01",
        value: 1.3180716401831405,
      },
      {
        date: "2019-11-04",
        value: 1.3156479655852305,
      },
      {
        date: "2019-11-05",
        value: 1.3128094337924205,
      },
      {
        date: "2019-11-06",
        value: 1.3164111812443642,
      },
      {
        date: "2019-11-07",
        value: 1.3166922451927416,
      },
      {
        date: "2019-11-08",
        value: 1.3196483596157331,
      },
      {
        date: "2019-11-11",
        value: 1.3229779911239925,
      },
      {
        date: "2019-11-12",
        value: 1.32437585111212,
      },
      {
        date: "2019-11-13",
        value: 1.3258222787570417,
      },
      {
        date: "2019-11-14",
        value: 1.326543602800764,
      },
      {
        date: "2019-11-15",
        value: 1.3239079209715428,
      },
      {
        date: "2019-11-18",
        value: 1.3219419582316245,
      },
      {
        date: "2019-11-19",
        value: 1.3210255484336917,
      },
      {
        date: "2019-11-20",
        value: 1.3302287729451125,
      },
      {
        date: "2019-11-21",
        value: 1.3307186006672076,
      },
      {
        date: "2019-11-22",
        value: 1.327455236028215,
      },
      {
        date: "2019-11-25",
        value: 1.3306686046511629,
      },
      {
        date: "2019-11-26",
        value: 1.3305807622504535,
      },
      {
        date: "2019-11-27",
        value: 1.326641838495776,
      },
      {
        date: "2019-11-28",
        value: 1.3289413902771465,
      },
      {
        date: "2019-11-29",
        value: 1.3307230012748132,
      },
      {
        date: "2019-12-02",
        value: 1.3295835979315975,
      },
      {
        date: "2019-12-03",
        value: 1.3320386595610152,
      },
      {
        date: "2019-12-04",
        value: 1.3272267845862284,
      },
      {
        date: "2019-12-05",
        value: 1.3169280692266092,
      },
      {
        date: "2019-12-06",
        value: 1.3176491797367946,
      },
      {
        date: "2019-12-09",
        value: 1.325056433408578,
      },
      {
        date: "2019-12-10",
        value: 1.3240046944118444,
      },
      {
        date: "2019-12-11",
        value: 1.3237923250564334,
      },
      {
        date: "2019-12-12",
        value: 1.3177695968393643,
      },
      {
        date: "2019-12-13",
        value: 1.3166278861643101,
      },
      {
        date: "2019-12-16",
        value: 1.3117710389377355,
      },
      {
        date: "2019-12-17",
        value: 1.3160723884608494,
      },
      {
        date: "2019-12-18",
        value: 1.3156995051731895,
      },
      {
        date: "2019-12-19",
        value: 1.312224521003868,
      },
      {
        date: "2019-12-20",
        value: 1.3137784986933407,
      },
      {
        date: "2019-12-23",
        value: 1.3162076749435667,
      },
      {
        date: "2019-12-24",
        value: 1.3160649819494583,
      },
      {
        date: "2019-12-27",
        value: 1.3083475298126066,
      },
      {
        date: "2019-12-30",
        value: 1.306729823934221,
      },
      {
        date: "2019-12-31",
        value: 1.299448103970091,
      },
      {
        date: "2020-01-02",
        value: 1.2998302510497635,
      },
      {
        date: "2020-01-03",
        value: 1.2981968242576478,
      },
      {
        date: "2020-01-06",
        value: 1.2975701268536717,
      },
      {
        date: "2020-01-07",
        value: 1.2996777658431793,
      },
      {
        date: "2020-01-08",
        value: 1.3018443544759335,
      },
      {
        date: "2020-01-09",
        value: 1.3055805580558055,
      },
      {
        date: "2020-01-10",
        value: 1.3071860066720764,
      },
      {
        date: "2020-01-13",
        value: 1.3045119539816645,
      },
      {
        date: "2020-01-14",
        value: 1.3070625281151598,
      },
      {
        date: "2020-01-15",
        value: 1.3072159396876681,
      },
      {
        date: "2020-01-16",
        value: 1.303518667741069,
      },
      {
        date: "2020-01-17",
        value: 1.3051854519265393,
      },
      {
        date: "2020-01-20",
        value: 1.3069012178619757,
      },
      {
        date: "2020-01-21",
        value: 1.3056230319388216,
      },
      {
        date: "2020-01-22",
        value: 1.3045634920634919,
      },
      {
        date: "2020-01-23",
        value: 1.3162924894058246,
      },
      {
        date: "2020-01-24",
        value: 1.3131853194381515,
      },
      {
        date: "2020-01-27",
        value: 1.3187301587301588,
      },
      {
        date: "2020-01-28",
        value: 1.3190368014538845,
      },
      {
        date: "2020-01-29",
        value: 1.3176983910553586,
      },
      {
        date: "2020-01-30",
        value: 1.3216973433674857,
      },
      {
        date: "2020-01-31",
        value: 1.3234708650018097,
      },
      {
        date: "2020-02-03",
        value: 1.3236038315561176,
      },
      {
        date: "2020-02-04",
        value: 1.3279326574945691,
      },
      {
        date: "2020-02-05",
        value: 1.3284949650730289,
      },
      {
        date: "2020-02-06",
        value: 1.3278196855403073,
      },
      {
        date: "2020-02-07",
        value: 1.331388458382715,
      },
      {
        date: "2020-02-10",
        value: 1.3313852616199433,
      },
      {
        date: "2020-02-11",
        value: 1.3296945234382167,
      },
      {
        date: "2020-02-12",
        value: 1.3256367967747849,
      },
      {
        date: "2020-02-13",
        value: 1.32566485690623,
      },
      {
        date: "2020-02-14",
        value: 1.3247555801512634,
      },
      {
        date: "2020-02-17",
        value: 1.3229349330872175,
      },
      {
        date: "2020-02-18",
        value: 1.3262758875739646,
      },
      {
        date: "2020-02-19",
        value: 1.3223148148148147,
      },
      {
        date: "2020-02-20",
        value: 1.3256719184430028,
      },
      {
        date: "2020-02-21",
        value: 1.3258031663734837,
      },
      {
        date: "2020-02-24",
        value: 1.3285265298576445,
      },
      {
        date: "2020-02-25",
        value: 1.328690036900369,
      },
      {
        date: "2020-02-26",
        value: 1.3304827586206898,
      },
      {
        date: "2020-02-27",
        value: 1.3349142648668368,
      },
      {
        date: "2020-02-28",
        value: 1.344356381525007,
      },
      {
        date: "2020-03-02",
        value: 1.335820895522388,
      },
      {
        date: "2020-03-03",
        value: 1.3364216965008546,
      },
      {
        date: "2020-03-04",
        value: 1.3359101123595505,
      },
      {
        date: "2020-03-05",
        value: 1.3427192276749798,
      },
      {
        date: "2020-03-06",
        value: 1.3420077628793228,
      },
      {
        date: "2020-03-09",
        value: 1.3628666201117319,
      },
      {
        date: "2020-03-10",
        value: 1.3677787532923618,
      },
      {
        date: "2020-03-11",
        value: 1.3742942836979535,
      },
      {
        date: "2020-03-12",
        value: 1.3811387900355871,
      },
      {
        date: "2020-03-13",
        value: 1.3858969740634004,
      },
      {
        date: "2020-03-16",
        value: 1.3927579098323923,
      },
      {
        date: "2020-03-17",
        value: 1.41249317064287,
      },
      {
        date: "2020-03-18",
        value: 1.4403694896652643,
      },
      {
        date: "2020-03-19",
        value: 1.446440144431071,
      },
      {
        date: "2020-03-20",
        value: 1.4249556364994864,
      },
      {
        date: "2020-03-23",
        value: 1.4383752202541036,
      },
      {
        date: "2020-03-24",
        value: 1.4494143687171448,
      },
      {
        date: "2020-03-25",
        value: 1.4352082756072781,
      },
      {
        date: "2020-03-26",
        value: 1.4084327474729077,
      },
      {
        date: "2020-03-27",
        value: 1.4139564544046646,
      },
      {
        date: "2020-03-30",
        value: 1.416168207359072,
      },
      {
        date: "2020-03-31",
        value: 1.4254289886820009,
      },
      {
        date: "2020-04-01",
        value: 1.4265727871250915,
      },
      {
        date: "2020-04-02",
        value: 1.416743077205208,
      },
      {
        date: "2020-04-03",
        value: 1.4185442744552619,
      },
      {
        date: "2020-04-06",
        value: 1.4159948104902234,
      },
      {
        date: "2020-04-07",
        value: 1.401837390904915,
      },
      {
        date: "2020-04-08",
        value: 1.4012510348633982,
      },
      {
        date: "2020-04-09",
        value: 1.4047115119168123,
      },
      {
        date: "2020-04-14",
        value: 1.391681109185442,
      },
      {
        date: "2020-04-15",
        value: 1.4060350362285607,
      },
      {
        date: "2020-04-16",
        value: 1.410635562086701,
      },
      {
        date: "2020-04-17",
        value: 1.4069981583793738,
      },
      {
        date: "2020-04-20",
        value: 1.4111418047882136,
      },
      {
        date: "2020-04-21",
        value: 1.4204115530128263,
      },
      {
        date: "2020-04-22",
        value: 1.414833900800589,
      },
      {
        date: "2020-04-23",
        value: 1.4134793910137393,
      },
      {
        date: "2020-04-24",
        value: 1.4049074074074075,
      },
      {
        date: "2020-04-27",
        value: 1.4057316623663842,
      },
      {
        date: "2020-04-28",
        value: 1.3955134687873496,
      },
      {
        date: "2020-04-29",
        value: 1.3954067515218593,
      },
      {
        date: "2020-04-30",
        value: 1.3862633321073925,
      },
      {
        date: "2020-05-04",
        value: 1.406507037104734,
      },
      {
        date: "2020-05-05",
        value: 1.4019182882965968,
      },
      {
        date: "2020-05-06",
        value: 1.4088091052095864,
      },
      {
        date: "2020-05-07",
        value: 1.4074932764536772,
      },
      {
        date: "2020-05-08",
        value: 1.3942635801899843,
      },
      {
        date: "2020-05-11",
        value: 1.3983739837398375,
      },
      {
        date: "2020-05-12",
        value: 1.3978633265794804,
      },
      {
        date: "2020-05-13",
        value: 1.4016551724137931,
      },
      {
        date: "2020-05-14",
        value: 1.4106745737583395,
      },
      {
        date: "2020-05-15",
        value: 1.410538988701611,
      },
      {
        date: "2020-05-18",
        value: 1.4034342688330872,
      },
      {
        date: "2020-05-19",
        value: 1.3927853881278538,
      },
      {
        date: "2020-05-20",
        value: 1.3881182697572547,
      },
      {
        date: "2020-05-21",
        value: 1.3918181818181816,
      },
      {
        date: "2020-05-22",
        value: 1.400678650036684,
      },
      {
        date: "2020-05-25",
        value: 1.3988084326306143,
      },
      {
        date: "2020-05-26",
        value: 1.3835079726651482,
      },
      {
        date: "2020-05-27",
        value: 1.376216904740242,
      },
      {
        date: "2020-05-28",
        value: 1.375726216412491,
      },
      {
        date: "2020-05-29",
        value: 1.3721264367816093,
      },
      {
        date: "2020-06-01",
        value: 1.369917236415977,
      },
      {
        date: "2020-06-02",
        value: 1.3518883121532128,
      },
      {
        date: "2020-06-03",
        value: 1.3554582812220834,
      },
      {
        date: "2020-06-04",
        value: 1.3513777777777778,
      },
      {
        date: "2020-06-05",
        value: 1.3448367166813768,
      },
      {
        date: "2020-06-08",
        value: 1.3403633141338058,
      },
      {
        date: "2020-06-09",
        value: 1.3446962989197804,
      },
      {
        date: "2020-06-10",
        value: 1.3387252747252747,
      },
      {
        date: "2020-06-11",
        value: 1.350105745505816,
      },
      {
        date: "2020-06-12",
        value: 1.3576610049539986,
      },
      {
        date: "2020-06-15",
        value: 1.3633697680618502,
      },
      {
        date: "2020-06-16",
        value: 1.3547930668553236,
      },
      {
        date: "2020-06-17",
        value: 1.3536324786324787,
      },
      {
        date: "2020-06-18",
        value: 1.356086259133844,
      },
      {
        date: "2020-06-19",
        value: 1.3567350579839428,
      },
      {
        date: "2020-06-22",
        value: 1.356461250334433,
      },
      {
        date: "2020-06-23",
        value: 1.3490899452200036,
      },
      {
        date: "2020-06-24",
        value: 1.359131205673759,
      },
      {
        date: "2020-06-25",
        value: 1.3644642857142857,
      },
      {
        date: "2020-06-26",
        value: 1.366092927851601,
      },
      {
        date: "2020-06-29",
        value: 1.3655618574973412,
      },
      {
        date: "2020-06-30",
        value: 1.368458653330952,
      },
      {
        date: "2020-07-01",
        value: 1.358660714285714,
      },
      {
        date: "2020-07-02",
        value: 1.359471912103491,
      },
      {
        date: "2020-07-03",
        value: 1.3571810406272273,
      },
      {
        date: "2020-07-06",
        value: 1.3539072847682119,
      },
      {
        date: "2020-07-07",
        value: 1.3566873339238263,
      },
      {
        date: "2020-07-08",
        value: 1.3590288853446748,
      },
      {
        date: "2020-07-09",
        value: 1.3502027861047434,
      },
      {
        date: "2020-07-10",
        value: 1.360056757715502,
      },
      {
        date: "2020-07-13",
        value: 1.3563421308147232,
      },
      {
        date: "2020-07-14",
        value: 1.3615824175824176,
      },
      {
        date: "2020-07-15",
        value: 1.3567808458580914,
      },
      {
        date: "2020-07-16",
        value: 1.3537760644822148,
      },
      {
        date: "2020-07-17",
        value: 1.357192859642982,
      },
      {
        date: "2020-07-20",
        value: 1.356743535988819,
      },
      {
        date: "2020-07-21",
        value: 1.3457135366599666,
      },
      {
        date: "2020-07-22",
        value: 1.342200725513906,
      },
      {
        date: "2020-07-23",
        value: 1.338577232258622,
      },
      {
        date: "2020-07-24",
        value: 1.3420055134390076,
      },
      {
        date: "2020-07-27",
        value: 1.3375000000000001,
      },
      {
        date: "2020-07-28",
        value: 1.3393360075104548,
      },
      {
        date: "2020-07-29",
        value: 1.3360341151385926,
      },
      {
        date: "2020-07-30",
        value: 1.3430128587243464,
      },
      {
        date: "2020-07-31",
        value: 1.3418298446995274,
      },
      {
        date: "2020-08-03",
        value: 1.3435954289612824,
      },
      {
        date: "2020-08-04",
        value: 1.3406714832129194,
      },
      {
        date: "2020-08-05",
        value: 1.3247005230302007,
      },
      {
        date: "2020-08-06",
        value: 1.3297306425736724,
      },
      {
        date: "2020-08-07",
        value: 1.3345180671913346,
      },
      {
        date: "2020-08-10",
        value: 1.3380090113066396,
      },
      {
        date: "2020-08-11",
        value: 1.3286938810150217,
      },
      {
        date: "2020-08-12",
        value: 1.3290289695013167,
      },
      {
        date: "2020-08-13",
        value: 1.3214738443336433,
      },
      {
        date: "2020-08-14",
        value: 1.3247269956827223,
      },
      {
        date: "2020-08-17",
        value: 1.3232093140976968,
      },
      {
        date: "2020-08-18",
        value: 1.3163111036452206,
      },
      {
        date: "2020-08-19",
        value: 1.3146735942344758,
      },
      {
        date: "2020-08-20",
        value: 1.3210126582278479,
      },
      {
        date: "2020-08-21",
        value: 1.3223723340980542,
      },
      {
        date: "2020-08-24",
        value: 1.3145944120874482,
      },
      {
        date: "2020-08-25",
        value: 1.3202133062468258,
      },
      {
        date: "2020-08-26",
        value: 1.3184324370175586,
      },
      {
        date: "2020-08-27",
        value: 1.3146705065221074,
      },
      {
        date: "2020-08-28",
        value: 1.3053294167016365,
      },
      {
        date: "2020-08-31",
        value: 1.3066164154103854,
      },
      {
        date: "2020-09-01",
        value: 1.3014098606824058,
      },
      {
        date: "2020-09-02",
        value: 1.3063822611921425,
      },
      {
        date: "2020-09-03",
        value: 1.3113519004486582,
      },
      {
        date: "2020-09-04",
        value: 1.3095760851207567,
      },
      {
        date: "2020-09-07",
        value: 1.3095399188092018,
      },
      {
        date: "2020-09-08",
        value: 1.3171828595672463,
      },
      {
        date: "2020-09-09",
        value: 1.321923044253801,
      },
      {
        date: "2020-09-10",
        value: 1.3153852645792894,
      },
      {
        date: "2020-09-11",
        value: 1.3178673865361903,
      },
      {
        date: "2020-09-14",
        value: 1.3170259346581341,
      },
      {
        date: "2020-09-15",
        value: 1.3146653212243524,
      },
      {
        date: "2020-09-16",
        value: 1.3167073889965455,
      },
      {
        date: "2020-09-17",
        value: 1.319572772738832,
      },
      {
        date: "2020-09-18",
        value: 1.3184315051128201,
      },
      {
        date: "2020-09-21",
        value: 1.3238313396114363,
      },
      {
        date: "2020-09-22",
        value: 1.3314310051107325,
      },
      {
        date: "2020-09-23",
        value: 1.333732466643859,
      },
      {
        date: "2020-09-24",
        value: 1.339630742808072,
      },
      {
        date: "2020-09-25",
        value: 1.3374591713941895,
      },
      {
        date: "2020-09-28",
        value: 1.3384747215081405,
      },
      {
        date: "2020-09-29",
        value: 1.3378055033327636,
      },
      {
        date: "2020-09-30",
        value: 1.3389135633754699,
      },
      {
        date: "2020-10-01",
        value: 1.3287950987066033,
      },
      {
        date: "2020-10-02",
        value: 1.3301790281329924,
      },
      {
        date: "2020-10-05",
        value: 1.3268184908225695,
      },
      {
        date: "2020-10-06",
        value: 1.3251377702416278,
      },
      {
        date: "2020-10-07",
        value: 1.328802039082413,
      },
      {
        date: "2020-10-08",
        value: 1.3245218869528261,
      },
      {
        date: "2020-10-09",
        value: 1.3176769817719374,
      },
      {
        date: "2020-10-12",
        value: 1.3130773794389354,
      },
      {
        date: "2020-10-13",
        value: 1.3107660982438278,
      },
      {
        date: "2020-10-14",
        value: 1.3133617021276593,
      },
      {
        date: "2020-10-15",
        value: 1.320995041887502,
      },
      {
        date: "2020-10-16",
        value: 1.3199046077846863,
      },
      {
        date: "2020-10-19",
        value: 1.3171828595672463,
      },
      {
        date: "2020-10-20",
        value: 1.3189669771380186,
      },
      {
        date: "2020-10-21",
        value: 1.3126054674316572,
      },
      {
        date: "2020-10-22",
        value: 1.3162169021233399,
      },
      {
        date: "2020-10-23",
        value: 1.3126686909581646,
      },
      {
        date: "2020-10-26",
        value: 1.3182164311701499,
      },
      {
        date: "2020-10-27",
        value: 1.3175287356321839,
      },
      {
        date: "2020-10-28",
        value: 1.3283874818794235,
      },
      {
        date: "2020-10-29",
        value: 1.3378332194121667,
      },
      {
        date: "2020-10-30",
        value: 1.3297999658061208,
      },
      {
        date: "2020-11-02",
        value: 1.3273257809818058,
      },
      {
        date: "2020-11-03",
        value: 1.3147325243548111,
      },
      {
        date: "2020-11-04",
        value: 1.3148195546455081,
      },
      {
        date: "2020-11-05",
        value: 1.3056094474905104,
      },
      {
        date: "2020-11-06",
        value: 1.3079191238416175,
      },
      {
        date: "2020-11-09",
        value: 1.2949591853908946,
      },
      {
        date: "2020-11-10",
        value: 1.3036077235772356,
      },
      {
        date: "2020-11-11",
        value: 1.3057963624001359,
      },
      {
        date: "2020-11-12",
        value: 1.3097277584598421,
      },
      {
        date: "2020-11-13",
        value: 1.31426153195091,
      },
      {
        date: "2020-11-16",
        value: 1.3081994928148775,
      },
      {
        date: "2020-11-17",
        value: 1.3095438478370647,
      },
      {
        date: "2020-11-18",
        value: 1.3072126727334006,
      },
      {
        date: "2020-11-19",
        value: 1.3100912778904665,
      },
      {
        date: "2020-11-20",
        value: 1.3052347635505355,
      },
      {
        date: "2020-11-23",
        value: 1.3049323586253256,
      },
      {
        date: "2020-11-24",
        value: 1.3067003792667509,
      },
      {
        date: "2020-11-25",
        value: 1.3017661900756938,
      },
      {
        date: "2020-11-26",
        value: 1.3001680672268907,
      },
      {
        date: "2020-11-27",
        value: 1.2998657943298106,
      },
      {
        date: "2020-11-30",
        value: 1.2951585976627713,
      },
      {
        date: "2020-12-01",
        value: 1.2969585561497325,
      },
      {
        date: "2020-12-02",
        value: 1.2944637825294216,
      },
      {
        date: "2020-12-03",
        value: 1.2914163443337996,
      },
      {
        date: "2020-12-04",
        value: 1.2857142857142856,
      },
      {
        date: "2020-12-07",
        value: 1.2810850923482848,
      },
      {
        date: "2020-12-08",
        value: 1.2801717021627868,
      },
      {
        date: "2020-12-09",
        value: 1.277727310265092,
      },
      {
        date: "2020-12-10",
        value: 1.2757738340899711,
      },
      {
        date: "2020-12-11",
        value: 1.2770677001731674,
      },
      {
        date: "2020-12-14",
        value: 1.2724880776188128,
      },
      {
        date: "2020-12-15",
        value: 1.2745469522240527,
      },
      {
        date: "2020-12-16",
        value: 1.2758224628763637,
      },
      {
        date: "2020-12-17",
        value: 1.2694757471827536,
      },
      {
        date: "2020-12-18",
        value: 1.2756342279141857,
      },
      {
        date: "2020-12-21",
        value: 1.2885895013554587,
      },
      {
        date: "2020-12-22",
        value: 1.2871149603725793,
      },
      {
        date: "2020-12-23",
        value: 1.2880979779713957,
      },
      {
        date: "2020-12-24",
        value: 1.2840154186828507,
      },
      {
        date: "2020-12-28",
        value: 1.2830018823144285,
      },
      {
        date: "2020-12-29",
        value: 1.2817521820703157,
      },
      {
        date: "2020-12-30",
        value: 1.2784789512254704,
      },
      {
        date: "2020-12-31",
        value: 1.2739793007904814,
      },
      {
        date: "2021-01-04",
        value: 1.270413142485361,
      },
      {
        date: "2021-01-05",
        value: 1.275446173905957,
      },
      {
        date: "2021-01-06",
        value: 1.267628464905171,
      },
      {
        date: "2021-01-07",
        value: 1.2708536982730532,
      },
      {
        date: "2021-01-08",
        value: 1.2688163265306123,
      },
      {
        date: "2021-01-11",
        value: 1.2785496999095618,
      },
      {
        date: "2021-01-12",
        value: 1.275388537126881,
      },
      {
        date: "2021-01-13",
        value: 1.2729738615814565,
      },
      {
        date: "2021-01-14",
        value: 1.268640712636094,
      },
      {
        date: "2021-01-15",
        value: 1.2713849707168192,
      },
      {
        date: "2021-01-18",
        value: 1.2776027851458887,
      },
      {
        date: "2021-01-19",
        value: 1.2725024727992087,
      },
      {
        date: "2021-01-20",
        value: 1.2708867035782168,
      },
      {
        date: "2021-01-21",
        value: 1.2621319295936833,
      },
      {
        date: "2021-01-22",
        value: 1.2714262214179965,
      },
      {
        date: "2021-01-25",
        value: 1.2721362738643844,
      },
      {
        date: "2021-01-26",
        value: 1.271843860660463,
      },
      {
        date: "2021-01-27",
        value: 1.275631500742942,
      },
      {
        date: "2021-01-28",
        value: 1.2872384418162268,
      },
      {
        date: "2021-01-29",
        value: 1.2788398154251812,
      },
      {
        date: "2021-02-01",
        value: 1.2805362462760677,
      },
      {
        date: "2021-02-02",
        value: 1.2804716041182334,
      },
      {
        date: "2021-02-03",
        value: 1.2792710327036698,
      },
      {
        date: "2021-02-04",
        value: 1.2802600866955651,
      },
      {
        date: "2021-02-05",
        value: 1.2804806809647,
      },
      {
        date: "2021-02-08",
        value: 1.2775883575883578,
      },
      {
        date: "2021-02-09",
        value: 1.2734633179114343,
      },
      {
        date: "2021-02-10",
        value: 1.2693988620433745,
      },
      {
        date: "2021-02-11",
        value: 1.2664855519881453,
      },
      {
        date: "2021-02-12",
        value: 1.2733729765444335,
      },
      {
        date: "2021-02-15",
        value: 1.2652320883832138,
      },
      {
        date: "2021-02-16",
        value: 1.2653380548464137,
      },
      {
        date: "2021-02-17",
        value: 1.268988391376451,
      },
      {
        date: "2021-02-18",
        value: 1.266716319099636,
      },
      {
        date: "2021-02-19",
        value: 1.2609770162286844,
      },
      {
        date: "2021-02-22",
        value: 1.2633314102035769,
      },
      {
        date: "2021-02-23",
        value: 1.2615498641192457,
      },
      {
        date: "2021-02-24",
        value: 1.2568746912563808,
      },
      {
        date: "2021-02-25",
        value: 1.2480163599182006,
      },
      {
        date: "2021-02-26",
        value: 1.264829634518604,
      },
      {
        date: "2021-03-01",
        value: 1.2672363726872977,
      },
      {
        date: "2021-03-02",
        value: 1.2657964748919188,
      },
      {
        date: "2021-03-03",
        value: 1.264442231075697,
      },
      {
        date: "2021-03-04",
        value: 1.2641681901279709,
      },
      {
        date: "2021-03-05",
        value: 1.2698944546825264,
      },
      {
        date: "2021-03-08",
        value: 1.2670655654812069,
      },
      {
        date: "2021-03-09",
        value: 1.2602993105767615,
      },
      {
        date: "2021-03-10",
        value: 1.2647998654557686,
      },
      {
        date: "2021-03-11",
        value: 1.257498537889548,
      },
      {
        date: "2021-03-12",
        value: 1.2549233218804994,
      },
      {
        date: "2021-03-15",
        value: 1.2478187919463088,
      },
      {
        date: "2021-03-16",
        value: 1.2466040583598859,
      },
      {
        date: "2021-03-17",
        value: 1.2473335013017552,
      },
      {
        date: "2021-03-18",
        value: 1.244459368703828,
      },
      {
        date: "2021-03-19",
        value: 1.2511142881170634,
      },
      {
        date: "2021-03-22",
        value: 1.2496226731510982,
      },
      {
        date: "2021-03-23",
        value: 1.2570899604476986,
      },
      {
        date: "2021-03-24",
        value: 1.258689217758985,
      },
      {
        date: "2021-03-25",
        value: 1.2581765802406373,
      },
      {
        date: "2021-03-26",
        value: 1.2573417076896962,
      },
      {
        date: "2021-03-29",
        value: 1.2587406653088935,
      },
      {
        date: "2021-03-30",
        value: 1.2617323907673965,
      },
      {
        date: "2021-03-31",
        value: 1.2607249466950958,
      },
      {
        date: "2021-04-01",
        value: 1.2588966456666097,
      },
      {
        date: "2021-04-06",
        value: 1.2563494751100575,
      },
      {
        date: "2021-04-07",
        value: 1.2606866374957928,
      },
      {
        date: "2021-04-08",
        value: 1.2589067632443358,
      },
      {
        date: "2021-04-09",
        value: 1.2575706594885598,
      },
      {
        date: "2021-04-12",
        value: 1.2542842741935485,
      },
      {
        date: "2021-04-13",
        value: 1.2614324142568931,
      },
      {
        date: "2021-04-14",
        value: 1.2559344700768973,
      },
      {
        date: "2021-04-15",
        value: 1.24937343358396,
      },
      {
        date: "2021-04-16",
        value: 1.2502920073418986,
      },
      {
        date: "2021-04-19",
        value: 1.249522226838388,
      },
      {
        date: "2021-04-20",
        value: 1.2520952618039995,
      },
      {
        date: "2021-04-21",
        value: 1.2606812692595986,
      },
      {
        date: "2021-04-22",
        value: 1.250788643533123,
      },
      {
        date: "2021-04-23",
        value: 1.2483010111055861,
      },
      {
        date: "2021-04-26",
        value: 1.2436905254447663,
      },
      {
        date: "2021-04-27",
        value: 1.2396591661151555,
      },
      {
        date: "2021-04-28",
        value: 1.2390223695111848,
      },
      {
        date: "2021-04-29",
        value: 1.2286256080468299,
      },
      {
        date: "2021-04-30",
        value: 1.2277768581360702,
      },
      {
        date: "2021-05-03",
        value: 1.2294088342743275,
      },
      {
        date: "2021-05-04",
        value: 1.2341735296564347,
      },
      {
        date: "2021-05-05",
        value: 1.2270720533111206,
      },
      {
        date: "2021-05-06",
        value: 1.224212271973466,
      },
      {
        date: "2021-05-07",
        value: 1.2180943693506925,
      },
      {
        date: "2021-05-10",
        value: 1.211521078149396,
      },
      {
        date: "2021-05-11",
        value: 1.2102711585866885,
      },
      {
        date: "2021-05-12",
        value: 1.2083677174451228,
      },
      {
        date: "2021-05-13",
        value: 1.2138895786772619,
      },
      {
        date: "2021-05-14",
        value: 1.2125711457560011,
      },
      {
        date: "2021-05-17",
        value: 1.212221032693733,
      },
      {
        date: "2021-05-18",
        value: 1.2021764032073312,
      },
      {
        date: "2021-05-19",
        value: 1.2075827055355386,
      },
      {
        date: "2021-05-20",
        value: 1.2110136851593871,
      },
      {
        date: "2021-05-21",
        value: 1.2044634066294715,
      },
      {
        date: "2021-05-24",
        value: 1.2062725188339338,
      },
      {
        date: "2021-05-25",
        value: 1.2033594259621658,
      },
      {
        date: "2021-05-26",
        value: 1.2089295935890096,
      },
      {
        date: "2021-05-27",
        value: 1.2088867027381538,
      },
      {
        date: "2021-05-28",
        value: 1.2115796409158295,
      },
      {
        date: "2021-05-31",
        value: 1.2070322104745512,
      },
      {
        date: "2021-06-01",
        value: 1.2031083844580779,
      },
      {
        date: "2021-06-02",
        value: 1.2067126210405383,
      },
      {
        date: "2021-06-03",
        value: 1.2070238779026834,
      },
      {
        date: "2021-06-04",
        value: 1.2115210035487332,
      },
      {
        date: "2021-06-07",
        value: 1.2071205393849695,
      },
      {
        date: "2021-06-08",
        value: 1.2071909374486949,
      },
      {
        date: "2021-06-09",
        value: 1.207790077900779,
      },
      {
        date: "2021-06-10",
        value: 1.2106949236076885,
      },
      {
        date: "2021-06-11",
        value: 1.211298969072165,
      },
      {
        date: "2021-06-14",
        value: 1.216727212681638,
      },
      {
        date: "2021-06-15",
        value: 1.218863561281797,
      },
      {
        date: "2021-06-16",
        value: 1.2177499175189708,
      },
      {
        date: "2021-06-17",
        value: 1.2329731088213118,
      },
      {
        date: "2021-06-18",
        value: 1.2372667675239537,
      },
      {
        date: "2021-06-21",
        value: 1.2411067193675889,
      },
      {
        date: "2021-06-22",
        value: 1.2381032453337817,
      },
      {
        date: "2021-06-23",
        value: 1.2272613170445987,
      },
      {
        date: "2021-06-24",
        value: 1.229725201072386,
      },
      {
        date: "2021-06-25",
        value: 1.2297907949790794,
      },
      {
        date: "2021-06-28",
        value: 1.2331654072208227,
      },
      {
        date: "2021-06-29",
        value: 1.2370457604306861,
      },
      {
        date: "2021-06-30",
        value: 1.238808481992595,
      },
    ],
  },
  {
    name: "Eurozone",
    flag: "🇪🇺",
    currency: "EUR",
    currencySymbol: "€",
    placeholderValue: 100,
    min: 0.8,
    max: 1.0,
    ppp: 0.95,
    vat: 0.2,
    fxRates: [
      {
        date: "2019-01-02",
        value: 0.8774238834781083,
      },
      {
        date: "2019-01-03",
        value: 0.8812125484666902,
      },
      {
        date: "2019-01-04",
        value: 0.8769622029290537,
      },
      {
        date: "2019-01-07",
        value: 0.873743993010048,
      },
      {
        date: "2019-01-08",
        value: 0.8741258741258742,
      },
      {
        date: "2019-01-09",
        value: 0.8729812309035356,
      },
      {
        date: "2019-01-10",
        value: 0.8669267446900737,
      },
      {
        date: "2019-01-11",
        value: 0.8670770831526923,
      },
      {
        date: "2019-01-14",
        value: 0.8720676724513822,
      },
      {
        date: "2019-01-15",
        value: 0.8753501400560223,
      },
      {
        date: "2019-01-16",
        value: 0.8780402142418122,
      },
      {
        date: "2019-01-17",
        value: 0.8775008775008776,
      },
      {
        date: "2019-01-18",
        value: 0.8770391159445711,
      },
      {
        date: "2019-01-21",
        value: 0.880126738250308,
      },
      {
        date: "2019-01-22",
        value: 0.8807468733485997,
      },
      {
        date: "2019-01-23",
        value: 0.8797395970792645,
      },
      {
        date: "2019-01-24",
        value: 0.8817564588660611,
      },
      {
        date: "2019-01-25",
        value: 0.8813678829543451,
      },
      {
        date: "2019-01-28",
        value: 0.8758101243650377,
      },
      {
        date: "2019-01-29",
        value: 0.8755034144633164,
      },
      {
        date: "2019-01-30",
        value: 0.8749671887304226,
      },
      {
        date: "2019-01-31",
        value: 0.8704735376044568,
      },
      {
        date: "2019-02-01",
        value: 0.871763577717723,
      },
      {
        date: "2019-02-04",
        value: 0.873743993010048,
      },
      {
        date: "2019-02-05",
        value: 0.8754267705506433,
      },
      {
        date: "2019-02-06",
        value: 0.8776549060909251,
      },
      {
        date: "2019-02-07",
        value: 0.881445570736007,
      },
      {
        date: "2019-02-08",
        value: 0.8813678829543451,
      },
      {
        date: "2019-02-11",
        value: 0.8842514811212309,
      },
      {
        date: "2019-02-12",
        value: 0.8852691218130312,
      },
      {
        date: "2019-02-13",
        value: 0.8845643520566121,
      },
      {
        date: "2019-02-14",
        value: 0.8874689385871495,
      },
      {
        date: "2019-02-15",
        value: 0.8880994671403198,
      },
      {
        date: "2019-02-18",
        value: 0.8827683615819208,
      },
      {
        date: "2019-02-19",
        value: 0.8854258898530193,
      },
      {
        date: "2019-02-20",
        value: 0.881678716275789,
      },
      {
        date: "2019-02-21",
        value: 0.8807468733485997,
      },
      {
        date: "2019-02-22",
        value: 0.8830022075055187,
      },
      {
        date: "2019-02-25",
        value: 0.8806693086745927,
      },
      {
        date: "2019-02-26",
        value: 0.8802042073761112,
      },
      {
        date: "2019-02-27",
        value: 0.8782715615668364,
      },
      {
        date: "2019-02-28",
        value: 0.8759635599159076,
      },
      {
        date: "2019-03-01",
        value: 0.8785030308354563,
      },
      {
        date: "2019-03-04",
        value: 0.8820675663755845,
      },
      {
        date: "2019-03-05",
        value: 0.8826904404625298,
      },
      {
        date: "2019-03-06",
        value: 0.8845643520566121,
      },
      {
        date: "2019-03-07",
        value: 0.8872327211427558,
      },
      {
        date: "2019-03-08",
        value: 0.8911067545891997,
      },
      {
        date: "2019-03-11",
        value: 0.8893632159373888,
      },
      {
        date: "2019-03-12",
        value: 0.8869179600886918,
      },
      {
        date: "2019-03-13",
        value: 0.8847208705653365,
      },
      {
        date: "2019-03-14",
        value: 0.8853474988933157,
      },
      {
        date: "2019-03-15",
        value: 0.8843296781039971,
      },
      {
        date: "2019-03-18",
        value: 0.8811349017534584,
      },
      {
        date: "2019-03-19",
        value: 0.8804366966015145,
      },
      {
        date: "2019-03-20",
        value: 0.8807468733485997,
      },
      {
        date: "2019-03-21",
        value: 0.8781944322472995,
      },
      {
        date: "2019-03-22",
        value: 0.8847991505928153,
      },
      {
        date: "2019-03-25",
        value: 0.8830022075055187,
      },
      {
        date: "2019-03-26",
        value: 0.885661146045523,
      },
      {
        date: "2019-03-27",
        value: 0.8880206020779682,
      },
      {
        date: "2019-03-28",
        value: 0.8914244963451596,
      },
      {
        date: "2019-03-29",
        value: 0.8900756564307967,
      },
      {
        date: "2019-04-01",
        value: 0.8899964400142399,
      },
      {
        date: "2019-04-02",
        value: 0.8928571428571428,
      },
      {
        date: "2019-04-03",
        value: 0.8894423196655696,
      },
      {
        date: "2019-04-04",
        value: 0.8913450396648543,
      },
      {
        date: "2019-04-05",
        value: 0.8902341315766047,
      },
      {
        date: "2019-04-08",
        value: 0.8892050506846878,
      },
      {
        date: "2019-04-09",
        value: 0.8867606632969762,
      },
      {
        date: "2019-04-10",
        value: 0.8866034222892101,
      },
      {
        date: "2019-04-11",
        value: 0.8877840909090908,
      },
      {
        date: "2019-04-12",
        value: 0.8833141948591113,
      },
      {
        date: "2019-04-15",
        value: 0.8839388314328649,
      },
      {
        date: "2019-04-16",
        value: 0.8845643520566121,
      },
      {
        date: "2019-04-17",
        value: 0.8848774444739402,
      },
      {
        date: "2019-04-18",
        value: 0.8888888888888888,
      },
      {
        date: "2019-04-23",
        value: 0.8892841262783459,
      },
      {
        date: "2019-04-24",
        value: 0.8921402444464269,
      },
      {
        date: "2019-04-25",
        value: 0.8990380293086397,
      },
      {
        date: "2019-04-26",
        value: 0.898230485942693,
      },
      {
        date: "2019-04-29",
        value: 0.8968609865470852,
      },
      {
        date: "2019-04-30",
        value: 0.8914244963451596,
      },
      {
        date: "2019-05-02",
        value: 0.8919015340706387,
      },
      {
        date: "2019-05-03",
        value: 0.8964589870013447,
      },
      {
        date: "2019-05-06",
        value: 0.892936869363336,
      },
      {
        date: "2019-05-07",
        value: 0.8940545373267769,
      },
      {
        date: "2019-05-08",
        value: 0.8926977325477593,
      },
      {
        date: "2019-05-09",
        value: 0.893415527561869,
      },
      {
        date: "2019-05-10",
        value: 0.8904719501335708,
      },
      {
        date: "2019-05-13",
        value: 0.8892841262783459,
      },
      {
        date: "2019-05-14",
        value: 0.8907892392659896,
      },
      {
        date: "2019-05-15",
        value: 0.8942144326209425,
      },
      {
        date: "2019-05-16",
        value: 0.8926180487369454,
      },
      {
        date: "2019-05-17",
        value: 0.8950948800572861,
      },
      {
        date: "2019-05-20",
        value: 0.8954956568460642,
      },
      {
        date: "2019-05-21",
        value: 0.8959770629871875,
      },
      {
        date: "2019-05-22",
        value: 0.8951750067138126,
      },
      {
        date: "2019-05-23",
        value: 0.8977466558937068,
      },
      {
        date: "2019-05-24",
        value: 0.8938946992044337,
      },
      {
        date: "2019-05-27",
        value: 0.8930166101089481,
      },
      {
        date: "2019-05-28",
        value: 0.8934953538241601,
      },
      {
        date: "2019-05-29",
        value: 0.8963786303334529,
      },
      {
        date: "2019-05-30",
        value: 0.8981498113885397,
      },
      {
        date: "2019-05-31",
        value: 0.896780557797507,
      },
      {
        date: "2019-06-03",
        value: 0.8940545373267769,
      },
      {
        date: "2019-06-04",
        value: 0.8893632159373888,
      },
      {
        date: "2019-06-05",
        value: 0.888336146397797,
      },
      {
        date: "2019-06-06",
        value: 0.8876264867743653,
      },
      {
        date: "2019-06-07",
        value: 0.8870753126940477,
      },
      {
        date: "2019-06-10",
        value: 0.8848774444739402,
      },
      {
        date: "2019-06-11",
        value: 0.88339222614841,
      },
      {
        date: "2019-06-12",
        value: 0.8831581736288968,
      },
      {
        date: "2019-06-13",
        value: 0.8858180529719195,
      },
      {
        date: "2019-06-14",
        value: 0.8877052818464269,
      },
      {
        date: "2019-06-17",
        value: 0.8901548869503294,
      },
      {
        date: "2019-06-18",
        value: 0.8938946992044337,
      },
      {
        date: "2019-06-19",
        value: 0.892299455697332,
      },
      {
        date: "2019-06-20",
        value: 0.8844078889183691,
      },
      {
        date: "2019-06-21",
        value: 0.8837044892188053,
      },
      {
        date: "2019-06-24",
        value: 0.8776549060909251,
      },
      {
        date: "2019-06-25",
        value: 0.8781173164734808,
      },
      {
        date: "2019-06-26",
        value: 0.880126738250308,
      },
      {
        date: "2019-06-27",
        value: 0.8795074758135444,
      },
      {
        date: "2019-06-28",
        value: 0.8787346221441126,
      },
      {
        date: "2019-07-01",
        value: 0.8811349017534584,
      },
      {
        date: "2019-07-02",
        value: 0.8848774444739402,
      },
      {
        date: "2019-07-03",
        value: 0.8855042946958293,
      },
      {
        date: "2019-07-04",
        value: 0.885896527285613,
      },
      {
        date: "2019-07-05",
        value: 0.8880994671403198,
      },
      {
        date: "2019-07-08",
        value: 0.8916629514043692,
      },
      {
        date: "2019-07-09",
        value: 0.8924587237840249,
      },
      {
        date: "2019-07-10",
        value: 0.89126559714795,
      },
      {
        date: "2019-07-11",
        value: 0.8861320336730172,
      },
      {
        date: "2019-07-12",
        value: 0.888651915044877,
      },
      {
        date: "2019-07-15",
        value: 0.8873901854645487,
      },
      {
        date: "2019-07-16",
        value: 0.8910273545397843,
      },
      {
        date: "2019-07-17",
        value: 0.8916629514043692,
      },
      {
        date: "2019-07-18",
        value: 0.891583452211127,
      },
      {
        date: "2019-07-19",
        value: 0.8907892392659896,
      },
      {
        date: "2019-07-22",
        value: 0.8916629514043692,
      },
      {
        date: "2019-07-23",
        value: 0.8950147677436678,
      },
      {
        date: "2019-07-24",
        value: 0.8976660682226211,
      },
      {
        date: "2019-07-25",
        value: 0.899685110211426,
      },
      {
        date: "2019-07-26",
        value: 0.8978272580355541,
      },
      {
        date: "2019-07-29",
        value: 0.8993614533681086,
      },
      {
        date: "2019-07-30",
        value: 0.8965393580778197,
      },
      {
        date: "2019-07-31",
        value: 0.896780557797507,
      },
      {
        date: "2019-08-01",
        value: 0.906043308870164,
      },
      {
        date: "2019-08-02",
        value: 0.9004141905276427,
      },
      {
        date: "2019-08-05",
        value: 0.8942944017170452,
      },
      {
        date: "2019-08-06",
        value: 0.8938946992044337,
      },
      {
        date: "2019-08-07",
        value: 0.8926977325477593,
      },
      {
        date: "2019-08-08",
        value: 0.893415527561869,
      },
      {
        date: "2019-08-09",
        value: 0.8930166101089481,
      },
      {
        date: "2019-08-12",
        value: 0.8933357155619082,
      },
      {
        date: "2019-08-13",
        value: 0.8911067545891997,
      },
      {
        date: "2019-08-14",
        value: 0.893814801573114,
      },
      {
        date: "2019-08-15",
        value: 0.8968609865470852,
      },
      {
        date: "2019-08-16",
        value: 0.9028530155290719,
      },
      {
        date: "2019-08-19",
        value: 0.900657479960371,
      },
      {
        date: "2019-08-20",
        value: 0.9028530155290719,
      },
      {
        date: "2019-08-21",
        value: 0.9005763688760806,
      },
      {
        date: "2019-08-22",
        value: 0.9022827754218171,
      },
      {
        date: "2019-08-23",
        value: 0.903750564844103,
      },
      {
        date: "2019-08-26",
        value: 0.8996041741633681,
      },
      {
        date: "2019-08-27",
        value: 0.9005763688760806,
      },
      {
        date: "2019-08-28",
        value: 0.9022827754218171,
      },
      {
        date: "2019-08-29",
        value: 0.9031791907514451,
      },
      {
        date: "2019-08-30",
        value: 0.9061254077564336,
      },
      {
        date: "2019-09-02",
        value: 0.9117432530999271,
      },
      {
        date: "2019-09-03",
        value: 0.9143275121148396,
      },
      {
        date: "2019-09-04",
        value: 0.907605736068252,
      },
      {
        date: "2019-09-05",
        value: 0.904322662325918,
      },
      {
        date: "2019-09-06",
        value: 0.9068649678062937,
      },
      {
        date: "2019-09-09",
        value: 0.9063717937097798,
      },
      {
        date: "2019-09-10",
        value: 0.9057971014492753,
      },
      {
        date: "2019-09-11",
        value: 0.9088430428065073,
      },
      {
        date: "2019-09-12",
        value: 0.9121590805436468,
      },
      {
        date: "2019-09-13",
        value: 0.9012256669069936,
      },
      {
        date: "2019-09-16",
        value: 0.9065361254645998,
      },
      {
        date: "2019-09-17",
        value: 0.9069472156720478,
      },
      {
        date: "2019-09-18",
        value: 0.9047317470370035,
      },
      {
        date: "2019-09-19",
        value: 0.9035872413481522,
      },
      {
        date: "2019-09-20",
        value: 0.9066183136899365,
      },
      {
        date: "2019-09-23",
        value: 0.9103322712790168,
      },
      {
        date: "2019-09-24",
        value: 0.9088430428065073,
      },
      {
        date: "2019-09-25",
        value: 0.9105809506465125,
      },
      {
        date: "2019-09-26",
        value: 0.9142439202779301,
      },
      {
        date: "2019-09-27",
        value: 0.9144947416552356,
      },
      {
        date: "2019-09-30",
        value: 0.918357975939021,
      },
      {
        date: "2019-10-01",
        value: 0.9175995595522113,
      },
      {
        date: "2019-10-02",
        value: 0.9153318077803203,
      },
      {
        date: "2019-10-03",
        value: 0.9131586156515387,
      },
      {
        date: "2019-10-04",
        value: 0.91082976591675,
      },
      {
        date: "2019-10-07",
        value: 0.9096697898662786,
      },
      {
        date: "2019-10-08",
        value: 0.9102494083378846,
      },
      {
        date: "2019-10-09",
        value: 0.9106638739641197,
      },
      {
        date: "2019-10-10",
        value: 0.9066183136899365,
      },
      {
        date: "2019-10-11",
        value: 0.9055510278004165,
      },
      {
        date: "2019-10-14",
        value: 0.9065361254645998,
      },
      {
        date: "2019-10-15",
        value: 0.9085127646043427,
      },
      {
        date: "2019-10-16",
        value: 0.9070294784580498,
      },
      {
        date: "2019-10-17",
        value: 0.8998470260055791,
      },
      {
        date: "2019-10-18",
        value: 0.8973438621679827,
      },
      {
        date: "2019-10-21",
        value: 0.8950147677436678,
      },
      {
        date: "2019-10-22",
        value: 0.8984725965858041,
      },
      {
        date: "2019-10-23",
        value: 0.8990380293086397,
      },
      {
        date: "2019-10-24",
        value: 0.8986340762041697,
      },
      {
        date: "2019-10-25",
        value: 0.9003331232556046,
      },
      {
        date: "2019-10-28",
        value: 0.9019572472264814,
      },
      {
        date: "2019-10-29",
        value: 0.9013068949977467,
      },
      {
        date: "2019-10-30",
        value: 0.9004141905276427,
      },
      {
        date: "2019-10-31",
        value: 0.8965393580778197,
      },
      {
        date: "2019-11-01",
        value: 0.8977466558937068,
      },
      {
        date: "2019-11-04",
        value: 0.8962179602079227,
      },
      {
        date: "2019-11-05",
        value: 0.9001710324961743,
      },
      {
        date: "2019-11-06",
        value: 0.9017132551848512,
      },
      {
        date: "2019-11-07",
        value: 0.9027715085311908,
      },
      {
        date: "2019-11-08",
        value: 0.9062896501721951,
      },
      {
        date: "2019-11-11",
        value: 0.9057150620414817,
      },
      {
        date: "2019-11-12",
        value: 0.9078529278256923,
      },
      {
        date: "2019-11-13",
        value: 0.9085953116481919,
      },
      {
        date: "2019-11-14",
        value: 0.9093389106119851,
      },
      {
        date: "2019-11-15",
        value: 0.9062896501721951,
      },
      {
        date: "2019-11-18",
        value: 0.9040773890245004,
      },
      {
        date: "2019-11-19",
        value: 0.9027715085311908,
      },
      {
        date: "2019-11-20",
        value: 0.9042408897730354,
      },
      {
        date: "2019-11-21",
        value: 0.901631953836444,
      },
      {
        date: "2019-11-22",
        value: 0.904322662325918,
      },
      {
        date: "2019-11-25",
        value: 0.9084302325581395,
      },
      {
        date: "2019-11-26",
        value: 0.9074410163339383,
      },
      {
        date: "2019-11-27",
        value: 0.9083477155054955,
      },
      {
        date: "2019-11-28",
        value: 0.9086778736937755,
      },
      {
        date: "2019-11-29",
        value: 0.9105809506465125,
      },
      {
        date: "2019-12-02",
        value: 0.9071940488070398,
      },
      {
        date: "2019-12-03",
        value: 0.9032607713846987,
      },
      {
        date: "2019-12-04",
        value: 0.902445627650934,
      },
      {
        date: "2019-12-05",
        value: 0.9013881377321075,
      },
      {
        date: "2019-12-06",
        value: 0.9013881377321075,
      },
      {
        date: "2019-12-09",
        value: 0.9029345372460498,
      },
      {
        date: "2019-12-10",
        value: 0.9027715085311908,
      },
      {
        date: "2019-12-11",
        value: 0.9029345372460498,
      },
      {
        date: "2019-12-12",
        value: 0.8979078746520608,
      },
      {
        date: "2019-12-13",
        value: 0.8949346697691068,
      },
      {
        date: "2019-12-16",
        value: 0.897182845863987,
      },
      {
        date: "2019-12-17",
        value: 0.8958967926894821,
      },
      {
        date: "2019-12-18",
        value: 0.899685110211426,
      },
      {
        date: "2019-12-19",
        value: 0.8995232526760818,
      },
      {
        date: "2019-12-20",
        value: 0.901144453455889,
      },
      {
        date: "2019-12-23",
        value: 0.9029345372460498,
      },
      {
        date: "2019-12-24",
        value: 0.9025270758122743,
      },
      {
        date: "2019-12-27",
        value: 0.8966197435667533,
      },
      {
        date: "2019-12-30",
        value: 0.893734918223255,
      },
      {
        date: "2019-12-31",
        value: 0.8901548869503294,
      },
      {
        date: "2020-01-02",
        value: 0.893415527561869,
      },
      {
        date: "2020-01-03",
        value: 0.8971023593792051,
      },
      {
        date: "2020-01-06",
        value: 0.8933357155619082,
      },
      {
        date: "2020-01-07",
        value: 0.8950948800572861,
      },
      {
        date: "2020-01-08",
        value: 0.899685110211426,
      },
      {
        date: "2020-01-09",
        value: 0.9000900090009001,
      },
      {
        date: "2020-01-10",
        value: 0.901631953836444,
      },
      {
        date: "2020-01-13",
        value: 0.8987956138774043,
      },
      {
        date: "2020-01-14",
        value: 0.899685110211426,
      },
      {
        date: "2020-01-15",
        value: 0.8975049362771494,
      },
      {
        date: "2020-01-16",
        value: 0.8953353030710001,
      },
      {
        date: "2020-01-17",
        value: 0.9002520705797623,
      },
      {
        date: "2020-01-20",
        value: 0.9021199819576003,
      },
      {
        date: "2020-01-21",
        value: 0.899685110211426,
      },
      {
        date: "2020-01-22",
        value: 0.9018759018759018,
      },
      {
        date: "2020-01-23",
        value: 0.901631953836444,
      },
      {
        date: "2020-01-24",
        value: 0.9062075215224287,
      },
      {
        date: "2020-01-27",
        value: 0.9070294784580498,
      },
      {
        date: "2020-01-28",
        value: 0.9086778736937755,
      },
      {
        date: "2020-01-29",
        value: 0.9090082719752749,
      },
      {
        date: "2020-01-30",
        value: 0.9067005168192946,
      },
      {
        date: "2020-01-31",
        value: 0.9048136083966704,
      },
      {
        date: "2020-02-03",
        value: 0.9036688957166095,
      },
      {
        date: "2020-02-04",
        value: 0.9051412020275162,
      },
      {
        date: "2020-02-05",
        value: 0.9071940488070398,
      },
      {
        date: "2020-02-06",
        value: 0.9088430428065073,
      },
      {
        date: "2020-02-07",
        value: 0.9116601331023795,
      },
      {
        date: "2020-02-10",
        value: 0.9131586156515387,
      },
      {
        date: "2020-02-11",
        value: 0.9173470323823502,
      },
      {
        date: "2020-02-12",
        value: 0.9162543522081731,
      },
      {
        date: "2020-02-13",
        value: 0.9202171712524155,
      },
      {
        date: "2020-02-14",
        value: 0.9223390518354546,
      },
      {
        date: "2020-02-17",
        value: 0.9229349330872174,
      },
      {
        date: "2020-02-18",
        value: 0.9245562130177516,
      },
      {
        date: "2020-02-19",
        value: 0.9259259259259258,
      },
      {
        date: "2020-02-20",
        value: 0.9267840593141798,
      },
      {
        date: "2020-02-21",
        value: 0.9258401999814831,
      },
      {
        date: "2020-02-24",
        value: 0.924385283786282,
      },
      {
        date: "2020-02-25",
        value: 0.9225092250922509,
      },
      {
        date: "2020-02-26",
        value: 0.9195402298850576,
      },
      {
        date: "2020-02-27",
        value: 0.9120758847136081,
      },
      {
        date: "2020-02-28",
        value: 0.9109957183201239,
      },
      {
        date: "2020-03-02",
        value: 0.8991188635137565,
      },
      {
        date: "2020-03-03",
        value: 0.8995232526760818,
      },
      {
        date: "2020-03-04",
        value: 0.898876404494382,
      },
      {
        date: "2020-03-05",
        value: 0.8938946992044337,
      },
      {
        date: "2020-03-06",
        value: 0.8821453775582216,
      },
      {
        date: "2020-03-09",
        value: 0.8729050279329609,
      },
      {
        date: "2020-03-10",
        value: 0.8779631255487269,
      },
      {
        date: "2020-03-11",
        value: 0.8821453775582216,
      },
      {
        date: "2020-03-12",
        value: 0.889679715302491,
      },
      {
        date: "2020-03-13",
        value: 0.9005763688760806,
      },
      {
        date: "2020-03-16",
        value: 0.8962982880702699,
      },
      {
        date: "2020-03-17",
        value: 0.9105809506465125,
      },
      {
        date: "2020-03-18",
        value: 0.9145783793671118,
      },
      {
        date: "2020-03-19",
        value: 0.9258401999814831,
      },
      {
        date: "2020-03-20",
        value: 0.933968431867003,
      },
      {
        date: "2020-03-23",
        value: 0.9273856997125104,
      },
      {
        date: "2020-03-24",
        value: 0.9222539887485013,
      },
      {
        date: "2020-03-25",
        value: 0.9236168837166343,
      },
      {
        date: "2020-03-26",
        value: 0.9106638739641197,
      },
      {
        date: "2020-03-27",
        value: 0.9109957183201239,
      },
      {
        date: "2020-03-30",
        value: 0.9062896501721951,
      },
      {
        date: "2020-03-31",
        value: 0.9127418765972983,
      },
      {
        date: "2020-04-01",
        value: 0.91441111923921,
      },
      {
        date: "2020-04-02",
        value: 0.9169264624977077,
      },
      {
        date: "2020-04-03",
        value: 0.9272137227630969,
      },
      {
        date: "2020-04-06",
        value: 0.9266981744045965,
      },
      {
        date: "2020-04-07",
        value: 0.9186954524575103,
      },
      {
        date: "2020-04-08",
        value: 0.9198785760279643,
      },
      {
        date: "2020-04-09",
        value: 0.9202171712524155,
      },
      {
        date: "2020-04-14",
        value: 0.9121590805436468,
      },
      {
        date: "2020-04-15",
        value: 0.9171787581399614,
      },
      {
        date: "2020-04-16",
        value: 0.9184423218221895,
      },
      {
        date: "2020-04-17",
        value: 0.9208103130755064,
      },
      {
        date: "2020-04-20",
        value: 0.9208103130755064,
      },
      {
        date: "2020-04-21",
        value: 0.9227646027498384,
      },
      {
        date: "2020-04-22",
        value: 0.9202171712524155,
      },
      {
        date: "2020-04-23",
        value: 0.9283327144448571,
      },
      {
        date: "2020-04-24",
        value: 0.9259259259259258,
      },
      {
        date: "2020-04-27",
        value: 0.9214891264283082,
      },
      {
        date: "2020-04-28",
        value: 0.919371150133309,
      },
      {
        date: "2020-04-29",
        value: 0.9223390518354546,
      },
      {
        date: "2020-04-30",
        value: 0.9194556822361163,
      },
      {
        date: "2020-05-04",
        value: 0.9139097057210747,
      },
      {
        date: "2020-05-05",
        value: 0.9222539887485013,
      },
      {
        date: "2020-05-06",
        value: 0.9253261774775609,
      },
      {
        date: "2020-05-07",
        value: 0.9273856997125104,
      },
      {
        date: "2020-05-08",
        value: 0.9222539887485013,
      },
      {
        date: "2020-05-11",
        value: 0.9238728750923872,
      },
      {
        date: "2020-05-12",
        value: 0.9209799226376865,
      },
      {
        date: "2020-05-13",
        value: 0.9195402298850576,
      },
      {
        date: "2020-05-14",
        value: 0.9266123054114159,
      },
      {
        date: "2020-05-15",
        value: 0.9260974254491572,
      },
      {
        date: "2020-05-18",
        value: 0.9231905465288036,
      },
      {
        date: "2020-05-19",
        value: 0.9132420091324202,
      },
      {
        date: "2020-05-20",
        value: 0.9125752874612154,
      },
      {
        date: "2020-05-21",
        value: 0.9090909090909091,
      },
      {
        date: "2020-05-22",
        value: 0.917094644167278,
      },
      {
        date: "2020-05-25",
        value: 0.9165902841429882,
      },
      {
        date: "2020-05-26",
        value: 0.9111617312072894,
      },
      {
        date: "2020-05-27",
        value: 0.909835319807115,
      },
      {
        date: "2020-05-28",
        value: 0.907770515613653,
      },
      {
        date: "2020-05-29",
        value: 0.8979885057471265,
      },
      {
        date: "2020-06-01",
        value: 0.8996041741633681,
      },
      {
        date: "2020-06-02",
        value: 0.8949346697691068,
      },
      {
        date: "2020-06-03",
        value: 0.8933357155619082,
      },
      {
        date: "2020-06-04",
        value: 0.8888888888888888,
      },
      {
        date: "2020-06-05",
        value: 0.8826125330979699,
      },
      {
        date: "2020-06-08",
        value: 0.8861320336730172,
      },
      {
        date: "2020-06-09",
        value: 0.8854258898530193,
      },
      {
        date: "2020-06-10",
        value: 0.8791208791208791,
      },
      {
        date: "2020-06-11",
        value: 0.8812125484666902,
      },
      {
        date: "2020-06-12",
        value: 0.8846426043878273,
      },
      {
        date: "2020-06-15",
        value: 0.888651915044877,
      },
      {
        date: "2020-06-16",
        value: 0.8843296781039971,
      },
      {
        date: "2020-06-17",
        value: 0.8903133903133903,
      },
      {
        date: "2020-06-18",
        value: 0.8911067545891997,
      },
      {
        date: "2020-06-19",
        value: 0.8920606601248885,
      },
      {
        date: "2020-06-22",
        value: 0.8918219923303309,
      },
      {
        date: "2020-06-23",
        value: 0.8835483300936562,
      },
      {
        date: "2020-06-24",
        value: 0.8865248226950355,
      },
      {
        date: "2020-06-25",
        value: 0.8928571428571428,
      },
      {
        date: "2020-06-26",
        value: 0.8918219923303309,
      },
      {
        date: "2020-06-29",
        value: 0.8862105636299185,
      },
      {
        date: "2020-06-30",
        value: 0.8930166101089481,
      },
      {
        date: "2020-07-01",
        value: 0.8928571428571428,
      },
      {
        date: "2020-07-02",
        value: 0.886053517632465,
      },
      {
        date: "2020-07-03",
        value: 0.8909479686386315,
      },
      {
        date: "2020-07-06",
        value: 0.8830022075055187,
      },
      {
        date: "2020-07-07",
        value: 0.8857395925597874,
      },
      {
        date: "2020-07-08",
        value: 0.886053517632465,
      },
      {
        date: "2020-07-09",
        value: 0.881678716275789,
      },
      {
        date: "2020-07-10",
        value: 0.8868393047179851,
      },
      {
        date: "2020-07-13",
        value: 0.8826904404625298,
      },
      {
        date: "2020-07-14",
        value: 0.8791208791208791,
      },
      {
        date: "2020-07-15",
        value: 0.8738203425375742,
      },
      {
        date: "2020-07-16",
        value: 0.8761170492377782,
      },
      {
        date: "2020-07-17",
        value: 0.8750437521876093,
      },
      {
        date: "2020-07-20",
        value: 0.8735150244584207,
      },
      {
        date: "2020-07-21",
        value: 0.8738967054094205,
      },
      {
        date: "2020-07-22",
        value: 0.8637070305752289,
      },
      {
        date: "2020-07-23",
        value: 0.8643789437289308,
      },
      {
        date: "2020-07-24",
        value: 0.8614748449345279,
      },
      {
        date: "2020-07-27",
        value: 0.8503401360544218,
      },
      {
        date: "2020-07-28",
        value: 0.8534607834769993,
      },
      {
        date: "2020-07-29",
        value: 0.8528784648187633,
      },
      {
        date: "2020-07-30",
        value: 0.8515711487694798,
      },
      {
        date: "2020-07-31",
        value: 0.8440243079000674,
      },
      {
        date: "2020-08-03",
        value: 0.8528057308545113,
      },
      {
        date: "2020-08-04",
        value: 0.8499787505312366,
      },
      {
        date: "2020-08-05",
        value: 0.8435970980259828,
      },
      {
        date: "2020-08-06",
        value: 0.8443806467955756,
      },
      {
        date: "2020-08-07",
        value: 0.8462384700008463,
      },
      {
        date: "2020-08-10",
        value: 0.8501232678738417,
      },
      {
        date: "2020-08-11",
        value: 0.8486803021301876,
      },
      {
        date: "2020-08-12",
        value: 0.8495454931611588,
      },
      {
        date: "2020-08-13",
        value: 0.8450942280064228,
      },
      {
        date: "2020-08-14",
        value: 0.8465250148141877,
      },
      {
        date: "2020-08-17",
        value: 0.843668269636379,
      },
      {
        date: "2020-08-18",
        value: 0.8399126490844951,
      },
      {
        date: "2020-08-19",
        value: 0.8380122349786306,
      },
      {
        date: "2020-08-20",
        value: 0.8438818565400843,
      },
      {
        date: "2020-08-21",
        value: 0.849689863199932,
      },
      {
        date: "2020-08-24",
        value: 0.8440955516164429,
      },
      {
        date: "2020-08-25",
        value: 0.8464533604198409,
      },
      {
        date: "2020-08-26",
        value: 0.8482483671218932,
      },
      {
        date: "2020-08-27",
        value: 0.8470269354565475,
      },
      {
        date: "2020-08-28",
        value: 0.839278220730172,
      },
      {
        date: "2020-08-31",
        value: 0.8375209380234506,
      },
      {
        date: "2020-09-01",
        value: 0.8342370901810294,
      },
      {
        date: "2020-09-02",
        value: 0.8430992327796982,
      },
      {
        date: "2020-09-03",
        value: 0.8465250148141877,
      },
      {
        date: "2020-09-04",
        value: 0.8444519506840061,
      },
      {
        date: "2020-09-07",
        value: 0.8457374830852504,
      },
      {
        date: "2020-09-08",
        value: 0.848536274925753,
      },
      {
        date: "2020-09-09",
        value: 0.8494011721736175,
      },
      {
        date: "2020-09-10",
        value: 0.8439530762089628,
      },
      {
        date: "2020-09-11",
        value: 0.8435970980259828,
      },
      {
        date: "2020-09-14",
        value: 0.8420343550016841,
      },
      {
        date: "2020-09-15",
        value: 0.8409014463504877,
      },
      {
        date: "2020-09-16",
        value: 0.8425309630128907,
      },
      {
        date: "2020-09-17",
        value: 0.847673137238281,
      },
      {
        date: "2020-09-18",
        value: 0.8450942280064228,
      },
      {
        date: "2020-09-21",
        value: 0.8483922965979468,
      },
      {
        date: "2020-09-22",
        value: 0.8517887563884157,
      },
      {
        date: "2020-09-23",
        value: 0.8552856654122477,
      },
      {
        date: "2020-09-24",
        value: 0.8587376556462,
      },
      {
        date: "2020-09-25",
        value: 0.8595495960116899,
      },
      {
        date: "2020-09-28",
        value: 0.856898029134533,
      },
      {
        date: "2020-09-29",
        value: 0.8545547769612033,
      },
      {
        date: "2020-09-30",
        value: 0.8541168431841476,
      },
      {
        date: "2020-10-01",
        value: 0.8509189925119128,
      },
      {
        date: "2020-10-02",
        value: 0.8525149190110827,
      },
      {
        date: "2020-10-05",
        value: 0.849762066621346,
      },
      {
        date: "2020-10-06",
        value: 0.8478168715557439,
      },
      {
        date: "2020-10-07",
        value: 0.8496176720475785,
      },
      {
        date: "2020-10-08",
        value: 0.8499787505312366,
      },
      {
        date: "2020-10-09",
        value: 0.8478168715557439,
      },
      {
        date: "2020-10-12",
        value: 0.8475294516484448,
      },
      {
        date: "2020-10-13",
        value: 0.8483922965979468,
      },
      {
        date: "2020-10-14",
        value: 0.851063829787234,
      },
      {
        date: "2020-10-15",
        value: 0.8548469823901522,
      },
      {
        date: "2020-10-16",
        value: 0.8517162081594414,
      },
      {
        date: "2020-10-19",
        value: 0.848536274925753,
      },
      {
        date: "2020-10-20",
        value: 0.8467400508044031,
      },
      {
        date: "2020-10-21",
        value: 0.8437394532568343,
      },
      {
        date: "2020-10-22",
        value: 0.8459521191100584,
      },
      {
        date: "2020-10-23",
        value: 0.8434547908232118,
      },
      {
        date: "2020-10-26",
        value: 0.8460952703274389,
      },
      {
        date: "2020-10-27",
        value: 0.8451656524678837,
      },
      {
        date: "2020-10-28",
        value: 0.8527330092947898,
      },
      {
        date: "2020-10-29",
        value: 0.8544087491455912,
      },
      {
        date: "2020-10-30",
        value: 0.8548469823901522,
      },
      {
        date: "2020-11-02",
        value: 0.8582217645039478,
      },
      {
        date: "2020-11-03",
        value: 0.8545547769612033,
      },
      {
        date: "2020-11-04",
        value: 0.8531695247845748,
      },
      {
        date: "2020-11-05",
        value: 0.8435259384226065,
      },
      {
        date: "2020-11-06",
        value: 0.8424599831508003,
      },
      {
        date: "2020-11-09",
        value: 0.8415383320710259,
      },
      {
        date: "2020-11-10",
        value: 0.8468834688346883,
      },
      {
        date: "2020-11-11",
        value: 0.8499065102838687,
      },
      {
        date: "2020-11-12",
        value: 0.8481044864727334,
      },
      {
        date: "2020-11-13",
        value: 0.8463817181548878,
      },
      {
        date: "2020-11-16",
        value: 0.8453085376162299,
      },
      {
        date: "2020-11-17",
        value: 0.841609156707625,
      },
      {
        date: "2020-11-18",
        value: 0.8426019548365352,
      },
      {
        date: "2020-11-19",
        value: 0.8451656524678837,
      },
      {
        date: "2020-11-20",
        value: 0.8429570934839418,
      },
      {
        date: "2020-11-23",
        value: 0.8402655239055542,
      },
      {
        date: "2020-11-24",
        value: 0.8428150021070374,
      },
      {
        date: "2020-11-25",
        value: 0.8410428931875525,
      },
      {
        date: "2020-11-26",
        value: 0.8403361344537815,
      },
      {
        date: "2020-11-27",
        value: 0.8387854386847845,
      },
      {
        date: "2020-11-30",
        value: 0.8347245409015025,
      },
      {
        date: "2020-12-01",
        value: 0.8355614973262031,
      },
      {
        date: "2020-12-02",
        value: 0.8287750704458811,
      },
      {
        date: "2020-12-03",
        value: 0.8229775327133568,
      },
      {
        date: "2020-12-04",
        value: 0.8224360555966773,
      },
      {
        date: "2020-12-07",
        value: 0.8245382585751978,
      },
      {
        date: "2020-12-08",
        value: 0.8254911672445104,
      },
      {
        date: "2020-12-09",
        value: 0.8258320257659592,
      },
      {
        date: "2020-12-10",
        value: 0.8254230293025175,
      },
      {
        date: "2020-12-11",
        value: 0.824606250515379,
      },
      {
        date: "2020-12-14",
        value: 0.82223318533136,
      },
      {
        date: "2020-12-15",
        value: 0.8237232289950577,
      },
      {
        date: "2020-12-16",
        value: 0.820411846747067,
      },
      {
        date: "2020-12-17",
        value: 0.8165931732810714,
      },
      {
        date: "2020-12-18",
        value: 0.8157272208173587,
      },
      {
        date: "2020-12-21",
        value: 0.8214901831923108,
      },
      {
        date: "2020-12-22",
        value: 0.8170602173380178,
      },
      {
        date: "2020-12-23",
        value: 0.821962847279303,
      },
      {
        date: "2020-12-24",
        value: 0.8201427048306404,
      },
      {
        date: "2020-12-28",
        value: 0.8183975775431704,
      },
      {
        date: "2020-12-29",
        value: 0.8157272208173587,
      },
      {
        date: "2020-12-30",
        value: 0.814265939255761,
      },
      {
        date: "2020-12-31",
        value: 0.8149295085975062,
      },
      {
        date: "2021-01-04",
        value: 0.8132726089785296,
      },
      {
        date: "2021-01-05",
        value: 0.8149295085975062,
      },
      {
        date: "2021-01-06",
        value: 0.8105041335710812,
      },
      {
        date: "2021-01-07",
        value: 0.8145975887911372,
      },
      {
        date: "2021-01-08",
        value: 0.8163265306122448,
      },
      {
        date: "2021-01-11",
        value: 0.8221655841486476,
      },
      {
        date: "2021-01-12",
        value: 0.8223007976317738,
      },
      {
        date: "2021-01-13",
        value: 0.821962847279303,
      },
      {
        date: "2021-01-14",
        value: 0.8248102936324646,
      },
      {
        date: "2021-01-15",
        value: 0.8248783304462592,
      },
      {
        date: "2021-01-18",
        value: 0.8289124668435014,
      },
      {
        date: "2021-01-19",
        value: 0.8242664029014177,
      },
      {
        date: "2021-01-20",
        value: 0.8263779852904719,
      },
      {
        date: "2021-01-21",
        value: 0.8225037012666557,
      },
      {
        date: "2021-01-22",
        value: 0.8225037012666557,
      },
      {
        date: "2021-01-25",
        value: 0.8229098090849243,
      },
      {
        date: "2021-01-26",
        value: 0.823519723297373,
      },
      {
        date: "2021-01-27",
        value: 0.8254911672445104,
      },
      {
        date: "2021-01-28",
        value: 0.8270614506657844,
      },
      {
        date: "2021-01-29",
        value: 0.8239947264337508,
      },
      {
        date: "2021-02-01",
        value: 0.8275405494869249,
      },
      {
        date: "2021-02-02",
        value: 0.8302889405513119,
      },
      {
        date: "2021-02-03",
        value: 0.8321544478655238,
      },
      {
        date: "2021-02-04",
        value: 0.8336112037345782,
      },
      {
        date: "2021-02-05",
        value: 0.8345155637152634,
      },
      {
        date: "2021-02-08",
        value: 0.8316008316008316,
      },
      {
        date: "2021-02-09",
        value: 0.8261731658955718,
      },
      {
        date: "2021-02-10",
        value: 0.824606250515379,
      },
      {
        date: "2021-02-11",
        value: 0.8232485387338438,
      },
      {
        date: "2021-02-12",
        value: 0.8259002312520647,
      },
      {
        date: "2021-02-15",
        value: 0.8244702778464835,
      },
      {
        date: "2021-02-16",
        value: 0.823519723297373,
      },
      {
        date: "2021-02-17",
        value: 0.8291873963515755,
      },
      {
        date: "2021-02-18",
        value: 0.8275405494869249,
      },
      {
        date: "2021-02-19",
        value: 0.8237910865804432,
      },
      {
        date: "2021-02-22",
        value: 0.8241984669908514,
      },
      {
        date: "2021-02-23",
        value: 0.823519723297373,
      },
      {
        date: "2021-02-24",
        value: 0.8233163181294254,
      },
      {
        date: "2021-02-25",
        value: 0.8179959100204499,
      },
      {
        date: "2021-02-26",
        value: 0.8250144377526607,
      },
      {
        date: "2021-03-01",
        value: 0.8296689620841284,
      },
      {
        date: "2021-03-02",
        value: 0.8313934153641502,
      },
      {
        date: "2021-03-03",
        value: 0.8300132802124833,
      },
      {
        date: "2021-03-04",
        value: 0.8309788931361143,
      },
      {
        date: "2021-03-05",
        value: 0.8376612497905848,
      },
      {
        date: "2021-03-08",
        value: 0.8427439743805831,
      },
      {
        date: "2021-03-09",
        value: 0.8407600470825626,
      },
      {
        date: "2021-03-10",
        value: 0.8409014463504877,
      },
      {
        date: "2021-03-11",
        value: 0.8354916868577157,
      },
      {
        date: "2021-03-12",
        value: 0.8380122349786306,
      },
      {
        date: "2021-03-15",
        value: 0.8389261744966443,
      },
      {
        date: "2021-03-16",
        value: 0.8385041086701324,
      },
      {
        date: "2021-03-17",
        value: 0.8398421096833795,
      },
      {
        date: "2021-03-18",
        value: 0.8394895903290799,
      },
      {
        date: "2021-03-19",
        value: 0.8409721638213775,
      },
      {
        date: "2021-03-22",
        value: 0.8385041086701324,
      },
      {
        date: "2021-03-23",
        value: 0.8415383320710259,
      },
      {
        date: "2021-03-24",
        value: 0.8456659619450316,
      },
      {
        date: "2021-03-25",
        value: 0.8473140145738011,
      },
      {
        date: "2021-03-26",
        value: 0.8487523340689187,
      },
      {
        date: "2021-03-29",
        value: 0.8486082824168365,
      },
      {
        date: "2021-03-30",
        value: 0.8517162081594414,
      },
      {
        date: "2021-03-31",
        value: 0.8528784648187633,
      },
      {
        date: "2021-04-01",
        value: 0.8513536523071683,
      },
      {
        date: "2021-04-06",
        value: 0.8465966813410091,
      },
      {
        date: "2021-04-07",
        value: 0.841467519353753,
      },
      {
        date: "2021-04-08",
        value: 0.8422471153036301,
      },
      {
        date: "2021-04-09",
        value: 0.8411843876177657,
      },
      {
        date: "2021-04-12",
        value: 0.8400537634408602,
      },
      {
        date: "2021-04-13",
        value: 0.8406186953597848,
      },
      {
        date: "2021-04-14",
        value: 0.8358408559010365,
      },
      {
        date: "2021-04-15",
        value: 0.835421888053467,
      },
      {
        date: "2021-04-16",
        value: 0.8343066911396628,
      },
      {
        date: "2021-04-19",
        value: 0.8309098462816784,
      },
      {
        date: "2021-04-20",
        value: 0.8298066550493735,
      },
      {
        date: "2021-04-21",
        value: 0.8328475056217206,
      },
      {
        date: "2021-04-22",
        value: 0.8301510874979247,
      },
      {
        date: "2021-04-23",
        value: 0.8287750704458811,
      },
      {
        date: "2021-04-26",
        value: 0.8274720728175424,
      },
      {
        date: "2021-04-27",
        value: 0.8272667107875579,
      },
      {
        date: "2021-04-28",
        value: 0.828500414250207,
      },
      {
        date: "2021-04-29",
        value: 0.8244702778464835,
      },
      {
        date: "2021-04-30",
        value: 0.8276775368316505,
      },
      {
        date: "2021-05-03",
        value: 0.8302889405513119,
      },
      {
        date: "2021-05-04",
        value: 0.8318775476249897,
      },
      {
        date: "2021-05-05",
        value: 0.8329862557267805,
      },
      {
        date: "2021-05-06",
        value: 0.8291873963515755,
      },
      {
        date: "2021-05-07",
        value: 0.8292561572269674,
      },
      {
        date: "2021-05-10",
        value: 0.8217602103706138,
      },
      {
        date: "2021-05-11",
        value: 0.8216926869350862,
      },
      {
        date: "2021-05-12",
        value: 0.825218682950982,
      },
      {
        date: "2021-05-13",
        value: 0.8277460475126232,
      },
      {
        date: "2021-05-14",
        value: 0.8248783304462592,
      },
      {
        date: "2021-05-17",
        value: 0.823519723297373,
      },
      {
        date: "2021-05-18",
        value: 0.8181966944853543,
      },
      {
        date: "2021-05-19",
        value: 0.8188666885031116,
      },
      {
        date: "2021-05-20",
        value: 0.8194706219782021,
      },
      {
        date: "2021-05-21",
        value: 0.8204791598293403,
      },
      {
        date: "2021-05-24",
        value: 0.8188666885031116,
      },
      {
        date: "2021-05-25",
        value: 0.8153946510110894,
      },
      {
        date: "2021-05-26",
        value: 0.8177283506419167,
      },
      {
        date: "2021-05-27",
        value: 0.8198065256599443,
      },
      {
        date: "2021-05-28",
        value: 0.823587547356284,
      },
      {
        date: "2021-05-31",
        value: 0.8196049504139006,
      },
      {
        date: "2021-06-01",
        value: 0.8179959100204499,
      },
      {
        date: "2021-06-02",
        value: 0.8206138191367143,
      },
      {
        date: "2021-06-03",
        value: 0.8205464839583163,
      },
      {
        date: "2021-06-04",
        value: 0.8252867871585375,
      },
      {
        date: "2021-06-07",
        value: 0.82223318533136,
      },
      {
        date: "2021-06-08",
        value: 0.8208832703989493,
      },
      {
        date: "2021-06-09",
        value: 0.8200082000820008,
      },
      {
        date: "2021-06-10",
        value: 0.8214227041235419,
      },
      {
        date: "2021-06-11",
        value: 0.8247422680412372,
      },
      {
        date: "2021-06-14",
        value: 0.8256274768824307,
      },
      {
        date: "2021-06-15",
        value: 0.8259002312520647,
      },
      {
        date: "2021-06-16",
        value: 0.8248102936324646,
      },
      {
        date: "2021-06-17",
        value: 0.8377314233056882,
      },
      {
        date: "2021-06-18",
        value: 0.8404773911581779,
      },
      {
        date: "2021-06-21",
        value: 0.8409721638213775,
      },
      {
        date: "2021-06-22",
        value: 0.8407600470825626,
      },
      {
        date: "2021-06-23",
        value: 0.8367500627562546,
      },
      {
        date: "2021-06-24",
        value: 0.8378016085790885,
      },
      {
        date: "2021-06-25",
        value: 0.8368200836820083,
      },
      {
        date: "2021-06-28",
        value: 0.8396305625524769,
      },
      {
        date: "2021-06-29",
        value: 0.8411843876177657,
      },
      {
        date: "2021-06-30",
        value: 0.841467519353753,
      },
      {
        date: "2021-07-01",
        value: 0.841467519353753,
      },
    ],
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    currency: "JPY",
    currencySymbol: "¥",
    placeholderValue: 14180,
    min: 98,
    max: 118,
    ppp: 1.07,
    vat: 0.1,
    fxRates: [
      {
        date: "2019-01-01",
        value: 109.0462402387,
      },
      {
        date: "2019-01-02",
        value: 107.6929855481,
      },
      {
        date: "2019-01-03",
        value: 108.0417434009,
      },
      {
        date: "2019-01-06",
        value: 108.2568807339,
      },
      {
        date: "2019-01-07",
        value: 108.7937062937,
      },
      {
        date: "2019-01-08",
        value: 108.8607594937,
      },
      {
        date: "2019-01-09",
        value: 108.1057650629,
      },
      {
        date: "2019-01-10",
        value: 108.3065984566,
      },
      {
        date: "2019-01-13",
        value: 108.0753466469,
      },
      {
        date: "2019-01-14",
        value: 108.5609243697,
      },
      {
        date: "2019-01-15",
        value: 108.7979629467,
      },
      {
        date: "2019-01-16",
        value: 108.7662337662,
      },
      {
        date: "2019-01-17",
        value: 109.4369408876,
      },
      {
        date: "2019-01-20",
        value: 109.663791586,
      },
      {
        date: "2019-01-21",
        value: 109.4327990136,
      },
      {
        date: "2019-01-22",
        value: 109.7035277558,
      },
      {
        date: "2019-01-23",
        value: 109.7169561767,
      },
      {
        date: "2019-01-24",
        value: 109.9242023621,
      },
      {
        date: "2019-01-27",
        value: 109.4237169382,
      },
      {
        date: "2019-01-28",
        value: 109.4641919103,
      },
      {
        date: "2019-01-29",
        value: 109.4408959664,
      },
      {
        date: "2019-01-30",
        value: 108.6438022284,
      },
      {
        date: "2019-01-31",
        value: 108.8919884927,
      },
      {
        date: "2019-02-03",
        value: 109.8907820009,
      },
      {
        date: "2019-02-04",
        value: 109.9448481135,
      },
      {
        date: "2019-02-05",
        value: 109.7507460067,
      },
      {
        date: "2019-02-06",
        value: 109.6606434553,
      },
      {
        date: "2019-02-07",
        value: 109.7919971796,
      },
      {
        date: "2019-02-10",
        value: 110.2042620921,
      },
      {
        date: "2019-02-11",
        value: 110.3930594901,
      },
      {
        date: "2019-02-12",
        value: 110.738611234,
      },
      {
        date: "2019-02-13",
        value: 111.040113596,
      },
      {
        date: "2019-02-14",
        value: 110.5239786856,
      },
      {
        date: "2019-02-17",
        value: 110.5490819209,
      },
      {
        date: "2019-02-18",
        value: 110.7579245617,
      },
      {
        date: "2019-02-19",
        value: 110.7476635514,
      },
      {
        date: "2019-02-20",
        value: 110.7098819799,
      },
      {
        date: "2019-02-21",
        value: 110.8697571744,
      },
      {
        date: "2019-02-24",
        value: 110.7441655658,
      },
      {
        date: "2019-02-25",
        value: 110.8441158349,
      },
      {
        date: "2019-02-26",
        value: 110.5743896013,
      },
      {
        date: "2019-02-27",
        value: 110.7568325158,
      },
      {
        date: "2019-02-28",
        value: 111.8773609769,
      },
      {
        date: "2019-03-03",
        value: 111.9431948487,
      },
      {
        date: "2019-03-04",
        value: 111.9251478506,
      },
      {
        date: "2019-03-05",
        value: 111.8089341,
      },
      {
        date: "2019-03-06",
        value: 111.7647058824,
      },
      {
        date: "2019-03-07",
        value: 111.1388344324,
      },
      {
        date: "2019-03-10",
        value: 111.0903593027,
      },
      {
        date: "2019-03-11",
        value: 111.1042128603,
      },
      {
        date: "2019-03-12",
        value: 111.3775103955,
      },
      {
        date: "2019-03-13",
        value: 111.6334661355,
      },
      {
        date: "2019-03-14",
        value: 111.5670321896,
      },
      {
        date: "2019-03-17",
        value: 111.4988104679,
      },
      {
        date: "2019-03-18",
        value: 111.4544814228,
      },
      {
        date: "2019-03-19",
        value: 111.5289765721,
      },
      {
        date: "2019-03-20",
        value: 110.5822429086,
      },
      {
        date: "2019-03-21",
        value: 110.2459741639,
      },
      {
        date: "2019-03-24",
        value: 110.0662251656,
      },
      {
        date: "2019-03-25",
        value: 110.4596581348,
      },
      {
        date: "2019-03-26",
        value: 110.4875233105,
      },
      {
        date: "2019-03-27",
        value: 110.6792654662,
      },
      {
        date: "2019-03-28",
        value: 110.7699154428,
      },
      {
        date: "2019-03-31",
        value: 110.964756141,
      },
      {
        date: "2019-04-01",
        value: 111.3660714286,
      },
      {
        date: "2019-04-02",
        value: 111.4471226541,
      },
      {
        date: "2019-04-03",
        value: 111.4270434085,
      },
      {
        date: "2019-04-04",
        value: 111.670969465,
      },
      {
        date: "2019-04-07",
        value: 111.4707451538,
      },
      {
        date: "2019-04-08",
        value: 111.2973308504,
      },
      {
        date: "2019-04-09",
        value: 111.1623370866,
      },
      {
        date: "2019-04-10",
        value: 111.2393465909,
      },
      {
        date: "2019-04-11",
        value: 111.9689073403,
      },
      {
        date: "2019-04-14",
        value: 111.9596923893,
      },
      {
        date: "2019-04-15",
        value: 111.9770013268,
      },
      {
        date: "2019-04-16",
        value: 111.9458455004,
      },
      {
        date: "2019-04-17",
        value: 111.8755555556,
      },
      {
        date: "2019-04-22",
        value: 111.8897287683,
      },
      {
        date: "2019-04-23",
        value: 111.8208582389,
      },
      {
        date: "2019-04-24",
        value: 111.8852827475,
      },
      {
        date: "2019-04-25",
        value: 111.7847839756,
      },
      {
        date: "2019-04-28",
        value: 111.7847533632,
      },
      {
        date: "2019-04-29",
        value: 111.3656623284,
      },
      {
        date: "2019-05-01",
        value: 111.4966107742,
      },
      {
        date: "2019-05-02",
        value: 111.519497983,
      },
      {
        date: "2019-05-05",
        value: 110.8402535941,
      },
      {
        date: "2019-05-06",
        value: 110.6213679034,
      },
      {
        date: "2019-05-07",
        value: 110.0785574005,
      },
      {
        date: "2019-05-08",
        value: 109.8097024926,
      },
      {
        date: "2019-05-09",
        value: 109.750667854,
      },
      {
        date: "2019-05-12",
        value: 109.4353045798,
      },
      {
        date: "2019-05-13",
        value: 109.5670764297,
      },
      {
        date: "2019-05-14",
        value: 109.3087722436,
      },
      {
        date: "2019-05-15",
        value: 109.6224225654,
      },
      {
        date: "2019-05-16",
        value: 109.5059076262,
      },
      {
        date: "2019-05-19",
        value: 109.8504522253,
      },
      {
        date: "2019-05-20",
        value: 110.4470925544,
      },
      {
        date: "2019-05-21",
        value: 110.3482230776,
      },
      {
        date: "2019-05-22",
        value: 110.0278301463,
      },
      {
        date: "2019-05-23",
        value: 109.6004290695,
      },
      {
        date: "2019-05-26",
        value: 109.448115735,
      },
      {
        date: "2019-05-27",
        value: 109.4085060758,
      },
      {
        date: "2019-05-28",
        value: 109.277518824,
      },
      {
        date: "2019-05-29",
        value: 109.6640919705,
      },
      {
        date: "2019-05-30",
        value: 108.7525782441,
      },
      {
        date: "2019-06-02",
        value: 108.3325882879,
      },
      {
        date: "2019-06-03",
        value: 108.1643543223,
      },
      {
        date: "2019-06-04",
        value: 108.3414764147,
      },
      {
        date: "2019-06-05",
        value: 108.1306586189,
      },
      {
        date: "2019-06-06",
        value: 108.4626984831,
      },
      {
        date: "2019-06-09",
        value: 108.6452526325,
      },
      {
        date: "2019-06-10",
        value: 108.7367491166,
      },
      {
        date: "2019-06-11",
        value: 108.3811710677,
      },
      {
        date: "2019-06-12",
        value: 108.4595624059,
      },
      {
        date: "2019-06-13",
        value: 108.2112738571,
      },
      {
        date: "2019-06-16",
        value: 108.6523055012,
      },
      {
        date: "2019-06-17",
        value: 108.2327701797,
      },
      {
        date: "2019-06-18",
        value: 108.4054608727,
      },
      {
        date: "2019-06-19",
        value: 107.6412841603,
      },
      {
        date: "2019-06-20",
        value: 107.4938140686,
      },
      {
        date: "2019-06-23",
        value: 107.2933122696,
      },
      {
        date: "2019-06-24",
        value: 107.1039690903,
      },
      {
        date: "2019-06-25",
        value: 107.7275127618,
      },
      {
        date: "2019-06-26",
        value: 107.8627968338,
      },
      {
        date: "2019-06-27",
        value: 107.7328646749,
      },
      {
        date: "2019-06-30",
        value: 108.3179134726,
      },
      {
        date: "2019-07-01",
        value: 108.2736041058,
      },
      {
        date: "2019-07-02",
        value: 107.686177278,
      },
      {
        date: "2019-07-03",
        value: 107.7693125443,
      },
      {
        date: "2019-07-04",
        value: 108.1438721137,
      },
      {
        date: "2019-07-07",
        value: 108.6045474811,
      },
      {
        date: "2019-07-08",
        value: 108.817492191,
      },
      {
        date: "2019-07-09",
        value: 108.9126559715,
      },
      {
        date: "2019-07-10",
        value: 108.0549401861,
      },
      {
        date: "2019-07-11",
        value: 108.2644628099,
      },
      {
        date: "2019-07-14",
        value: 107.9243943562,
      },
      {
        date: "2019-07-15",
        value: 107.9301434554,
      },
      {
        date: "2019-07-16",
        value: 108.2657155595,
      },
      {
        date: "2019-07-17",
        value: 107.7835235378,
      },
      {
        date: "2019-07-18",
        value: 107.7231427044,
      },
      {
        date: "2019-07-21",
        value: 107.9179670085,
      },
      {
        date: "2019-07-22",
        value: 108.1356842388,
      },
      {
        date: "2019-07-23",
        value: 108.0879712747,
      },
      {
        date: "2019-07-24",
        value: 108.0971659919,
      },
      {
        date: "2019-07-25",
        value: 108.6909678578,
      },
      {
        date: "2019-07-28",
        value: 108.6518571814,
      },
      {
        date: "2019-07-29",
        value: 108.4812623274,
      },
      {
        date: "2019-07-30",
        value: 108.5463187158,
      },
      {
        date: "2019-07-31",
        value: 108.88828486,
      },
      {
        date: "2019-08-01",
        value: 106.7621105709,
      },
      {
        date: "2019-08-04",
        value: 106.0722589877,
      },
      {
        date: "2019-08-05",
        value: 106.4628586752,
      },
      {
        date: "2019-08-06",
        value: 105.7668273523,
      },
      {
        date: "2019-08-07",
        value: 105.9948181899,
      },
      {
        date: "2019-08-08",
        value: 105.831398464,
      },
      {
        date: "2019-08-11",
        value: 105.1366803645,
      },
      {
        date: "2019-08-12",
        value: 105.1595081091,
      },
      {
        date: "2019-08-13",
        value: 105.8991776904,
      },
      {
        date: "2019-08-14",
        value: 106.1614349776,
      },
      {
        date: "2019-08-15",
        value: 106.3289996389,
      },
      {
        date: "2019-08-18",
        value: 106.5838061785,
      },
      {
        date: "2019-08-19",
        value: 106.3470566992,
      },
      {
        date: "2019-08-20",
        value: 106.4841498559,
      },
      {
        date: "2019-08-21",
        value: 106.5866642606,
      },
      {
        date: "2019-08-22",
        value: 106.452779033,
      },
      {
        date: "2019-08-25",
        value: 105.8564231738,
      },
      {
        date: "2019-08-26",
        value: 105.7546829971,
      },
      {
        date: "2019-08-27",
        value: 105.7294956239,
      },
      {
        date: "2019-08-28",
        value: 106.2861271676,
      },
      {
        date: "2019-08-29",
        value: 106.2703878217,
      },
      {
        date: "2019-09-01",
        value: 106.3639679066,
      },
      {
        date: "2019-09-02",
        value: 106.1077077809,
      },
      {
        date: "2019-09-03",
        value: 106.2170992921,
      },
      {
        date: "2019-09-04",
        value: 106.6829444746,
      },
      {
        date: "2019-09-05",
        value: 107.0191348508,
      },
      {
        date: "2019-09-08",
        value: 106.9609353757,
      },
      {
        date: "2019-09-09",
        value: 107.3550724638,
      },
      {
        date: "2019-09-10",
        value: 107.706989003,
      },
      {
        date: "2019-09-11",
        value: 107.7624737754,
      },
      {
        date: "2019-09-12",
        value: 107.9938716655,
      },
      {
        date: "2019-09-15",
        value: 107.8415374853,
      },
      {
        date: "2019-09-16",
        value: 108.1353165246,
      },
      {
        date: "2019-09-17",
        value: 108.1516330408,
      },
      {
        date: "2019-09-18",
        value: 107.9425318515,
      },
      {
        date: "2019-09-19",
        value: 107.9873073436,
      },
      {
        date: "2019-09-22",
        value: 107.4829312699,
      },
      {
        date: "2019-09-23",
        value: 107.6524584204,
      },
      {
        date: "2019-09-24",
        value: 107.4485521763,
      },
      {
        date: "2019-09-25",
        value: 107.6339367343,
      },
      {
        date: "2019-09-26",
        value: 108.1024234111,
      },
      {
        date: "2019-09-29",
        value: 107.9897143907,
      },
      {
        date: "2019-09-30",
        value: 108.2767480272,
      },
      {
        date: "2019-10-01",
        value: 107.52402746,
      },
      {
        date: "2019-10-02",
        value: 106.9947949959,
      },
      {
        date: "2019-10-03",
        value: 106.7765734584,
      },
      {
        date: "2019-10-06",
        value: 106.8316201219,
      },
      {
        date: "2019-10-07",
        value: 106.8905880211,
      },
      {
        date: "2019-10-08",
        value: 107.3763773791,
      },
      {
        date: "2019-10-09",
        value: 107.4524025385,
      },
      {
        date: "2019-10-10",
        value: 108.4397355791,
      },
      {
        date: "2019-10-13",
        value: 108.2404133805,
      },
      {
        date: "2019-10-14",
        value: 108.3219769238,
      },
      {
        date: "2019-10-15",
        value: 108.7528344671,
      },
      {
        date: "2019-10-16",
        value: 108.7105192117,
      },
      {
        date: "2019-10-17",
        value: 108.5516870065,
      },
      {
        date: "2019-10-20",
        value: 108.5563411796,
      },
      {
        date: "2019-10-21",
        value: 108.5983827493,
      },
      {
        date: "2019-10-22",
        value: 108.4509574755,
      },
      {
        date: "2019-10-23",
        value: 108.60891445,
      },
      {
        date: "2019-10-24",
        value: 108.5711713334,
      },
      {
        date: "2019-10-27",
        value: 108.8572201678,
      },
      {
        date: "2019-10-28",
        value: 108.9499774673,
      },
      {
        date: "2019-10-29",
        value: 108.9411129119,
      },
      {
        date: "2019-10-30",
        value: 108.2391967007,
      },
      {
        date: "2019-10-31",
        value: 108.1156297693,
      },
      {
        date: "2019-11-03",
        value: 108.3796379279,
      },
      {
        date: "2019-11-04",
        value: 108.8576829598,
      },
      {
        date: "2019-11-05",
        value: 108.9990982867,
      },
      {
        date: "2019-11-06",
        value: 109.1450753814,
      },
      {
        date: "2019-11-07",
        value: 109.4072865688,
      },
      {
        date: "2019-11-10",
        value: 108.948464813,
      },
      {
        date: "2019-11-11",
        value: 109.1602360418,
      },
      {
        date: "2019-11-12",
        value: 108.8406323823,
      },
      {
        date: "2019-11-13",
        value: 108.656906429,
      },
      {
        date: "2019-11-14",
        value: 108.7094435382,
      },
      {
        date: "2019-11-17",
        value: 108.9865292469,
      },
      {
        date: "2019-11-18",
        value: 108.7478559177,
      },
      {
        date: "2019-11-19",
        value: 108.4727371372,
      },
      {
        date: "2019-11-20",
        value: 108.6105851591,
      },
      {
        date: "2019-11-21",
        value: 108.545849159,
      },
      {
        date: "2019-11-24",
        value: 108.9026162791,
      },
      {
        date: "2019-11-25",
        value: 108.9745916515,
      },
      {
        date: "2019-11-26",
        value: 109.1652284495,
      },
      {
        date: "2019-11-27",
        value: 109.4956837801,
      },
      {
        date: "2019-11-28",
        value: 109.6612638864,
      },
      {
        date: "2019-12-01",
        value: 109.5436813935,
      },
      {
        date: "2019-12-02",
        value: 108.743564267,
      },
      {
        date: "2019-12-03",
        value: 108.6995758506,
      },
      {
        date: "2019-12-04",
        value: 108.7885343429,
      },
      {
        date: "2019-12-05",
        value: 108.5631873085,
      },
      {
        date: "2019-12-08",
        value: 108.4875846501,
      },
      {
        date: "2019-12-09",
        value: 108.576329331,
      },
      {
        date: "2019-12-10",
        value: 108.7494356659,
      },
      {
        date: "2019-12-11",
        value: 108.6019574392,
      },
      {
        date: "2019-12-12",
        value: 109.5668516198,
      },
      {
        date: "2019-12-15",
        value: 109.42939171,
      },
      {
        date: "2019-12-16",
        value: 109.5771367139,
      },
      {
        date: "2019-12-17",
        value: 109.5906432749,
      },
      {
        date: "2019-12-18",
        value: 109.4989655483,
      },
      {
        date: "2019-12-19",
        value: 109.3178336487,
      },
      {
        date: "2019-12-22",
        value: 109.4176072235,
      },
      {
        date: "2019-12-23",
        value: 109.3772563177,
      },
      {
        date: "2019-12-26",
        value: 109.5938312562,
      },
      {
        date: "2019-12-29",
        value: 109.2054696577,
      },
      {
        date: "2019-12-30",
        value: 108.5454869147,
      },
      {
        date: "2020-01-01",
        value: 108.7733404807,
      },
      {
        date: "2020-01-02",
        value: 108.1367183996,
      },
      {
        date: "2020-01-05",
        value: 108.1114882973,
      },
      {
        date: "2020-01-06",
        value: 108.4407447189,
      },
      {
        date: "2020-01-07",
        value: 108.7359424202,
      },
      {
        date: "2020-01-08",
        value: 109.396939694,
      },
      {
        date: "2020-01-09",
        value: 109.6384455865,
      },
      {
        date: "2020-01-12",
        value: 109.8777637965,
      },
      {
        date: "2020-01-13",
        value: 110.0494826811,
      },
      {
        date: "2020-01-14",
        value: 109.8815293484,
      },
      {
        date: "2020-01-15",
        value: 109.9471752171,
      },
      {
        date: "2020-01-16",
        value: 110.1098307526,
      },
      {
        date: "2020-01-19",
        value: 110.1849345963,
      },
      {
        date: "2020-01-20",
        value: 110.04048583,
      },
      {
        date: "2020-01-21",
        value: 109.9657287157,
      },
      {
        date: "2020-01-22",
        value: 109.5482823911,
      },
      {
        date: "2020-01-23",
        value: 109.6148618034,
      },
      {
        date: "2020-01-26",
        value: 108.9433106576,
      },
      {
        date: "2020-01-27",
        value: 109.0595184007,
      },
      {
        date: "2020-01-28",
        value: 109.1355331334,
      },
      {
        date: "2020-01-29",
        value: 108.8312630338,
      },
      {
        date: "2020-01-30",
        value: 108.8943177705,
      },
      {
        date: "2020-02-02",
        value: 108.5306343756,
      },
      {
        date: "2020-02-03",
        value: 109.0876176684,
      },
      {
        date: "2020-02-04",
        value: 109.7160482627,
      },
      {
        date: "2020-02-05",
        value: 109.851858584,
      },
      {
        date: "2020-02-06",
        value: 109.8641626402,
      },
      {
        date: "2020-02-09",
        value: 109.743402429,
      },
      {
        date: "2020-02-10",
        value: 109.8339601871,
      },
      {
        date: "2020-02-11",
        value: 109.9780098955,
      },
      {
        date: "2020-02-12",
        value: 109.699088985,
      },
      {
        date: "2020-02-13",
        value: 109.8598044641,
      },
      {
        date: "2020-02-16",
        value: 109.875403784,
      },
      {
        date: "2020-02-17",
        value: 109.7448224852,
      },
      {
        date: "2020-02-18",
        value: 110.5092592593,
      },
      {
        date: "2020-02-19",
        value: 112.0111214087,
      },
      {
        date: "2020-02-20",
        value: 111.9896305898,
      },
      {
        date: "2020-02-23",
        value: 111.4069144019,
      },
      {
        date: "2020-02-24",
        value: 110.6273062731,
      },
      {
        date: "2020-02-25",
        value: 110.4643678161,
      },
      {
        date: "2020-02-26",
        value: 109.8686610726,
      },
      {
        date: "2020-02-27",
        value: 108.7364489387,
      },
      {
        date: "2020-03-01",
        value: 107.7324222262,
      },
      {
        date: "2020-03-02",
        value: 107.735899973,
      },
      {
        date: "2020-03-03",
        value: 107.4876404494,
      },
      {
        date: "2020-03-04",
        value: 106.9366228658,
      },
      {
        date: "2020-03-05",
        value: 105.0458715596,
      },
      {
        date: "2020-03-08",
        value: 102.2346368715,
      },
      {
        date: "2020-03-09",
        value: 104.1703248464,
      },
      {
        date: "2020-03-10",
        value: 104.5783345095,
      },
      {
        date: "2020-03-11",
        value: 103.9501779359,
      },
      {
        date: "2020-03-12",
        value: 107.2676512968,
      },
      {
        date: "2020-03-15",
        value: 105.5480864032,
      },
      {
        date: "2020-03-16",
        value: 106.993261701,
      },
      {
        date: "2020-03-17",
        value: 107.7190415219,
      },
      {
        date: "2020-03-18",
        value: 109.8324229238,
      },
      {
        date: "2020-03-19",
        value: 110.5538432801,
      },
      {
        date: "2020-03-22",
        value: 110.4609106928,
      },
      {
        date: "2020-03-23",
        value: 110.6612561099,
      },
      {
        date: "2020-03-24",
        value: 111.4528493581,
      },
      {
        date: "2020-03-25",
        value: 109.443584373,
      },
      {
        date: "2020-03-26",
        value: 108.7364489387,
      },
      {
        date: "2020-03-29",
        value: 108.1566068515,
      },
      {
        date: "2020-03-30",
        value: 108.5250091274,
      },
      {
        date: "2020-03-31",
        value: 107.4890270666,
      },
      {
        date: "2020-04-01",
        value: 107.3354117,
      },
      {
        date: "2020-04-02",
        value: 108.5767269356,
      },
      {
        date: "2020-04-05",
        value: 108.9241034195,
      },
      {
        date: "2020-04-06",
        value: 108.9113458888,
      },
      {
        date: "2020-04-07",
        value: 108.8768282587,
      },
      {
        date: "2020-04-08",
        value: 108.8892978743,
      },
      {
        date: "2020-04-13",
        value: 107.3246374168,
      },
      {
        date: "2020-04-14",
        value: 107.4199761534,
      },
      {
        date: "2020-04-15",
        value: 107.5679647318,
      },
      {
        date: "2020-04-16",
        value: 107.605893186,
      },
      {
        date: "2020-04-19",
        value: 107.8360957643,
      },
      {
        date: "2020-04-20",
        value: 107.4005721141,
      },
      {
        date: "2020-04-21",
        value: 107.6746112082,
      },
      {
        date: "2020-04-22",
        value: 107.454511697,
      },
      {
        date: "2020-04-23",
        value: 107.6111111111,
      },
      {
        date: "2020-04-26",
        value: 107.0954662735,
      },
      {
        date: "2020-04-27",
        value: 106.7022156845,
      },
      {
        date: "2020-04-28",
        value: 106.548607268,
      },
      {
        date: "2020-04-29",
        value: 106.5373299007,
      },
      {
        date: "2020-05-03",
        value: 106.7812100165,
      },
      {
        date: "2020-05-04",
        value: 106.7140090381,
      },
      {
        date: "2020-05-05",
        value: 106.0886462478,
      },
      {
        date: "2020-05-06",
        value: 106.565890754,
      },
      {
        date: "2020-05-07",
        value: 106.3727750623,
      },
      {
        date: "2020-05-10",
        value: 107.3170731707,
      },
      {
        date: "2020-05-11",
        value: 107.3494197826,
      },
      {
        date: "2020-05-12",
        value: 106.924137931,
      },
      {
        date: "2020-05-13",
        value: 107.0051890289,
      },
      {
        date: "2020-05-14",
        value: 106.9920355621,
      },
      {
        date: "2020-05-17",
        value: 107.3762924668,
      },
      {
        date: "2020-05-18",
        value: 107.7625570776,
      },
      {
        date: "2020-05-19",
        value: 107.6382551561,
      },
      {
        date: "2020-05-20",
        value: 107.6545454545,
      },
      {
        date: "2020-05-21",
        value: 107.5385179751,
      },
      {
        date: "2020-05-24",
        value: 107.6718606783,
      },
      {
        date: "2020-05-25",
        value: 107.444191344,
      },
      {
        date: "2020-05-26",
        value: 107.8882722227,
      },
      {
        date: "2020-05-27",
        value: 107.734204793,
      },
      {
        date: "2020-05-28",
        value: 107.1210488506,
      },
      {
        date: "2020-05-31",
        value: 107.7275998561,
      },
      {
        date: "2020-06-01",
        value: 108.1349561482,
      },
      {
        date: "2020-06-02",
        value: 108.5760228694,
      },
      {
        date: "2020-06-03",
        value: 108.8711111111,
      },
      {
        date: "2020-06-04",
        value: 109.2409532215,
      },
      {
        date: "2020-06-07",
        value: 109.3575542756,
      },
      {
        date: "2020-06-08",
        value: 108.1459181866,
      },
      {
        date: "2020-06-09",
        value: 107.3934065934,
      },
      {
        date: "2020-06-10",
        value: 107.0849488897,
      },
      {
        date: "2020-06-11",
        value: 107.2717622081,
      },
      {
        date: "2020-06-14",
        value: 107.3758108949,
      },
      {
        date: "2020-06-15",
        value: 107.348779625,
      },
      {
        date: "2020-06-16",
        value: 107.4163105413,
      },
      {
        date: "2020-06-17",
        value: 106.9328105507,
      },
      {
        date: "2020-06-18",
        value: 106.8421052632,
      },
      {
        date: "2020-06-21",
        value: 106.9205386605,
      },
      {
        date: "2020-06-22",
        value: 106.9535253578,
      },
      {
        date: "2020-06-23",
        value: 106.6489361702,
      },
      {
        date: "2020-06-24",
        value: 107.3660714286,
      },
      {
        date: "2020-06-25",
        value: 106.9562115402,
      },
      {
        date: "2020-06-28",
        value: 107.2935129387,
      },
      {
        date: "2020-06-29",
        value: 107.7513841757,
      },
      {
        date: "2020-06-30",
        value: 107.4196428571,
      },
      {
        date: "2020-07-01",
        value: 107.4251284778,
      },
      {
        date: "2020-07-02",
        value: 107.5196008553,
      },
      {
        date: "2020-07-05",
        value: 107.53200883,
      },
      {
        date: "2020-07-06",
        value: 107.7147918512,
      },
      {
        date: "2020-07-07",
        value: 107.5580365054,
      },
      {
        date: "2020-07-08",
        value: 107.2738494093,
      },
      {
        date: "2020-07-09",
        value: 106.8463994324,
      },
      {
        date: "2020-07-12",
        value: 107.1586194722,
      },
      {
        date: "2020-07-13",
        value: 107.4021978022,
      },
      {
        date: "2020-07-14",
        value: 106.8245368752,
      },
      {
        date: "2020-07-15",
        value: 107.0965480988,
      },
      {
        date: "2020-07-16",
        value: 107.2191109555,
      },
      {
        date: "2020-07-19",
        value: 107.1191474493,
      },
      {
        date: "2020-07-20",
        value: 107.2271257537,
      },
      {
        date: "2020-07-21",
        value: 107.0478493695,
      },
      {
        date: "2020-07-22",
        value: 107.1657014435,
      },
      {
        date: "2020-07-23",
        value: 106.2715368711,
      },
      {
        date: "2020-07-26",
        value: 105.3401360544,
      },
      {
        date: "2020-07-27",
        value: 105.2658530341,
      },
      {
        date: "2020-07-28",
        value: 105.1428571429,
      },
      {
        date: "2020-07-29",
        value: 105.2371625649,
      },
      {
        date: "2020-07-30",
        value: 104.9206617151,
      },
      {
        date: "2020-08-02",
        value: 106.1828415487,
      },
      {
        date: "2020-08-03",
        value: 106.0603484913,
      },
      {
        date: "2020-08-04",
        value: 105.7617681795,
      },
      {
        date: "2020-08-05",
        value: 105.5138056236,
      },
      {
        date: "2020-08-06",
        value: 105.7036472878,
      },
      {
        date: "2020-08-09",
        value: 106.0443764346,
      },
      {
        date: "2020-08-10",
        value: 106.0595773572,
      },
      {
        date: "2020-08-11",
        value: 106.9747684989,
      },
      {
        date: "2020-08-12",
        value: 106.9720273811,
      },
      {
        date: "2020-08-13",
        value: 106.6706171167,
      },
      {
        date: "2020-08-16",
        value: 106.2937652915,
      },
      {
        date: "2020-08-17",
        value: 105.4426339661,
      },
      {
        date: "2020-08-18",
        value: 105.3716584262,
      },
      {
        date: "2020-08-19",
        value: 105.8734177215,
      },
      {
        date: "2020-08-20",
        value: 105.8118786643,
      },
      {
        date: "2020-08-23",
        value: 105.7314087955,
      },
      {
        date: "2020-08-24",
        value: 106.373793804,
      },
      {
        date: "2020-08-25",
        value: 106.3194503351,
      },
      {
        date: "2020-08-26",
        value: 106.1663560901,
      },
      {
        date: "2020-08-27",
        value: 105.2370960974,
      },
      {
        date: "2020-08-30",
        value: 105.9212730318,
      },
      {
        date: "2020-08-31",
        value: 105.8813714858,
      },
      {
        date: "2020-09-01",
        value: 106.2305033302,
      },
      {
        date: "2020-09-02",
        value: 106.5351731144,
      },
      {
        date: "2020-09-03",
        value: 106.2236108765,
      },
      {
        date: "2020-09-06",
        value: 106.2161705007,
      },
      {
        date: "2020-09-07",
        value: 106.2367416207,
      },
      {
        date: "2020-09-08",
        value: 106.2176165803,
      },
      {
        date: "2020-09-09",
        value: 106.135538864,
      },
      {
        date: "2020-09-10",
        value: 106.1413868736,
      },
      {
        date: "2020-09-13",
        value: 105.9447625463,
      },
      {
        date: "2020-09-14",
        value: 105.4406323579,
      },
      {
        date: "2020-09-15",
        value: 105.080461707,
      },
      {
        date: "2020-09-16",
        value: 104.5859116725,
      },
      {
        date: "2020-09-17",
        value: 104.3606862165,
      },
      {
        date: "2020-09-20",
        value: 104.0977347926,
      },
      {
        date: "2020-09-21",
        value: 104.5826235094,
      },
      {
        date: "2020-09-22",
        value: 105.1231611358,
      },
      {
        date: "2020-09-23",
        value: 105.3928724775,
      },
      {
        date: "2020-09-24",
        value: 105.5011174145,
      },
      {
        date: "2020-09-27",
        value: 105.4155955441,
      },
      {
        date: "2020-09-28",
        value: 105.6315159802,
      },
      {
        date: "2020-09-29",
        value: 105.7055005125,
      },
      {
        date: "2020-09-30",
        value: 105.4969366916,
      },
      {
        date: "2020-10-01",
        value: 105.200341006,
      },
      {
        date: "2020-10-04",
        value: 105.5829367777,
      },
      {
        date: "2020-10-05",
        value: 105.6210258584,
      },
      {
        date: "2020-10-06",
        value: 105.9728122345,
      },
      {
        date: "2020-10-07",
        value: 105.9838504037,
      },
      {
        date: "2020-10-08",
        value: 105.9347181009,
      },
      {
        date: "2020-10-11",
        value: 105.4411390796,
      },
      {
        date: "2020-10-12",
        value: 105.5230338509,
      },
      {
        date: "2020-10-13",
        value: 105.2765957447,
      },
      {
        date: "2020-10-14",
        value: 105.2744058813,
      },
      {
        date: "2020-10-15",
        value: 105.2636061664,
      },
      {
        date: "2020-10-18",
        value: 105.311837081,
      },
      {
        date: "2020-10-19",
        value: 105.6646909399,
      },
      {
        date: "2020-10-20",
        value: 104.8515018562,
      },
      {
        date: "2020-10-21",
        value: 104.6611961763,
      },
      {
        date: "2020-10-22",
        value: 104.7317813765,
      },
      {
        date: "2020-10-25",
        value: 105.0342668584,
      },
      {
        date: "2020-10-26",
        value: 104.5807978364,
      },
      {
        date: "2020-10-27",
        value: 104.3404110173,
      },
      {
        date: "2020-10-28",
        value: 104.3147641832,
      },
      {
        date: "2020-10-29",
        value: 104.5990767653,
      },
      {
        date: "2020-11-01",
        value: 104.642979746,
      },
      {
        date: "2020-11-02",
        value: 104.7342334644,
      },
      {
        date: "2020-11-03",
        value: 104.3511645764,
      },
      {
        date: "2020-11-04",
        value: 103.6355967946,
      },
      {
        date: "2020-11-05",
        value: 103.3361415333,
      },
      {
        date: "2020-11-08",
        value: 104.9145838593,
      },
      {
        date: "2020-11-09",
        value: 105.3184281843,
      },
      {
        date: "2020-11-10",
        value: 105.4479007309,
      },
      {
        date: "2020-11-11",
        value: 105.3769824442,
      },
      {
        date: "2020-11-12",
        value: 104.849767245,
      },
      {
        date: "2020-11-15",
        value: 104.9450549451,
      },
      {
        date: "2020-11-16",
        value: 104.1743814173,
      },
      {
        date: "2020-11-17",
        value: 103.8759689922,
      },
      {
        date: "2020-11-18",
        value: 104.0229885057,
      },
      {
        date: "2020-11-19",
        value: 103.8354547754,
      },
      {
        date: "2020-11-22",
        value: 103.7475842366,
      },
      {
        date: "2020-11-23",
        value: 104.5596291614,
      },
      {
        date: "2020-11-24",
        value: 104.449116905,
      },
      {
        date: "2020-11-25",
        value: 104.2352941176,
      },
      {
        date: "2020-11-26",
        value: 104.2023150478,
      },
      {
        date: "2020-11-29",
        value: 104.1652754591,
      },
      {
        date: "2020-11-30",
        value: 104.378342246,
      },
      {
        date: "2020-12-01",
        value: 104.5333996353,
      },
      {
        date: "2020-12-02",
        value: 104.0984281129,
      },
      {
        date: "2020-12-03",
        value: 103.9888148696,
      },
      {
        date: "2020-12-06",
        value: 104.139182058,
      },
      {
        date: "2020-12-07",
        value: 104.1604754829,
      },
      {
        date: "2020-12-08",
        value: 104.0961268478,
      },
      {
        date: "2020-12-09",
        value: 104.4407758976,
      },
      {
        date: "2020-12-10",
        value: 104.1312773151,
      },
      {
        date: "2020-12-13",
        value: 103.6918270021,
      },
      {
        date: "2020-12-14",
        value: 103.8550247117,
      },
      {
        date: "2020-12-15",
        value: 103.3472803347,
      },
      {
        date: "2020-12-16",
        value: 103.0458925363,
      },
      {
        date: "2020-12-17",
        value: 103.3444816054,
      },
      {
        date: "2020-12-20",
        value: 103.5406226896,
      },
      {
        date: "2020-12-21",
        value: 103.5406226896,
      },
      {
        date: "2020-12-22",
        value: 103.3744586976,
      },
      {
        date: "2020-12-23",
        value: 103.5590991287,
      },
      {
        date: "2020-12-24",
        value: 103.6496350365,
      },
      {
        date: "2020-12-28",
        value: 103.5600294623,
      },
      {
        date: "2020-12-29",
        value: 103.7849743046,
      },
      {
        date: "2020-12-30",
        value: 103.0616399316,
      },
      {
        date: "2020-12-31",
        value: 103.0804335425,
      },
      {
        date: "2021-01-04",
        value: 102.9765777489,
      },
      {
        date: "2021-01-05",
        value: 102.8848504604,
      },
      {
        date: "2021-01-06",
        value: 102.9583400875,
      },
      {
        date: "2021-01-07",
        value: 103.559791463,
      },
      {
        date: "2021-01-08",
        value: 103.8857142857,
      },
      {
        date: "2021-01-11",
        value: 104.2177094467,
      },
      {
        date: "2021-01-12",
        value: 104.2184030919,
      },
      {
        date: "2021-01-13",
        value: 103.92898241,
      },
      {
        date: "2021-01-14",
        value: 104.0993071594,
      },
      {
        date: "2021-01-15",
        value: 103.7202012703,
      },
      {
        date: "2021-01-18",
        value: 103.7632625995,
      },
      {
        date: "2021-01-19",
        value: 103.98944939,
      },
      {
        date: "2021-01-20",
        value: 103.8096025122,
      },
      {
        date: "2021-01-21",
        value: 103.4051653232,
      },
      {
        date: "2021-01-22",
        value: 103.7917420628,
      },
      {
        date: "2021-01-25",
        value: 103.7360105332,
      },
      {
        date: "2021-01-26",
        value: 103.7058387548,
      },
      {
        date: "2021-01-27",
        value: 103.9045732211,
      },
      {
        date: "2021-01-28",
        value: 104.375155074,
      },
      {
        date: "2021-01-29",
        value: 104.6885299934,
      },
      {
        date: "2021-02-01",
        value: 104.9073154585,
      },
      {
        date: "2021-02-02",
        value: 104.9983394221,
      },
      {
        date: "2021-02-03",
        value: 105.059499043,
      },
      {
        date: "2021-02-04",
        value: 105.2350783595,
      },
      {
        date: "2021-02-05",
        value: 105.749812234,
      },
      {
        date: "2021-02-08",
        value: 105.5883575884,
      },
      {
        date: "2021-02-09",
        value: 104.5769993391,
      },
      {
        date: "2021-02-10",
        value: 104.7579780655,
      },
      {
        date: "2021-02-11",
        value: 104.6513542438,
      },
      {
        date: "2021-02-12",
        value: 105.0132144037,
      },
      {
        date: "2021-02-15",
        value: 105.3013438866,
      },
      {
        date: "2021-02-16",
        value: 105.5422877378,
      },
      {
        date: "2021-02-17",
        value: 106.0862354892,
      },
      {
        date: "2021-02-18",
        value: 105.668652764,
      },
      {
        date: "2021-02-19",
        value: 105.2887387758,
      },
      {
        date: "2021-02-22",
        value: 105.4974037748,
      },
      {
        date: "2021-02-23",
        value: 105.3940541876,
      },
      {
        date: "2021-02-24",
        value: 105.9525769801,
      },
      {
        date: "2021-02-25",
        value: 106.118609407,
      },
      {
        date: "2021-02-26",
        value: 106.2866100157,
      },
      {
        date: "2021-03-01",
        value: 106.7203185929,
      },
      {
        date: "2021-03-02",
        value: 106.9005653475,
      },
      {
        date: "2021-03-03",
        value: 106.9140106242,
      },
      {
        date: "2021-03-04",
        value: 107.38740236,
      },
      {
        date: "2021-03-05",
        value: 108.3095995979,
      },
      {
        date: "2021-03-08",
        value: 108.6296982977,
      },
      {
        date: "2021-03-09",
        value: 108.8784260972,
      },
      {
        date: "2021-03-10",
        value: 108.5771947528,
      },
      {
        date: "2021-03-11",
        value: 108.4635307879,
      },
      {
        date: "2021-03-12",
        value: 109.0589122601,
      },
      {
        date: "2021-03-15",
        value: 109.2030201342,
      },
      {
        date: "2021-03-16",
        value: 108.9049136341,
      },
      {
        date: "2021-03-17",
        value: 109.196271101,
      },
      {
        date: "2021-03-18",
        value: 109.20080591,
      },
      {
        date: "2021-03-19",
        value: 108.9395341014,
      },
      {
        date: "2021-03-22",
        value: 108.8126781821,
      },
      {
        date: "2021-03-23",
        value: 108.5500294538,
      },
      {
        date: "2021-03-24",
        value: 108.7526427061,
      },
      {
        date: "2021-03-25",
        value: 109.0916793764,
      },
      {
        date: "2021-03-26",
        value: 109.7776268885,
      },
      {
        date: "2021-03-29",
        value: 109.6317040054,
      },
      {
        date: "2021-03-30",
        value: 110.2802146325,
      },
      {
        date: "2021-03-31",
        value: 110.7974413646,
      },
      {
        date: "2021-04-30",
        value: 108.93891739778184,
      },
      {
        date: "2021-05-03",
        value: 109.51511125871805,
      },
      {
        date: "2021-05-04",
        value: 109.19224690125613,
      },
      {
        date: "2021-05-05",
        value: 109.2877967513536,
      },
      {
        date: "2021-05-06",
        value: 109.19568822553897,
      },
      {
        date: "2021-05-07",
        value: 109.26279127622522,
      },
      {
        date: "2021-05-10",
        value: 108.72709343413591,
      },
      {
        date: "2021-05-11",
        value: 108.5620377978636,
      },
      {
        date: "2021-05-12",
        value: 108.78032678659845,
      },
      {
        date: "2021-05-13",
        value: 109.56874430924593,
      },
      {
        date: "2021-05-14",
        value: 109.28813000082489,
      },
      {
        date: "2021-05-17",
        value: 109.19871530923166,
      },
      {
        date: "2021-05-18",
        value: 108.8937980690558,
      },
      {
        date: "2021-05-19",
        value: 109.1549295774648,
      },
      {
        date: "2021-05-20",
        value: 108.94861919200196,
      },
      {
        date: "2021-05-21",
        value: 108.80374138496883,
      },
      {
        date: "2021-05-24",
        value: 108.88470357025875,
      },
      {
        date: "2021-05-25",
        value: 108.84703196347033,
      },
      {
        date: "2021-05-26",
        value: 108.88870717147762,
      },
      {
        date: "2021-05-27",
        value: 109.37858665354975,
      },
      {
        date: "2021-05-28",
        value: 110.07247570416736,
      },
      {
        date: "2021-05-31",
        value: 109.65494631587575,
      },
      {
        date: "2021-06-01",
        value: 109.65235173824132,
      },
      {
        date: "2021-06-02",
        value: 109.73247989496144,
      },
      {
        date: "2021-06-03",
        value: 109.7973250184623,
      },
      {
        date: "2021-06-04",
        value: 110.08500453907732,
      },
      {
        date: "2021-06-07",
        value: 109.34056898536424,
      },
      {
        date: "2021-06-08",
        value: 109.40732227877196,
      },
      {
        date: "2021-06-09",
        value: 109.37269372693726,
      },
      {
        date: "2021-06-10",
        value: 109.53671759487432,
      },
      {
        date: "2021-06-11",
        value: 109.5917525773196,
      },
      {
        date: "2021-06-14",
        value: 109.76717305151914,
      },
      {
        date: "2021-06-15",
        value: 110.07598282127518,
      },
      {
        date: "2021-06-16",
        value: 109.85648300890796,
      },
      {
        date: "2021-06-17",
        value: 110.65594370444836,
      },
      {
        date: "2021-06-18",
        value: 110.20339552866028,
      },
      {
        date: "2021-06-21",
        value: 110.09166596585652,
      },
      {
        date: "2021-06-22",
        value: 110.55994619135699,
      },
      {
        date: "2021-06-23",
        value: 110.81917831143836,
      },
      {
        date: "2021-06-24",
        value: 110.75737265415549,
      },
      {
        date: "2021-06-25",
        value: 110.68619246861925,
      },
      {
        date: "2021-06-28",
        value: 110.94038623005876,
      },
      {
        date: "2021-06-29",
        value: 110.6493943472409,
      },
      {
        date: "2021-06-30",
        value: 110.59407606866377,
      },
      {
        date: "2021-07-01",
        value: 111.42712891282396,
      },
    ],
  },
  {
    name: "South Korea",
    flag: "🇰🇷",
    ppp: 0.88,
    currency: "KRW",
    currencySymbol: "₩",
    placeholderValue: 147400,
    vat: 0.1,
    min: 1000,
    max: 1300,
    fxRates: [
      {
        date: "2019-01-02",
        value: 1121.803983504431,
      },
      {
        date: "2019-01-03",
        value: 1127.220655622136,
      },
      {
        date: "2019-01-04",
        value: 1121.7486626326406,
      },
      {
        date: "2019-01-07",
        value: 1119.397116644823,
      },
      {
        date: "2019-01-08",
        value: 1126.4160839160838,
      },
      {
        date: "2019-01-09",
        value: 1121.0475774770844,
      },
      {
        date: "2019-01-10",
        value: 1117.5032509752925,
      },
      {
        date: "2019-01-11",
        value: 1117.8010925171247,
      },
      {
        date: "2019-01-14",
        value: 1123.066189936339,
      },
      {
        date: "2019-01-15",
        value: 1123.3018207282912,
      },
      {
        date: "2019-01-16",
        value: 1122.451488278163,
      },
      {
        date: "2019-01-17",
        value: 1123.7978237978239,
      },
      {
        date: "2019-01-18",
        value: 1121.4962287318015,
      },
      {
        date: "2019-01-21",
        value: 1131.297306812181,
      },
      {
        date: "2019-01-22",
        value: 1131.5571604720803,
      },
      {
        date: "2019-01-23",
        value: 1127.826163455617,
      },
      {
        date: "2019-01-24",
        value: 1129.6887399700202,
      },
      {
        date: "2019-01-25",
        value: 1120.1040014101884,
      },
      {
        date: "2019-01-28",
        value: 1118.6022070415136,
      },
      {
        date: "2019-01-29",
        value: 1118.2980213622832,
      },
      {
        date: "2019-01-30",
        value: 1118.1905678537055,
      },
      {
        date: "2019-01-31",
        value: 1112.099582172702,
      },
      {
        date: "2019-02-01",
        value: 1117.0168250370498,
      },
      {
        date: "2019-02-04",
        value: 1120.6028833551768,
      },
      {
        date: "2019-02-05",
        value: 1117.701129300534,
      },
      {
        date: "2019-02-06",
        value: 1120.8443040196594,
      },
      {
        date: "2019-02-07",
        value: 1124.997796386073,
      },
      {
        date: "2019-02-08",
        value: 1122.8274281685176,
      },
      {
        date: "2019-02-11",
        value: 1125.2984348748782,
      },
      {
        date: "2019-02-12",
        value: 1123.6101274787536,
      },
      {
        date: "2019-02-13",
        value: 1123.3967271118972,
      },
      {
        date: "2019-02-14",
        value: 1127.4316648917288,
      },
      {
        date: "2019-02-15",
        value: 1127.2824156305508,
      },
      {
        date: "2019-02-18",
        value: 1125.8033192090395,
      },
      {
        date: "2019-02-19",
        value: 1127.5898707278202,
      },
      {
        date: "2019-02-20",
        value: 1122.791394815729,
      },
      {
        date: "2019-02-21",
        value: 1125.2510128589042,
      },
      {
        date: "2019-02-22",
        value: 1124.2384105960264,
      },
      {
        date: "2019-02-25",
        value: 1117.7366798767064,
      },
      {
        date: "2019-02-26",
        value: 1117.8593433676613,
      },
      {
        date: "2019-02-27",
        value: 1118.4524855085192,
      },
      {
        date: "2019-02-28",
        value: 1122.1706377014716,
      },
      {
        date: "2019-03-01",
        value: 1126.3463058947552,
      },
      {
        date: "2019-03-04",
        value: 1126.1180206403812,
      },
      {
        date: "2019-03-05",
        value: 1126.8514431988701,
      },
      {
        date: "2019-03-06",
        value: 1127.863777089783,
      },
      {
        date: "2019-03-07",
        value: 1129.5803389228995,
      },
      {
        date: "2019-03-08",
        value: 1136.6957761539832,
      },
      {
        date: "2019-03-11",
        value: 1134.187122020633,
      },
      {
        date: "2019-03-12",
        value: 1129.8270509977829,
      },
      {
        date: "2019-03-13",
        value: 1131.6641599575332,
      },
      {
        date: "2019-03-14",
        value: 1136.4497565294378,
      },
      {
        date: "2019-03-15",
        value: 1136.0541209763,
      },
      {
        date: "2019-03-18",
        value: 1131.958762886598,
      },
      {
        date: "2019-03-19",
        value: 1129.7323472442333,
      },
      {
        date: "2019-03-20",
        value: 1129.0822617579709,
      },
      {
        date: "2019-03-21",
        value: 1128.7959954333887,
      },
      {
        date: "2019-03-22",
        value: 1135.0291983719694,
      },
      {
        date: "2019-03-25",
        value: 1132.953642384106,
      },
      {
        date: "2019-03-26",
        value: 1134.4522185811709,
      },
      {
        date: "2019-03-27",
        value: 1137.554391261877,
      },
      {
        date: "2019-03-28",
        value: 1139.160278124443,
      },
      {
        date: "2019-03-29",
        value: 1136.1459724076547,
      },
      {
        date: "2019-04-01",
        value: 1134.9590601637594,
      },
      {
        date: "2019-04-02",
        value: 1136.3839285714284,
      },
      {
        date: "2019-04-03",
        value: 1134.350262385484,
      },
      {
        date: "2019-04-04",
        value: 1137.4186647651306,
      },
      {
        date: "2019-04-05",
        value: 1137.3987358675333,
      },
      {
        date: "2019-04-08",
        value: 1145.3939178374533,
      },
      {
        date: "2019-04-09",
        value: 1139.4519819100826,
      },
      {
        date: "2019-04-10",
        value: 1137.5919851050626,
      },
      {
        date: "2019-04-11",
        value: 1140.9534801136363,
      },
      {
        date: "2019-04-12",
        value: 1133.2921120042397,
      },
      {
        date: "2019-04-15",
        value: 1132.65270043313,
      },
      {
        date: "2019-04-16",
        value: 1135.046439628483,
      },
      {
        date: "2019-04-17",
        value: 1133.2182992655517,
      },
      {
        date: "2019-04-18",
        value: 1136.8444444444444,
      },
      {
        date: "2019-04-23",
        value: 1142.0720320142284,
      },
      {
        date: "2019-04-24",
        value: 1151.0661075921134,
      },
      {
        date: "2019-04-25",
        value: 1163.1484311786387,
      },
      {
        date: "2019-04-26",
        value: 1160.926973861493,
      },
      {
        date: "2019-04-29",
        value: 1160.5381165919282,
      },
      {
        date: "2019-04-30",
        value: 1165.9921554644322,
      },
      {
        date: "2019-05-02",
        value: 1163.1733856582234,
      },
      {
        date: "2019-05-03",
        value: 1169.950694755715,
      },
      {
        date: "2019-05-06",
        value: 1170.8009643718192,
      },
      {
        date: "2019-05-07",
        value: 1171.059454626732,
      },
      {
        date: "2019-05-08",
        value: 1172.5495447241562,
      },
      {
        date: "2019-05-09",
        value: 1183.0429732868758,
      },
      {
        date: "2019-05-10",
        value: 1180.160284951024,
      },
      {
        date: "2019-05-13",
        value: 1187.9502000889283,
      },
      {
        date: "2019-05-14",
        value: 1187.6002137894175,
      },
      {
        date: "2019-05-15",
        value: 1190.4766162925869,
      },
      {
        date: "2019-05-16",
        value: 1189.1189859858966,
      },
      {
        date: "2019-05-17",
        value: 1194.3967060508414,
      },
      {
        date: "2019-05-20",
        value: 1193.803170054625,
      },
      {
        date: "2019-05-21",
        value: 1193.3339306513753,
      },
      {
        date: "2019-05-22",
        value: 1189.956136424671,
      },
      {
        date: "2019-05-23",
        value: 1191.6778884998655,
      },
      {
        date: "2019-05-24",
        value: 1187.3513900062571,
      },
      {
        date: "2019-05-27",
        value: 1184.8990891230578,
      },
      {
        date: "2019-05-28",
        value: 1188.2505360972125,
      },
      {
        date: "2019-05-29",
        value: 1195.1416278235927,
      },
      {
        date: "2019-05-30",
        value: 1190.919705406862,
      },
      {
        date: "2019-05-31",
        value: 1191.2025827280065,
      },
      {
        date: "2019-06-03",
        value: 1183.236477425123,
      },
      {
        date: "2019-06-04",
        value: 1183.6535040910708,
      },
      {
        date: "2019-06-05",
        value: 1176.4502087589944,
      },
      {
        date: "2019-06-06",
        value: 1179.566838274454,
      },
      {
        date: "2019-06-07",
        value: 1185.3277743280405,
      },
      {
        date: "2019-06-10",
        value: 1185.4880099106274,
      },
      {
        date: "2019-06-11",
        value: 1181.5194346289754,
      },
      {
        date: "2019-06-12",
        value: 1181.4978362624745,
      },
      {
        date: "2019-06-13",
        value: 1183.2226060767118,
      },
      {
        date: "2019-06-14",
        value: 1183.843763870395,
      },
      {
        date: "2019-06-17",
        value: 1186.7990030265266,
      },
      {
        date: "2019-06-18",
        value: 1184.911057477429,
      },
      {
        date: "2019-06-19",
        value: 1176.6039082716159,
      },
      {
        date: "2019-06-20",
        value: 1160.56425223313,
      },
      {
        date: "2019-06-21",
        value: 1161.55001767409,
      },
      {
        date: "2019-06-24",
        value: 1156.5473055994382,
      },
      {
        date: "2019-06-25",
        value: 1154.0832455216016,
      },
      {
        date: "2019-06-26",
        value: 1155.4831895792993,
      },
      {
        date: "2019-06-27",
        value: 1154.8988566402813,
      },
      {
        date: "2019-06-28",
        value: 1155.8435852372584,
      },
      {
        date: "2019-07-01",
        value: 1161.5737069345316,
      },
      {
        date: "2019-07-02",
        value: 1166.5339350499955,
      },
      {
        date: "2019-07-03",
        value: 1169.7865934649783,
      },
      {
        date: "2019-07-04",
        value: 1168.9493267186392,
      },
      {
        date: "2019-07-05",
        value: 1170.8969804618118,
      },
      {
        date: "2019-07-08",
        value: 1178.8408381631743,
      },
      {
        date: "2019-07-09",
        value: 1182.5524319500223,
      },
      {
        date: "2019-07-10",
        value: 1181.1140819964348,
      },
      {
        date: "2019-07-11",
        value: 1173.265396544085,
      },
      {
        date: "2019-07-12",
        value: 1178.9656091708878,
      },
      {
        date: "2019-07-15",
        value: 1179.3415564823854,
      },
      {
        date: "2019-07-16",
        value: 1178.7846386884078,
      },
      {
        date: "2019-07-17",
        value: 1181.16807846634,
      },
      {
        date: "2019-07-18",
        value: 1178.049215406562,
      },
      {
        date: "2019-07-19",
        value: 1175.0579013005522,
      },
      {
        date: "2019-07-22",
        value: 1177.2982612572448,
      },
      {
        date: "2019-07-23",
        value: 1178.7523494137654,
      },
      {
        date: "2019-07-24",
        value: 1177.262118491921,
      },
      {
        date: "2019-07-25",
        value: 1180.530814215025,
      },
      {
        date: "2019-07-26",
        value: 1183.1477823666728,
      },
      {
        date: "2019-07-29",
        value: 1184.2971490241925,
      },
      {
        date: "2019-07-30",
        value: 1180.966469428008,
      },
      {
        date: "2019-07-31",
        value: 1182.1809703165636,
      },
      {
        date: "2019-08-01",
        value: 1190.2509739965571,
      },
      {
        date: "2019-08-02",
        value: 1200.64829821718,
      },
      {
        date: "2019-08-05",
        value: 1216.0972992309066,
      },
      {
        date: "2019-08-06",
        value: 1213.3011531241618,
      },
      {
        date: "2019-08-07",
        value: 1215.6222103195857,
      },
      {
        date: "2019-08-08",
        value: 1208.9341552756189,
      },
      {
        date: "2019-08-09",
        value: 1211.153777460261,
      },
      {
        date: "2019-08-12",
        value: 1219.0012506700018,
      },
      {
        date: "2019-08-13",
        value: 1223.6677954018892,
      },
      {
        date: "2019-08-14",
        value: 1214.2474079370754,
      },
      {
        date: "2019-08-15",
        value: 1213.5515695067263,
      },
      {
        date: "2019-08-16",
        value: 1208.5590465872156,
      },
      {
        date: "2019-08-19",
        value: 1209.7090876339728,
      },
      {
        date: "2019-08-20",
        value: 1207.8187071144819,
      },
      {
        date: "2019-08-21",
        value: 1202.9809077809798,
      },
      {
        date: "2019-08-22",
        value: 1209.9521790129027,
      },
      {
        date: "2019-08-23",
        value: 1212.1012200632626,
      },
      {
        date: "2019-08-26",
        value: 1213.4041021950343,
      },
      {
        date: "2019-08-27",
        value: 1212.5630403458213,
      },
      {
        date: "2019-08-28",
        value: 1213.9944058467922,
      },
      {
        date: "2019-08-29",
        value: 1210.2691473988439,
      },
      {
        date: "2019-08-30",
        value: 1208.0554548749549,
      },
      {
        date: "2019-09-02",
        value: 1212.153537563822,
      },
      {
        date: "2019-09-03",
        value: 1214.9583980981988,
      },
      {
        date: "2019-09-04",
        value: 1205.264113269196,
      },
      {
        date: "2019-09-05",
        value: 1197.2327726532828,
      },
      {
        date: "2019-09-06",
        value: 1192.2735104742903,
      },
      {
        date: "2019-09-09",
        value: 1192.7762168041331,
      },
      {
        date: "2019-09-10",
        value: 1191.9112318840578,
      },
      {
        date: "2019-09-11",
        value: 1192.7110787966917,
      },
      {
        date: "2019-09-12",
        value: 1182.9426251938337,
      },
      {
        date: "2019-09-13",
        value: 1177.0007209805335,
      },
      {
        date: "2019-09-16",
        value: 1184.0540295530777,
      },
      {
        date: "2019-09-17",
        value: 1189.5973154362416,
      },
      {
        date: "2019-09-18",
        value: 1190.8260200850448,
      },
      {
        date: "2019-09-19",
        value: 1194.217041655372,
      },
      {
        date: "2019-09-20",
        value: 1188.7941976427924,
      },
      {
        date: "2019-09-23",
        value: 1197.3327264451525,
      },
      {
        date: "2019-09-24",
        value: 1193.9107516131965,
      },
      {
        date: "2019-09-25",
        value: 1200.5008195228556,
      },
      {
        date: "2019-09-26",
        value: 1201.2342292923752,
      },
      {
        date: "2019-09-27",
        value: 1198.3996342021035,
      },
      {
        date: "2019-09-30",
        value: 1198.3010377445128,
      },
      {
        date: "2019-10-01",
        value: 1202.569278766746,
      },
      {
        date: "2019-10-02",
        value: 1206.5903890160182,
      },
      {
        date: "2019-10-03",
        value: 1203.3969500502237,
      },
      {
        date: "2019-10-04",
        value: 1195.3001184078694,
      },
      {
        date: "2019-10-07",
        value: 1196.3977076321296,
      },
      {
        date: "2019-10-08",
        value: 1198.2614236300747,
      },
      {
        date: "2019-10-09",
        value: 1197.3135415718057,
      },
      {
        date: "2019-10-10",
        value: 1192.9918404351768,
      },
      {
        date: "2019-10-11",
        value: 1185.013130489903,
      },
      {
        date: "2019-10-14",
        value: 1184.8971081497598,
      },
      {
        date: "2019-10-15",
        value: 1186.0724993186154,
      },
      {
        date: "2019-10-16",
        value: 1188.6712018140588,
      },
      {
        date: "2019-10-17",
        value: 1178.9615765319897,
      },
      {
        date: "2019-10-18",
        value: 1179.4059583632447,
      },
      {
        date: "2019-10-21",
        value: 1171.6101315671708,
      },
      {
        date: "2019-10-22",
        value: 1173.2524707996406,
      },
      {
        date: "2019-10-23",
        value: 1171.8511193023464,
      },
      {
        date: "2019-10-24",
        value: 1173.0140186915887,
      },
      {
        date: "2019-10-25",
        value: 1174.8176825425405,
      },
      {
        date: "2019-10-28",
        value: 1169.928745377469,
      },
      {
        date: "2019-10-29",
        value: 1167.9675529517801,
      },
      {
        date: "2019-10-30",
        value: 1168.0262920943635,
      },
      {
        date: "2019-10-31",
        value: 1168.0742334588488,
      },
      {
        date: "2019-11-01",
        value: 1167.1514498608492,
      },
      {
        date: "2019-11-04",
        value: 1162.322996952859,
      },
      {
        date: "2019-11-05",
        value: 1156.1256638761363,
      },
      {
        date: "2019-11-06",
        value: 1158.0703336339043,
      },
      {
        date: "2019-11-07",
        value: 1154.8885077186965,
      },
      {
        date: "2019-11-08",
        value: 1157.0237447888346,
      },
      {
        date: "2019-11-11",
        value: 1166.5609999094283,
      },
      {
        date: "2019-11-12",
        value: 1163.6768043576942,
      },
      {
        date: "2019-11-13",
        value: 1170.4070506996184,
      },
      {
        date: "2019-11-14",
        value: 1171.1103028098573,
      },
      {
        date: "2019-11-15",
        value: 1164.890338952329,
      },
      {
        date: "2019-11-18",
        value: 1165.4823252870447,
      },
      {
        date: "2019-11-19",
        value: 1166.3898167373839,
      },
      {
        date: "2019-11-20",
        value: 1170.5307894022967,
      },
      {
        date: "2019-11-21",
        value: 1174.9256153638084,
      },
      {
        date: "2019-11-22",
        value: 1178.3957315970338,
      },
      {
        date: "2019-11-25",
        value: 1175.4723837209303,
      },
      {
        date: "2019-11-26",
        value: 1176.8330308529944,
      },
      {
        date: "2019-11-27",
        value: 1177.1096375692616,
      },
      {
        date: "2019-11-28",
        value: 1179.4638800545206,
      },
      {
        date: "2019-11-29",
        value: 1179.9399016572572,
      },
      {
        date: "2019-12-02",
        value: 1185.2671686473736,
      },
      {
        date: "2019-12-03",
        value: 1191.5364465721254,
      },
      {
        date: "2019-12-04",
        value: 1192.311163252414,
      },
      {
        date: "2019-12-05",
        value: 1190.454299621417,
      },
      {
        date: "2019-12-06",
        value: 1187.4076077158825,
      },
      {
        date: "2019-12-09",
        value: 1191.2325056433408,
      },
      {
        date: "2019-12-10",
        value: 1192.326442177485,
      },
      {
        date: "2019-12-11",
        value: 1193.2279909706547,
      },
      {
        date: "2019-12-12",
        value: 1188.282302235791,
      },
      {
        date: "2019-12-13",
        value: 1171.4426346876678,
      },
      {
        date: "2019-12-16",
        value: 1170.527543513368,
      },
      {
        date: "2019-12-17",
        value: 1164.1103744848594,
      },
      {
        date: "2019-12-18",
        value: 1166.4957264957266,
      },
      {
        date: "2019-12-19",
        value: 1166.3218494198074,
      },
      {
        date: "2019-12-20",
        value: 1159.3944309272777,
      },
      {
        date: "2019-12-23",
        value: 1163.44920993228,
      },
      {
        date: "2019-12-24",
        value: 1162.1931407942238,
      },
      {
        date: "2019-12-27",
        value: 1160.602528467677,
      },
      {
        date: "2019-12-30",
        value: 1156.80579140227,
      },
      {
        date: "2019-12-31",
        value: 1153.889976855973,
      },
      {
        date: "2020-01-02",
        value: 1159.8677745019208,
      },
      {
        date: "2020-01-03",
        value: 1166.520139947968,
      },
      {
        date: "2020-01-06",
        value: 1169.2245845988923,
      },
      {
        date: "2020-01-07",
        value: 1165.843179377014,
      },
      {
        date: "2020-01-08",
        value: 1167.2244714349977,
      },
      {
        date: "2020-01-09",
        value: 1159.5679567956795,
      },
      {
        date: "2020-01-10",
        value: 1159.498692633667,
      },
      {
        date: "2020-01-13",
        value: 1157.7296422793456,
      },
      {
        date: "2020-01-14",
        value: 1156.761133603239,
      },
      {
        date: "2020-01-15",
        value: 1157.0813139472266,
      },
      {
        date: "2020-01-16",
        value: 1158.4474885844747,
      },
      {
        date: "2020-01-17",
        value: 1159.8577601728482,
      },
      {
        date: "2020-01-20",
        value: 1160.1984663960307,
      },
      {
        date: "2020-01-21",
        value: 1167.8992352676562,
      },
      {
        date: "2020-01-22",
        value: 1163.9339826839825,
      },
      {
        date: "2020-01-23",
        value: 1167.7486250112704,
      },
      {
        date: "2020-01-24",
        value: 1168.2102401449931,
      },
      {
        date: "2020-01-27",
        value: 1177.6326530612243,
      },
      {
        date: "2020-01-28",
        value: 1178.7369377555656,
      },
      {
        date: "2020-01-29",
        value: 1178.6655758567404,
      },
      {
        date: "2020-01-30",
        value: 1192.3111796173723,
      },
      {
        date: "2020-01-31",
        value: 1195.8016648570394,
      },
      {
        date: "2020-02-03",
        value: 1192.0477137176938,
      },
      {
        date: "2020-02-04",
        value: 1186.413830557567,
      },
      {
        date: "2020-02-05",
        value: 1182.0466297741086,
      },
      {
        date: "2020-02-06",
        value: 1183.1136962646551,
      },
      {
        date: "2020-02-07",
        value: 1194.0742091348345,
      },
      {
        date: "2020-02-10",
        value: 1188.3937539950691,
      },
      {
        date: "2020-02-11",
        value: 1183.9556004036326,
      },
      {
        date: "2020-02-12",
        value: 1180.1356056441268,
      },
      {
        date: "2020-02-13",
        value: 1183.4176865740314,
      },
      {
        date: "2020-02-14",
        value: 1184.0527577937648,
      },
      {
        date: "2020-02-17",
        value: 1183.8486386709737,
      },
      {
        date: "2020-02-18",
        value: 1191.5125739644973,
      },
      {
        date: "2020-02-19",
        value: 1189.5833333333333,
      },
      {
        date: "2020-02-20",
        value: 1206.4782205746062,
      },
      {
        date: "2020-02-21",
        value: 1212.3414498657532,
      },
      {
        date: "2020-02-24",
        value: 1219.4490663708634,
      },
      {
        date: "2020-02-25",
        value: 1213.7269372693727,
      },
      {
        date: "2020-02-26",
        value: 1215.8988505747127,
      },
      {
        date: "2020-02-27",
        value: 1211.0817219992703,
      },
      {
        date: "2020-02-28",
        value: 1207.051106859798,
      },
      {
        date: "2020-03-02",
        value: 1192.0967451897138,
      },
      {
        date: "2020-03-03",
        value: 1194.1980750202392,
      },
      {
        date: "2020-03-04",
        value: 1181.9865168539325,
      },
      {
        date: "2020-03-05",
        value: 1186.2072047912754,
      },
      {
        date: "2020-03-06",
        value: 1192.3341566690192,
      },
      {
        date: "2020-03-09",
        value: 1199.9912709497207,
      },
      {
        date: "2020-03-10",
        value: 1191.5013169446881,
      },
      {
        date: "2020-03-11",
        value: 1189.1937191249117,
      },
      {
        date: "2020-03-12",
        value: 1209.4306049822064,
      },
      {
        date: "2020-03-13",
        value: 1208.0151296829972,
      },
      {
        date: "2020-03-16",
        value: 1230.9222909384243,
      },
      {
        date: "2020-03-17",
        value: 1243.1342196321252,
      },
      {
        date: "2020-03-18",
        value: 1260.1060910920066,
      },
      {
        date: "2020-03-19",
        value: 1262.9756504027405,
      },
      {
        date: "2020-03-20",
        value: 1247.4455963388439,
      },
      {
        date: "2020-03-23",
        value: 1256.7003616804227,
      },
      {
        date: "2020-03-24",
        value: 1241.9533339481693,
      },
      {
        date: "2020-03-25",
        value: 1232.8622887226377,
      },
      {
        date: "2020-03-26",
        value: 1217.0658409980877,
      },
      {
        date: "2020-03-27",
        value: 1226.482645531566,
      },
      {
        date: "2020-03-30",
        value: 1224.8685880007251,
      },
      {
        date: "2020-03-31",
        value: 1224.0142387732749,
      },
      {
        date: "2020-04-01",
        value: 1231.2362838332115,
      },
      {
        date: "2020-04-02",
        value: 1228.5714285714287,
      },
      {
        date: "2020-04-03",
        value: 1235.8089939731108,
      },
      {
        date: "2020-04-06",
        value: 1228.8388471874712,
      },
      {
        date: "2020-04-07",
        value: 1212.1175930179147,
      },
      {
        date: "2020-04-08",
        value: 1218.2503909483949,
      },
      {
        date: "2020-04-09",
        value: 1216.978006809607,
      },
      {
        date: "2020-04-14",
        value: 1215.4793395968256,
      },
      {
        date: "2020-04-15",
        value: 1220.3430248555442,
      },
      {
        date: "2020-04-16",
        value: 1225.67046289493,
      },
      {
        date: "2020-04-17",
        value: 1216.878453038674,
      },
      {
        date: "2020-04-20",
        value: 1218.7108655616942,
      },
      {
        date: "2020-04-21",
        value: 1232.2044846359693,
      },
      {
        date: "2020-04-22",
        value: 1232.7781356400112,
      },
      {
        date: "2020-04-23",
        value: 1231.7118455254363,
      },
      {
        date: "2020-04-24",
        value: 1233.8796296296296,
      },
      {
        date: "2020-04-27",
        value: 1224.4931809804643,
      },
      {
        date: "2020-04-28",
        value: 1218.902270846741,
      },
      {
        date: "2020-04-29",
        value: 1218.9540675152184,
      },
      {
        date: "2020-04-30",
        value: 1207.3280617874218,
      },
      {
        date: "2020-05-04",
        value: 1224.63900566624,
      },
      {
        date: "2020-05-05",
        value: 1221.8113068339019,
      },
      {
        date: "2020-05-06",
        value: 1224.6506893680023,
      },
      {
        date: "2020-05-07",
        value: 1224.316053046462,
      },
      {
        date: "2020-05-08",
        value: 1219.5978972609057,
      },
      {
        date: "2020-05-11",
        value: 1224.6581670362157,
      },
      {
        date: "2020-05-12",
        value: 1221.3759440044207,
      },
      {
        date: "2020-05-13",
        value: 1223.9816091954024,
      },
      {
        date: "2020-05-14",
        value: 1231.6252779836918,
      },
      {
        date: "2020-05-15",
        value: 1234.1174291535467,
      },
      {
        date: "2020-05-18",
        value: 1230.0775480059085,
      },
      {
        date: "2020-05-19",
        value: 1224.8310502283107,
      },
      {
        date: "2020-05-20",
        value: 1228.0616900894322,
      },
      {
        date: "2020-05-21",
        value: 1231.4545454545453,
      },
      {
        date: "2020-05-22",
        value: 1241.2967718268526,
      },
      {
        date: "2020-05-25",
        value: 1241.640696608616,
      },
      {
        date: "2020-05-26",
        value: 1232.1002277904329,
      },
      {
        date: "2020-05-27",
        value: 1236.5025930306615,
      },
      {
        date: "2020-05-28",
        value: 1237.9811183732754,
      },
      {
        date: "2020-05-29",
        value: 1235.820761494253,
      },
      {
        date: "2020-06-01",
        value: 1227.455919395466,
      },
      {
        date: "2020-06-02",
        value: 1221.066762126365,
      },
      {
        date: "2020-06-03",
        value: 1218.5903162408433,
      },
      {
        date: "2020-06-04",
        value: 1216.648888888889,
      },
      {
        date: "2020-06-05",
        value: 1205.2691968225947,
      },
      {
        date: "2020-06-08",
        value: 1199.113867966327,
      },
      {
        date: "2020-06-09",
        value: 1198.246856738091,
      },
      {
        date: "2020-06-10",
        value: 1188.7208791208793,
      },
      {
        date: "2020-06-11",
        value: 1197.726471624956,
      },
      {
        date: "2020-06-12",
        value: 1203.5120311394196,
      },
      {
        date: "2020-06-15",
        value: 1213.9696081045054,
      },
      {
        date: "2020-06-16",
        value: 1210.7446055889634,
      },
      {
        date: "2020-06-17",
        value: 1212.7938034188035,
      },
      {
        date: "2020-06-18",
        value: 1211.2457672429157,
      },
      {
        date: "2020-06-19",
        value: 1207.4308652988402,
      },
      {
        date: "2020-06-22",
        value: 1214.0372781592794,
      },
      {
        date: "2020-06-23",
        value: 1207.3776285562822,
      },
      {
        date: "2020-06-24",
        value: 1202.4734042553193,
      },
      {
        date: "2020-06-25",
        value: 1203.330357142857,
      },
      {
        date: "2020-06-26",
        value: 1200.5618478551683,
      },
      {
        date: "2020-06-29",
        value: 1198.87451258419,
      },
      {
        date: "2020-06-30",
        value: 1201.8485443829256,
      },
      {
        date: "2020-07-01",
        value: 1203.4285714285713,
      },
      {
        date: "2020-07-02",
        value: 1199.6278575225945,
      },
      {
        date: "2020-07-03",
        value: 1199.6970776906628,
      },
      {
        date: "2020-07-06",
        value: 1192.503311258278,
      },
      {
        date: "2020-07-07",
        value: 1194.9158547387067,
      },
      {
        date: "2020-07-08",
        value: 1195.1621477937265,
      },
      {
        date: "2020-07-09",
        value: 1194.1015693881147,
      },
      {
        date: "2020-07-10",
        value: 1201.4012061014546,
      },
      {
        date: "2020-07-13",
        value: 1201.668284932474,
      },
      {
        date: "2020-07-14",
        value: 1206.1362637362638,
      },
      {
        date: "2020-07-15",
        value: 1200.235931492485,
      },
      {
        date: "2020-07-16",
        value: 1203.5570352199054,
      },
      {
        date: "2020-07-17",
        value: 1204.5414770738537,
      },
      {
        date: "2020-07-20",
        value: 1202.8913347309572,
      },
      {
        date: "2020-07-21",
        value: 1195.455737131871,
      },
      {
        date: "2020-07-22",
        value: 1195.6987389877356,
      },
      {
        date: "2020-07-23",
        value: 1200.8903103120408,
      },
      {
        date: "2020-07-24",
        value: 1203.3339076498964,
      },
      {
        date: "2020-07-27",
        value: 1196.5561224489797,
      },
      {
        date: "2020-07-28",
        value: 1200.0768114705131,
      },
      {
        date: "2020-07-29",
        value: 1194.2174840085288,
      },
      {
        date: "2020-07-30",
        value: 1195.7421442561529,
      },
      {
        date: "2020-07-31",
        value: 1189.702903443619,
      },
      {
        date: "2020-08-03",
        value: 1195.0025584171924,
      },
      {
        date: "2020-08-04",
        value: 1195.4866128346791,
      },
      {
        date: "2020-08-05",
        value: 1185.878184579045,
      },
      {
        date: "2020-08-06",
        value: 1186.3463649413156,
      },
      {
        date: "2020-08-07",
        value: 1186.3417110941864,
      },
      {
        date: "2020-08-10",
        value: 1187.0101164668877,
      },
      {
        date: "2020-08-11",
        value: 1182.8821183060343,
      },
      {
        date: "2020-08-12",
        value: 1184.1984538272025,
      },
      {
        date: "2020-08-13",
        value: 1185.008028395166,
      },
      {
        date: "2020-08-14",
        value: 1186.9211885211207,
      },
      {
        date: "2020-08-17",
        value: 1186.087910233696,
      },
      {
        date: "2020-08-18",
        value: 1184.344028221065,
      },
      {
        date: "2020-08-19",
        value: 1178.2200620129054,
      },
      {
        date: "2020-08-20",
        value: 1188.3713080168777,
      },
      {
        date: "2020-08-21",
        value: 1192.2423315489846,
      },
      {
        date: "2020-08-24",
        value: 1187.068456149236,
      },
      {
        date: "2020-08-25",
        value: 1186.8291857118672,
      },
      {
        date: "2020-08-26",
        value: 1186.674018152515,
      },
      {
        date: "2020-08-27",
        value: 1185.6259529053023,
      },
      {
        date: "2020-08-28",
        value: 1181.0658833403272,
      },
      {
        date: "2020-08-31",
        value: 1185.7286432160804,
      },
      {
        date: "2020-09-01",
        value: 1184.7167765078834,
      },
      {
        date: "2020-09-02",
        value: 1187.1427367001097,
      },
      {
        date: "2020-09-03",
        value: 1190.7644120883772,
      },
      {
        date: "2020-09-04",
        value: 1189.8919101503125,
      },
      {
        date: "2020-09-07",
        value: 1188.3372801082544,
      },
      {
        date: "2020-09-08",
        value: 1189.7666525243953,
      },
      {
        date: "2020-09-09",
        value: 1187.3269345111696,
      },
      {
        date: "2020-09-10",
        value: 1186.462992657608,
      },
      {
        date: "2020-09-11",
        value: 1187.0001687194194,
      },
      {
        date: "2020-09-14",
        value: 1182.8309195015156,
      },
      {
        date: "2020-09-15",
        value: 1177.8758829465187,
      },
      {
        date: "2020-09-16",
        value: 1171.7246608812873,
      },
      {
        date: "2020-09-17",
        value: 1172.4675765025008,
      },
      {
        date: "2020-09-18",
        value: 1162.8158539677174,
      },
      {
        date: "2020-09-21",
        value: 1164.7068804615253,
      },
      {
        date: "2020-09-22",
        value: 1161.7291311754684,
      },
      {
        date: "2020-09-23",
        value: 1165.5833048238112,
      },
      {
        date: "2020-09-24",
        value: 1174.3666809789609,
      },
      {
        date: "2020-09-25",
        value: 1175.4942410177066,
      },
      {
        date: "2020-09-28",
        value: 1169.4258783204798,
      },
      {
        date: "2020-09-29",
        value: 1167.227824303538,
      },
      {
        date: "2020-09-30",
        value: 1168.8674410659378,
      },
      {
        date: "2020-10-01",
        value: 1161.5299523485364,
      },
      {
        date: "2020-10-02",
        value: 1163.4100596760443,
      },
      {
        date: "2020-10-05",
        value: 1158.837525492862,
      },
      {
        date: "2020-10-06",
        value: 1160.22891055532,
      },
      {
        date: "2020-10-07",
        value: 1157.7740016992354,
      },
      {
        date: "2020-10-08",
        value: 1152.1291967700806,
      },
      {
        date: "2020-10-09",
        value: 1144.9936413734633,
      },
      {
        date: "2020-10-12",
        value: 1148.6566658191373,
      },
      {
        date: "2020-10-13",
        value: 1146.1779927038262,
      },
      {
        date: "2020-10-14",
        value: 1146.127659574468,
      },
      {
        date: "2020-10-15",
        value: 1148.9827320909558,
      },
      {
        date: "2020-10-16",
        value: 1142.2877097351163,
      },
      {
        date: "2020-10-19",
        value: 1138.1671616461601,
      },
      {
        date: "2020-10-20",
        value: 1140.008467400508,
      },
      {
        date: "2020-10-21",
        value: 1133.209584880189,
      },
      {
        date: "2020-10-22",
        value: 1134.980120125201,
      },
      {
        date: "2020-10-23",
        value: 1128.9811066126856,
      },
      {
        date: "2020-10-26",
        value: 1132.3377612319148,
      },
      {
        date: "2020-10-27",
        value: 1126.5128465179175,
      },
      {
        date: "2020-10-28",
        value: 1136.2752622154003,
      },
      {
        date: "2020-10-29",
        value: 1133.732057416268,
      },
      {
        date: "2020-10-30",
        value: 1131.9883740810396,
      },
      {
        date: "2020-11-02",
        value: 1133.3762444215583,
      },
      {
        date: "2020-11-03",
        value: 1131.2083404546233,
      },
      {
        date: "2020-11-04",
        value: 1132.360720075079,
      },
      {
        date: "2020-11-05",
        value: 1122.4630957401941,
      },
      {
        date: "2020-11-06",
        value: 1122.6621735467563,
      },
      {
        date: "2020-11-09",
        value: 1112.0339981486159,
      },
      {
        date: "2020-11-10",
        value: 1116.9800135501355,
      },
      {
        date: "2020-11-11",
        value: 1114.3464218935917,
      },
      {
        date: "2020-11-12",
        value: 1114.7400559748962,
      },
      {
        date: "2020-11-13",
        value: 1110.317393144308,
      },
      {
        date: "2020-11-16",
        value: 1107.7768385460693,
      },
      {
        date: "2020-11-17",
        value: 1106.497222689783,
      },
      {
        date: "2020-11-18",
        value: 1104.2719919110211,
      },
      {
        date: "2020-11-19",
        value: 1116.522988505747,
      },
      {
        date: "2020-11-20",
        value: 1115.4514035235607,
      },
      {
        date: "2020-11-23",
        value: 1111.948575749937,
      },
      {
        date: "2020-11-24",
        value: 1111.4791403286977,
      },
      {
        date: "2020-11-25",
        value: 1106.4928511354078,
      },
      {
        date: "2020-11-26",
        value: 1107.764705882353,
      },
      {
        date: "2020-11-27",
        value: 1105.5527596040934,
      },
      {
        date: "2020-11-30",
        value: 1106.9115191986643,
      },
      {
        date: "2020-12-01",
        value: 1108.5561497326203,
      },
      {
        date: "2020-12-02",
        value: 1102.436598707111,
      },
      {
        date: "2020-12-03",
        value: 1091.1941403999672,
      },
      {
        date: "2020-12-04",
        value: 1086.1090550209722,
      },
      {
        date: "2020-12-07",
        value: 1085.8179419525065,
      },
      {
        date: "2020-12-08",
        value: 1085.1329040779262,
      },
      {
        date: "2020-12-09",
        value: 1085.3662565034272,
      },
      {
        date: "2020-12-10",
        value: 1088.3367725959554,
      },
      {
        date: "2020-12-11",
        value: 1092.867155933042,
      },
      {
        date: "2020-12-14",
        value: 1091.5145535273805,
      },
      {
        date: "2020-12-15",
        value: 1093.6490939044481,
      },
      {
        date: "2020-12-16",
        value: 1093.5269505291656,
      },
      {
        date: "2020-12-17",
        value: 1092.9936305732485,
      },
      {
        date: "2020-12-18",
        value: 1098.7437800799414,
      },
      {
        date: "2020-12-21",
        value: 1109.4389222048796,
      },
      {
        date: "2020-12-22",
        value: 1105.8419805539668,
      },
      {
        date: "2020-12-23",
        value: 1107.553838566497,
      },
      {
        date: "2020-12-24",
        value: 1103.0673337160665,
      },
      {
        date: "2020-12-28",
        value: 1097.3647598003108,
      },
      {
        date: "2020-12-29",
        value: 1093.3436658781302,
      },
      {
        date: "2020-12-30",
        value: 1087.720869636023,
      },
      {
        date: "2020-12-31",
        value: 1088.7458234862684,
      },
      {
        date: "2021-01-04",
        value: 1083.3035133376707,
      },
      {
        date: "2021-01-05",
        value: 1088.6235840599786,
      },
      {
        date: "2021-01-06",
        value: 1085.508186091749,
      },
      {
        date: "2021-01-07",
        value: 1093.4261974584554,
      },
      {
        date: "2021-01-08",
        value: 1092.1632653061224,
      },
      {
        date: "2021-01-11",
        value: 1098.2159006823974,
      },
      {
        date: "2021-01-12",
        value: 1098.9063399391498,
      },
      {
        date: "2021-01-13",
        value: 1097.4847936873255,
      },
      {
        date: "2021-01-14",
        value: 1097.3028703398218,
      },
      {
        date: "2021-01-15",
        value: 1100.0577414831312,
      },
      {
        date: "2021-01-18",
        value: 1106.6395888594166,
      },
      {
        date: "2021-01-19",
        value: 1102.4892845367622,
      },
      {
        date: "2021-01-20",
        value: 1102.074208743079,
      },
      {
        date: "2021-01-21",
        value: 1099.777924000658,
      },
      {
        date: "2021-01-22",
        value: 1105.8397762789932,
      },
      {
        date: "2021-01-25",
        value: 1102.8472679394338,
      },
      {
        date: "2021-01-26",
        value: 1103.112904554064,
      },
      {
        date: "2021-01-27",
        value: 1106.8103021297673,
      },
      {
        date: "2021-01-28",
        value: 1117.161525101315,
      },
      {
        date: "2021-01-29",
        value: 1116.4963744232036,
      },
      {
        date: "2021-02-01",
        value: 1118.181065872228,
      },
      {
        date: "2021-02-02",
        value: 1115.9664563268018,
      },
      {
        date: "2021-02-03",
        value: 1115.9440792211035,
      },
      {
        date: "2021-02-04",
        value: 1118.0893631210404,
      },
      {
        date: "2021-02-05",
        value: 1122.798965200701,
      },
      {
        date: "2021-02-08",
        value: 1120.806652806653,
      },
      {
        date: "2021-02-09",
        value: 1113.2022471910113,
      },
      {
        date: "2021-02-10",
        value: 1105.5248618784533,
      },
      {
        date: "2021-02-11",
        value: 1103.1283444471887,
      },
      {
        date: "2021-02-12",
        value: 1105.0379914106375,
      },
      {
        date: "2021-02-15",
        value: 1102.41569791409,
      },
      {
        date: "2021-02-16",
        value: 1104.414065716874,
      },
      {
        date: "2021-02-17",
        value: 1106.7744610281925,
      },
      {
        date: "2021-02-18",
        value: 1106.2975835815955,
      },
      {
        date: "2021-02-19",
        value: 1103.542301672296,
      },
      {
        date: "2021-02-22",
        value: 1113.3520151652517,
      },
      {
        date: "2021-02-23",
        value: 1113.168080375525,
      },
      {
        date: "2021-02-24",
        value: 1108.307261649926,
      },
      {
        date: "2021-02-25",
        value: 1111.7709611451944,
      },
      {
        date: "2021-02-26",
        value: 1127.8772378516624,
      },
      {
        date: "2021-03-01",
        value: 1122.376171907409,
      },
      {
        date: "2021-03-02",
        value: 1126.0142999667441,
      },
      {
        date: "2021-03-03",
        value: 1118.2187915006639,
      },
      {
        date: "2021-03-04",
        value: 1125.054013628054,
      },
      {
        date: "2021-03-05",
        value: 1128.4218462053946,
      },
      {
        date: "2021-03-08",
        value: 1139.1201752907466,
      },
      {
        date: "2021-03-09",
        value: 1138.3134353455525,
      },
      {
        date: "2021-03-10",
        value: 1141.1453077699293,
      },
      {
        date: "2021-03-11",
        value: 1130.4954465703065,
      },
      {
        date: "2021-03-12",
        value: 1135.4479175395961,
      },
      {
        date: "2021-03-15",
        value: 1134.2030201342282,
      },
      {
        date: "2021-03-16",
        value: 1129.7668958577895,
      },
      {
        date: "2021-03-17",
        value: 1130.973377005123,
      },
      {
        date: "2021-03-18",
        value: 1127.6443922095366,
      },
      {
        date: "2021-03-19",
        value: 1130.846858968968,
      },
      {
        date: "2021-03-22",
        value: 1127.6035552574206,
      },
      {
        date: "2021-03-23",
        value: 1129.7483800387108,
      },
      {
        date: "2021-03-24",
        value: 1131.6025369978856,
      },
      {
        date: "2021-03-25",
        value: 1136.1464158617184,
      },
      {
        date: "2021-03-26",
        value: 1130.6823968765916,
      },
      {
        date: "2021-03-29",
        value: 1132.459266802444,
      },
      {
        date: "2021-03-30",
        value: 1133.9323737330722,
      },
      {
        date: "2021-03-31",
        value: 1129.3731343283582,
      },
      {
        date: "2021-04-01",
        value: 1130.9041375787501,
      },
      {
        date: "2021-04-06",
        value: 1120.1828648831697,
      },
      {
        date: "2021-04-07",
        value: 1117.5109390777516,
      },
      {
        date: "2021-04-08",
        value: 1115.9016255369327,
      },
      {
        date: "2021-04-09",
        value: 1119.8519515477792,
      },
      {
        date: "2021-04-12",
        value: 1124.3783602150538,
      },
      {
        date: "2021-04-13",
        value: 1124.1761936785474,
      },
      {
        date: "2021-04-14",
        value: 1114.9030424607154,
      },
      {
        date: "2021-04-15",
        value: 1115.5388471177944,
      },
      {
        date: "2021-04-16",
        value: 1114.3834473552477,
      },
      {
        date: "2021-04-19",
        value: 1114.1587037806398,
      },
      {
        date: "2021-04-20",
        value: 1114.3556551323543,
      },
      {
        date: "2021-04-21",
        value: 1119.521945531773,
      },
      {
        date: "2021-04-22",
        value: 1117.5576955005813,
      },
      {
        date: "2021-04-23",
        value: 1116.3848831427151,
      },
      {
        date: "2021-04-26",
        value: 1111.7583781547373,
      },
      {
        date: "2021-04-27",
        value: 1111.4080079417604,
      },
      {
        date: "2021-04-28",
        value: 1114.291632145816,
      },
      {
        date: "2021-04-29",
        value: 1107.5851265561876,
      },
      {
        date: "2021-04-30",
        value: 1114.252607184241,
      },
      {
        date: "2021-05-03",
        value: 1122.5091331783462,
      },
      {
        date: "2021-05-04",
        value: 1125.9961733632808,
      },
      {
        date: "2021-05-05",
        value: 1125.3477717617661,
      },
      {
        date: "2021-05-06",
        value: 1121.7081260364841,
      },
      {
        date: "2021-05-07",
        value: 1119.927025458164,
      },
      {
        date: "2021-05-10",
        value: 1111.9566110608923,
      },
      {
        date: "2021-05-11",
        value: 1118.5127362366475,
      },
      {
        date: "2021-05-12",
        value: 1124.7565604885294,
      },
      {
        date: "2021-05-13",
        value: 1132.3814253786938,
      },
      {
        date: "2021-05-14",
        value: 1128.4912975336138,
      },
      {
        date: "2021-05-17",
        value: 1138.252491147163,
      },
      {
        date: "2021-05-18",
        value: 1127.5650466372115,
      },
      {
        date: "2021-05-19",
        value: 1129.9541434654438,
      },
      {
        date: "2021-05-20",
        value: 1130.7875112677211,
      },
      {
        date: "2021-05-21",
        value: 1126.5589104036756,
      },
      {
        date: "2021-05-24",
        value: 1126.293809367835,
      },
      {
        date: "2021-05-25",
        value: 1121.6324200913243,
      },
      {
        date: "2021-05-26",
        value: 1117.0169269768583,
      },
      {
        date: "2021-05-27",
        value: 1117.1011641252665,
      },
      {
        date: "2021-05-28",
        value: 1116.669411958491,
      },
      {
        date: "2021-05-31",
        value: 1108.1386771576101,
      },
      {
        date: "2021-06-01",
        value: 1108.7034764826178,
      },
      {
        date: "2021-06-02",
        value: 1112.8426062694896,
      },
      {
        date: "2021-06-03",
        value: 1114.096988594404,
      },
      {
        date: "2021-06-04",
        value: 1116.5552529504002,
      },
      {
        date: "2021-06-07",
        value: 1111.0261470152936,
      },
      {
        date: "2021-06-08",
        value: 1115.3587259891644,
      },
      {
        date: "2021-06-09",
        value: 1115.129151291513,
      },
      {
        date: "2021-06-10",
        value: 1116.1163134549038,
      },
      {
        date: "2021-06-11",
        value: 1114.301030927835,
      },
      {
        date: "2021-06-14",
        value: 1118.7830250990753,
      },
      {
        date: "2021-06-15",
        value: 1118.3515031384206,
      },
      {
        date: "2021-06-16",
        value: 1118.6324645331574,
      },
      {
        date: "2021-06-17",
        value: 1134.3386110413,
      },
      {
        date: "2021-06-18",
        value: 1132.2323079509163,
      },
      {
        date: "2021-06-21",
        value: 1134.6312337061643,
      },
      {
        date: "2021-06-22",
        value: 1135.631410795359,
      },
      {
        date: "2021-06-23",
        value: 1136.2731152204835,
      },
      {
        date: "2021-06-24",
        value: 1131.928619302949,
      },
      {
        date: "2021-06-25",
        value: 1126.652719665272,
      },
      {
        date: "2021-06-28",
        value: 1131.7548278757347,
      },
      {
        date: "2021-06-29",
        value: 1128.2385598923283,
      },
      {
        date: "2021-06-30",
        value: 1128.752945136318,
      },
      {
        date: "2021-07-01",
        value: 1132.497475597442,
      },
    ],
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    min: 0.68,
    currency: "GBP",
    currencySymbol: "£",
    placeholderValue: 110,
    max: 0.9,
    ppp: 1.05,
    vat: 0.2,
    fxRates: [
      {
        date: "2019-01-01",
        value: 0.7911292445,
      },
      {
        date: "2019-01-02",
        value: 0.7958406768,
      },
      {
        date: "2019-01-03",
        value: 0.7891607472,
      },
      {
        date: "2019-01-06",
        value: 0.7839231105,
      },
      {
        date: "2019-01-07",
        value: 0.7844667832,
      },
      {
        date: "2019-01-08",
        value: 0.7849236141,
      },
      {
        date: "2019-01-09",
        value: 0.7839011704,
      },
      {
        date: "2019-01-10",
        value: 0.7804994364,
      },
      {
        date: "2019-01-13",
        value: 0.7784337665,
      },
      {
        date: "2019-01-14",
        value: 0.7792804622,
      },
      {
        date: "2019-01-15",
        value: 0.7778558258,
      },
      {
        date: "2019-01-16",
        value: 0.7744822745,
      },
      {
        date: "2019-01-17",
        value: 0.7728907209,
      },
      {
        date: "2019-01-20",
        value: 0.7771783137,
      },
      {
        date: "2019-01-21",
        value: 0.7750572485,
      },
      {
        date: "2019-01-22",
        value: 0.7672472948,
      },
      {
        date: "2019-01-23",
        value: 0.7678776122,
      },
      {
        date: "2019-01-24",
        value: 0.7630883131,
      },
      {
        date: "2019-01-27",
        value: 0.7609739009,
      },
      {
        date: "2019-01-28",
        value: 0.7593678865,
      },
      {
        date: "2019-01-29",
        value: 0.7642050923,
      },
      {
        date: "2019-01-30",
        value: 0.7623433148,
      },
      {
        date: "2019-01-31",
        value: 0.7661755732,
      },
      {
        date: "2019-02-03",
        value: 0.7660812582,
      },
      {
        date: "2019-02-04",
        value: 0.7686509673,
      },
      {
        date: "2019-02-05",
        value: 0.771195366,
      },
      {
        date: "2019-02-06",
        value: 0.7734684883,
      },
      {
        date: "2019-02-07",
        value: 0.7711087608,
      },
      {
        date: "2019-02-10",
        value: 0.7747369352,
      },
      {
        date: "2019-02-11",
        value: 0.7764252833,
      },
      {
        date: "2019-02-12",
        value: 0.7744626272,
      },
      {
        date: "2019-02-13",
        value: 0.780484558,
      },
      {
        date: "2019-02-14",
        value: 0.7809769094,
      },
      {
        date: "2019-02-17",
        value: 0.7734816384,
      },
      {
        date: "2019-02-18",
        value: 0.7719585621,
      },
      {
        date: "2019-02-19",
        value: 0.7665755599,
      },
      {
        date: "2019-02-20",
        value: 0.7645323234,
      },
      {
        date: "2019-02-21",
        value: 0.7705342163,
      },
      {
        date: "2019-02-24",
        value: 0.7646675473,
      },
      {
        date: "2019-02-25",
        value: 0.7574597307,
      },
      {
        date: "2019-02-26",
        value: 0.7509485333,
      },
      {
        date: "2019-02-27",
        value: 0.7518833217,
      },
      {
        date: "2019-02-28",
        value: 0.7552314855,
      },
      {
        date: "2019-03-03",
        value: 0.7565934551,
      },
      {
        date: "2019-03-04",
        value: 0.7622738106,
      },
      {
        date: "2019-03-05",
        value: 0.7604599735,
      },
      {
        date: "2019-03-06",
        value: 0.7619554609,
      },
      {
        date: "2019-03-07",
        value: 0.7655052575,
      },
      {
        date: "2019-03-10",
        value: 0.7669868374,
      },
      {
        date: "2019-03-11",
        value: 0.7640354767,
      },
      {
        date: "2019-03-12",
        value: 0.7597982836,
      },
      {
        date: "2019-03-13",
        value: 0.7545639664,
      },
      {
        date: "2019-03-14",
        value: 0.7553501946,
      },
      {
        date: "2019-03-17",
        value: 0.7547801568,
      },
      {
        date: "2019-03-18",
        value: 0.7531959852,
      },
      {
        date: "2019-03-19",
        value: 0.7599084023,
      },
      {
        date: "2019-03-20",
        value: 0.7609554755,
      },
      {
        date: "2019-03-21",
        value: 0.7599539904,
      },
      {
        date: "2019-03-24",
        value: 0.7561854305,
      },
      {
        date: "2019-03-25",
        value: 0.7557346559,
      },
      {
        date: "2019-03-26",
        value: 0.7558653761,
      },
      {
        date: "2019-03-27",
        value: 0.7626582278,
      },
      {
        date: "2019-03-28",
        value: 0.7639519359,
      },
      {
        date: "2019-03-31",
        value: 0.7623531506,
      },
      {
        date: "2019-04-01",
        value: 0.7678571429,
      },
      {
        date: "2019-04-02",
        value: 0.7594947968,
      },
      {
        date: "2019-04-03",
        value: 0.761369106,
      },
      {
        date: "2019-04-04",
        value: 0.765049408,
      },
      {
        date: "2019-04-07",
        value: 0.7663435888,
      },
      {
        date: "2019-04-08",
        value: 0.7655848187,
      },
      {
        date: "2019-04-09",
        value: 0.763214824,
      },
      {
        date: "2019-04-10",
        value: 0.7649857955,
      },
      {
        date: "2019-04-11",
        value: 0.7622118187,
      },
      {
        date: "2019-04-14",
        value: 0.7628834085,
      },
      {
        date: "2019-04-15",
        value: 0.7641751437,
      },
      {
        date: "2019-04-16",
        value: 0.7662419255,
      },
      {
        date: "2019-04-17",
        value: 0.7686222222,
      },
      {
        date: "2019-04-22",
        value: 0.7687861272,
      },
      {
        date: "2019-04-23",
        value: 0.7721027746,
      },
      {
        date: "2019-04-24",
        value: 0.7770835206,
      },
      {
        date: "2019-04-25",
        value: 0.7755322016,
      },
      {
        date: "2019-04-28",
        value: 0.7743497758,
      },
      {
        date: "2019-04-29",
        value: 0.7688357996,
      },
      {
        date: "2019-05-01",
        value: 0.7664109882,
      },
      {
        date: "2019-05-02",
        value: 0.769027342,
      },
      {
        date: "2019-05-05",
        value: 0.7631931422,
      },
      {
        date: "2019-05-06",
        value: 0.7657130085,
      },
      {
        date: "2019-05-07",
        value: 0.7685681128,
      },
      {
        date: "2019-05-08",
        value: 0.7694094523,
      },
      {
        date: "2019-05-09",
        value: 0.768032057,
      },
      {
        date: "2019-05-12",
        value: 0.767896843,
      },
      {
        date: "2019-05-13",
        value: 0.772519152,
      },
      {
        date: "2019-05-14",
        value: 0.7763569704,
      },
      {
        date: "2019-05-15",
        value: 0.780710524,
      },
      {
        date: "2019-05-16",
        value: 0.7840583602,
      },
      {
        date: "2019-05-19",
        value: 0.7846332945,
      },
      {
        date: "2019-05-20",
        value: 0.7849655049,
      },
      {
        date: "2019-05-21",
        value: 0.7902604959,
      },
      {
        date: "2019-05-22",
        value: 0.7909148038,
      },
      {
        date: "2019-05-23",
        value: 0.7894699204,
      },
      {
        date: "2019-05-26",
        value: 0.7878639043,
      },
      {
        date: "2019-05-27",
        value: 0.789608649,
      },
      {
        date: "2019-05-28",
        value: 0.7908300466,
      },
      {
        date: "2019-05-29",
        value: 0.7919705407,
      },
      {
        date: "2019-05-30",
        value: 0.7953815801,
      },
      {
        date: "2019-06-02",
        value: 0.7922932499,
      },
      {
        date: "2019-06-03",
        value: 0.7892031306,
      },
      {
        date: "2019-06-04",
        value: 0.7873412099,
      },
      {
        date: "2019-06-05",
        value: 0.7860642642,
      },
      {
        date: "2019-06-06",
        value: 0.7866140335,
      },
      {
        date: "2019-06-09",
        value: 0.7897354216,
      },
      {
        date: "2019-06-10",
        value: 0.7869699647,
      },
      {
        date: "2019-06-11",
        value: 0.7842886161,
      },
      {
        date: "2019-06-12",
        value: 0.7879174418,
      },
      {
        date: "2019-06-13",
        value: 0.7908832668,
      },
      {
        date: "2019-06-16",
        value: 0.7940893716,
      },
      {
        date: "2019-06-17",
        value: 0.7991686779,
      },
      {
        date: "2019-06-18",
        value: 0.7943249755,
      },
      {
        date: "2019-06-19",
        value: 0.7884938534,
      },
      {
        date: "2019-06-20",
        value: 0.7902527395,
      },
      {
        date: "2019-06-23",
        value: 0.7843163068,
      },
      {
        date: "2019-06-24",
        value: 0.7857832806,
      },
      {
        date: "2019-06-25",
        value: 0.7886199613,
      },
      {
        date: "2019-06-26",
        value: 0.7865259455,
      },
      {
        date: "2019-06-27",
        value: 0.7878295255,
      },
      {
        date: "2019-06-30",
        value: 0.7905366112,
      },
      {
        date: "2019-07-01",
        value: 0.7914609327,
      },
      {
        date: "2019-07-02",
        value: 0.7947401045,
      },
      {
        date: "2019-07-03",
        value: 0.7949149539,
      },
      {
        date: "2019-07-04",
        value: 0.796651865,
      },
      {
        date: "2019-07-07",
        value: 0.7991529202,
      },
      {
        date: "2019-07-08",
        value: 0.8029897367,
      },
      {
        date: "2019-07-09",
        value: 0.8014973262,
      },
      {
        date: "2019-07-10",
        value: 0.795657953,
      },
      {
        date: "2019-07-11",
        value: 0.7979027815,
      },
      {
        date: "2019-07-14",
        value: 0.7975241814,
      },
      {
        date: "2019-07-15",
        value: 0.8042412902,
      },
      {
        date: "2019-07-16",
        value: 0.8055283103,
      },
      {
        date: "2019-07-17",
        value: 0.8011144793,
      },
      {
        date: "2019-07-18",
        value: 0.7984589346,
      },
      {
        date: "2019-07-21",
        value: 0.8022113241,
      },
      {
        date: "2019-07-22",
        value: 0.8039917659,
      },
      {
        date: "2019-07-23",
        value: 0.8002064632,
      },
      {
        date: "2019-07-24",
        value: 0.8003868646,
      },
      {
        date: "2019-07-25",
        value: 0.8047495062,
      },
      {
        date: "2019-07-28",
        value: 0.8134094793,
      },
      {
        date: "2019-07-29",
        value: 0.8217052179,
      },
      {
        date: "2019-07-30",
        value: 0.8216572505,
      },
      {
        date: "2019-07-31",
        value: 0.825858476,
      },
      {
        date: "2019-08-01",
        value: 0.823924005,
      },
      {
        date: "2019-08-04",
        value: 0.8216776963,
      },
      {
        date: "2019-08-05",
        value: 0.8208635023,
      },
      {
        date: "2019-08-06",
        value: 0.8244331369,
      },
      {
        date: "2019-08-07",
        value: 0.8229697132,
      },
      {
        date: "2019-08-08",
        value: 0.8288980175,
      },
      {
        date: "2019-08-11",
        value: 0.827362873,
      },
      {
        date: "2019-08-12",
        value: 0.8272144003,
      },
      {
        date: "2019-08-13",
        value: 0.8283249911,
      },
      {
        date: "2019-08-14",
        value: 0.8238834081,
      },
      {
        date: "2019-08-15",
        value: 0.8218941856,
      },
      {
        date: "2019-08-18",
        value: 0.8248491399,
      },
      {
        date: "2019-08-19",
        value: 0.8272210184,
      },
      {
        date: "2019-08-20",
        value: 0.8244326369,
      },
      {
        date: "2019-08-21",
        value: 0.8224307498,
      },
      {
        date: "2019-08-22",
        value: 0.8174694984,
      },
      {
        date: "2019-08-25",
        value: 0.8169125585,
      },
      {
        date: "2019-08-26",
        value: 0.8144362392,
      },
      {
        date: "2019-08-27",
        value: 0.8194802851,
      },
      {
        date: "2019-08-28",
        value: 0.8176571532,
      },
      {
        date: "2019-08-29",
        value: 0.8206324755,
      },
      {
        date: "2019-09-01",
        value: 0.8280452225,
      },
      {
        date: "2019-09-02",
        value: 0.8309865594,
      },
      {
        date: "2019-09-03",
        value: 0.8191595571,
      },
      {
        date: "2019-09-04",
        value: 0.8100922409,
      },
      {
        date: "2019-09-05",
        value: 0.8128684139,
      },
      {
        date: "2019-09-08",
        value: 0.8091634188,
      },
      {
        date: "2019-09-09",
        value: 0.8088768116,
      },
      {
        date: "2019-09-10",
        value: 0.8100790693,
      },
      {
        date: "2019-09-11",
        value: 0.8110918544,
      },
      {
        date: "2019-09-12",
        value: 0.8029289834,
      },
      {
        date: "2019-09-15",
        value: 0.8023751246,
      },
      {
        date: "2019-09-16",
        value: 0.8054870307,
      },
      {
        date: "2019-09-17",
        value: 0.802678006,
      },
      {
        date: "2019-09-18",
        value: 0.8017981386,
      },
      {
        date: "2019-09-19",
        value: 0.7999093382,
      },
      {
        date: "2019-09-22",
        value: 0.8042148384,
      },
      {
        date: "2019-09-23",
        value: 0.8011724075,
      },
      {
        date: "2019-09-24",
        value: 0.8073939173,
      },
      {
        date: "2019-09-25",
        value: 0.8091515816,
      },
      {
        date: "2019-09-26",
        value: 0.8118701417,
      },
      {
        date: "2019-09-29",
        value: 0.81341721,
      },
      {
        date: "2019-09-30",
        value: 0.8162506882,
      },
      {
        date: "2019-10-01",
        value: 0.8143707094,
      },
      {
        date: "2019-10-02",
        value: 0.8107935348,
      },
      {
        date: "2019-10-03",
        value: 0.8110483651,
      },
      {
        date: "2019-10-06",
        value: 0.8110161012,
      },
      {
        date: "2019-10-07",
        value: 0.8173584562,
      },
      {
        date: "2019-10-08",
        value: 0.8182314908,
      },
      {
        date: "2019-10-09",
        value: 0.8173617407,
      },
      {
        date: "2019-10-10",
        value: 0.7925201485,
      },
      {
        date: "2019-10-13",
        value: 0.7975976793,
      },
      {
        date: "2019-10-14",
        value: 0.7909330426,
      },
      {
        date: "2019-10-15",
        value: 0.7851247166,
      },
      {
        date: "2019-10-16",
        value: 0.7814271574,
      },
      {
        date: "2019-10-17",
        value: 0.7756191673,
      },
      {
        date: "2019-10-20",
        value: 0.7690861899,
      },
      {
        date: "2019-10-21",
        value: 0.7732704403,
      },
      {
        date: "2019-10-22",
        value: 0.7768407804,
      },
      {
        date: "2019-10-23",
        value: 0.7754133717,
      },
      {
        date: "2019-10-24",
        value: 0.7796704781,
      },
      {
        date: "2019-10-27",
        value: 0.7786416524,
      },
      {
        date: "2019-10-28",
        value: 0.7780802163,
      },
      {
        date: "2019-10-29",
        value: 0.7761570322,
      },
      {
        date: "2019-10-30",
        value: 0.7722162453,
      },
      {
        date: "2019-10-31",
        value: 0.7721339438,
      },
      {
        date: "2019-11-03",
        value: 0.7740455279,
      },
      {
        date: "2019-11-04",
        value: 0.7751642812,
      },
      {
        date: "2019-11-05",
        value: 0.7757709648,
      },
      {
        date: "2019-11-06",
        value: 0.7803737474,
      },
      {
        date: "2019-11-07",
        value: 0.7808410368,
      },
      {
        date: "2019-11-10",
        value: 0.7765872656,
      },
      {
        date: "2019-11-11",
        value: 0.7791193827,
      },
      {
        date: "2019-11-12",
        value: 0.7792113393,
      },
      {
        date: "2019-11-13",
        value: 0.7787851232,
      },
      {
        date: "2019-11-14",
        value: 0.7763277143,
      },
      {
        date: "2019-11-17",
        value: 0.7714492361,
      },
      {
        date: "2019-11-18",
        value: 0.772528663,
      },
      {
        date: "2019-11-19",
        value: 0.7750700787,
      },
      {
        date: "2019-11-20",
        value: 0.7713281039,
      },
      {
        date: "2019-11-21",
        value: 0.7775366251,
      },
      {
        date: "2019-11-24",
        value: 0.7768441134,
      },
      {
        date: "2019-11-25",
        value: 0.7778130672,
      },
      {
        date: "2019-11-26",
        value: 0.7761831229,
      },
      {
        date: "2019-11-27",
        value: 0.7740118128,
      },
      {
        date: "2019-11-28",
        value: 0.7760426152,
      },
      {
        date: "2019-12-01",
        value: 0.7730926245,
      },
      {
        date: "2019-12-02",
        value: 0.7695781772,
      },
      {
        date: "2019-12-03",
        value: 0.7635592456,
      },
      {
        date: "2019-12-04",
        value: 0.7614025599,
      },
      {
        date: "2019-12-05",
        value: 0.761249324,
      },
      {
        date: "2019-12-08",
        value: 0.7602257336,
      },
      {
        date: "2019-12-09",
        value: 0.7589870904,
      },
      {
        date: "2019-12-10",
        value: 0.7606772009,
      },
      {
        date: "2019-12-11",
        value: 0.7592708988,
      },
      {
        date: "2019-12-12",
        value: 0.747342044,
      },
      {
        date: "2019-12-15",
        value: 0.7483850709,
      },
      {
        date: "2019-12-16",
        value: 0.7592546139,
      },
      {
        date: "2019-12-17",
        value: 0.7652271705,
      },
      {
        date: "2019-12-18",
        value: 0.7652514167,
      },
      {
        date: "2019-12-19",
        value: 0.7671713076,
      },
      {
        date: "2019-12-22",
        value: 0.7738871332,
      },
      {
        date: "2019-12-23",
        value: 0.7719584838,
      },
      {
        date: "2019-12-26",
        value: 0.7632923877,
      },
      {
        date: "2019-12-29",
        value: 0.7615336491,
      },
      {
        date: "2019-12-30",
        value: 0.7573437778,
      },
      {
        date: "2020-01-01",
        value: 0.7578665237,
      },
      {
        date: "2020-01-02",
        value: 0.7635686732,
      },
      {
        date: "2020-01-05",
        value: 0.76125603,
      },
      {
        date: "2020-01-06",
        value: 0.7624686717,
      },
      {
        date: "2020-01-07",
        value: 0.7635447593,
      },
      {
        date: "2020-01-08",
        value: 0.7676417642,
      },
      {
        date: "2020-01-09",
        value: 0.76467406,
      },
      {
        date: "2020-01-12",
        value: 0.7708071185,
      },
      {
        date: "2020-01-13",
        value: 0.7702923977,
      },
      {
        date: "2020-01-14",
        value: 0.7690091546,
      },
      {
        date: "2020-01-15",
        value: 0.7652430835,
      },
      {
        date: "2020-01-16",
        value: 0.7661595247,
      },
      {
        date: "2020-01-19",
        value: 0.7692828146,
      },
      {
        date: "2020-01-20",
        value: 0.7650022492,
      },
      {
        date: "2020-01-21",
        value: 0.7615891053,
      },
      {
        date: "2020-01-22",
        value: 0.7618609684,
      },
      {
        date: "2020-01-23",
        value: 0.7640507476,
      },
      {
        date: "2020-01-26",
        value: 0.7651519274,
      },
      {
        date: "2020-01-27",
        value: 0.7687687415,
      },
      {
        date: "2020-01-28",
        value: 0.7688391964,
      },
      {
        date: "2020-01-29",
        value: 0.7632876961,
      },
      {
        date: "2020-01-30",
        value: 0.7616268549,
      },
      {
        date: "2020-02-02",
        value: 0.7660853063,
      },
      {
        date: "2020-02-03",
        value: 0.7682838523,
      },
      {
        date: "2020-02-04",
        value: 0.7660709426,
      },
      {
        date: "2020-02-05",
        value: 0.7710169954,
      },
      {
        date: "2020-02-06",
        value: 0.7723584648,
      },
      {
        date: "2020-02-09",
        value: 0.7727878733,
      },
      {
        date: "2020-02-10",
        value: 0.7735528851,
      },
      {
        date: "2020-02-11",
        value: 0.7701850834,
      },
      {
        date: "2020-02-12",
        value: 0.7672310665,
      },
      {
        date: "2020-02-13",
        value: 0.7674598783,
      },
      {
        date: "2020-02-16",
        value: 0.7682325796,
      },
      {
        date: "2020-02-17",
        value: 0.7672429734,
      },
      {
        date: "2020-02-18",
        value: 0.7698888889,
      },
      {
        date: "2020-02-19",
        value: 0.7770157553,
      },
      {
        date: "2020-02-20",
        value: 0.773169151,
      },
      {
        date: "2020-02-23",
        value: 0.774939915,
      },
      {
        date: "2020-02-24",
        value: 0.7714944649,
      },
      {
        date: "2020-02-25",
        value: 0.7737931034,
      },
      {
        date: "2020-02-26",
        value: 0.7752188982,
      },
      {
        date: "2020-02-27",
        value: 0.7772159971,
      },
      {
        date: "2020-03-01",
        value: 0.7832494156,
      },
      {
        date: "2020-03-02",
        value: 0.7826751822,
      },
      {
        date: "2020-03-03",
        value: 0.7806741573,
      },
      {
        date: "2020-03-04",
        value: 0.7747385358,
      },
      {
        date: "2020-03-05",
        value: 0.7689220183,
      },
      {
        date: "2020-03-08",
        value: 0.7627706006,
      },
      {
        date: "2020-03-09",
        value: 0.7672080773,
      },
      {
        date: "2020-03-10",
        value: 0.7735532816,
      },
      {
        date: "2020-03-11",
        value: 0.7884608541,
      },
      {
        date: "2020-03-12",
        value: 0.8021433718,
      },
      {
        date: "2020-03-15",
        value: 0.8148964775,
      },
      {
        date: "2020-03-16",
        value: 0.8270169368,
      },
      {
        date: "2020-03-17",
        value: 0.8431498079,
      },
      {
        date: "2020-03-18",
        value: 0.86089251,
      },
      {
        date: "2020-03-19",
        value: 0.8501727842,
      },
      {
        date: "2020-03-22",
        value: 0.862190485,
      },
      {
        date: "2020-03-23",
        value: 0.8493959236,
      },
      {
        date: "2020-03-24",
        value: 0.8451371571,
      },
      {
        date: "2020-03-25",
        value: 0.8318732356,
      },
      {
        date: "2020-03-26",
        value: 0.8175548875,
      },
      {
        date: "2020-03-29",
        value: 0.805691499,
      },
      {
        date: "2020-03-30",
        value: 0.8090817817,
      },
      {
        date: "2020-03-31",
        value: 0.8088880761,
      },
      {
        date: "2020-04-01",
        value: 0.8044929397,
      },
      {
        date: "2020-04-02",
        value: 0.8145572554,
      },
      {
        date: "2020-04-05",
        value: 0.8136409971,
      },
      {
        date: "2020-04-06",
        value: 0.8093063849,
      },
      {
        date: "2020-04-07",
        value: 0.80901481,
      },
      {
        date: "2020-04-08",
        value: 0.805788166,
      },
      {
        date: "2020-04-13",
        value: 0.7958861625,
      },
      {
        date: "2020-04-14",
        value: 0.8014766578,
      },
      {
        date: "2020-04-15",
        value: 0.800541881,
      },
      {
        date: "2020-04-16",
        value: 0.8009023941,
      },
      {
        date: "2020-04-19",
        value: 0.8042633517,
      },
      {
        date: "2020-04-20",
        value: 0.8131401679,
      },
      {
        date: "2020-04-21",
        value: 0.809054937,
      },
      {
        date: "2020-04-22",
        value: 0.809506127,
      },
      {
        date: "2020-04-23",
        value: 0.8101666667,
      },
      {
        date: "2020-04-26",
        value: 0.8041190564,
      },
      {
        date: "2020-04-27",
        value: 0.8005700101,
      },
      {
        date: "2020-04-28",
        value: 0.8059214167,
      },
      {
        date: "2020-04-29",
        value: 0.7990529606,
      },
      {
        date: "2020-05-03",
        value: 0.8033083531,
      },
      {
        date: "2020-05-04",
        value: 0.8029143226,
      },
      {
        date: "2020-05-05",
        value: 0.8073748496,
      },
      {
        date: "2020-05-06",
        value: 0.8112584624,
      },
      {
        date: "2020-05-07",
        value: 0.8072950291,
      },
      {
        date: "2020-05-10",
        value: 0.811853289,
      },
      {
        date: "2020-05-11",
        value: 0.8083717075,
      },
      {
        date: "2020-05-12",
        value: 0.8114482759,
      },
      {
        date: "2020-05-13",
        value: 0.8200055597,
      },
      {
        date: "2020-05-14",
        value: 0.8218003334,
      },
      {
        date: "2020-05-17",
        value: 0.8230520679,
      },
      {
        date: "2020-05-18",
        value: 0.8176712329,
      },
      {
        date: "2020-05-19",
        value: 0.8154590254,
      },
      {
        date: "2020-05-20",
        value: 0.8176636364,
      },
      {
        date: "2020-05-21",
        value: 0.8213774762,
      },
      {
        date: "2020-05-24",
        value: 0.8204857929,
      },
      {
        date: "2020-05-25",
        value: 0.8098223235,
      },
      {
        date: "2020-05-26",
        value: 0.8151669548,
      },
      {
        date: "2020-05-27",
        value: 0.8145243282,
      },
      {
        date: "2020-05-28",
        value: 0.8089798851,
      },
      {
        date: "2020-05-31",
        value: 0.8067020511,
      },
      {
        date: "2020-06-01",
        value: 0.7972346519,
      },
      {
        date: "2020-06-02",
        value: 0.7956494551,
      },
      {
        date: "2020-06-03",
        value: 0.7972,
      },
      {
        date: "2020-06-04",
        value: 0.7894792586,
      },
      {
        date: "2020-06-07",
        value: 0.7901905184,
      },
      {
        date: "2020-06-08",
        value: 0.789091553,
      },
      {
        date: "2020-06-09",
        value: 0.7820923077,
      },
      {
        date: "2020-06-10",
        value: 0.7901392316,
      },
      {
        date: "2020-06-11",
        value: 0.7931086341,
      },
      {
        date: "2020-06-14",
        value: 0.7966586688,
      },
      {
        date: "2020-06-15",
        value: 0.7903254333,
      },
      {
        date: "2020-06-16",
        value: 0.7963675214,
      },
      {
        date: "2020-06-17",
        value: 0.802245589,
      },
      {
        date: "2020-06-18",
        value: 0.8073595004,
      },
      {
        date: "2020-06-21",
        value: 0.8055649692,
      },
      {
        date: "2020-06-22",
        value: 0.8007598516,
      },
      {
        date: "2020-06-23",
        value: 0.8009131206,
      },
      {
        date: "2020-06-24",
        value: 0.8047589286,
      },
      {
        date: "2020-06-25",
        value: 0.8077677696,
      },
      {
        date: "2020-06-28",
        value: 0.8112371499,
      },
      {
        date: "2020-06-29",
        value: 0.8148151456,
      },
      {
        date: "2020-06-30",
        value: 0.8074107143,
      },
      {
        date: "2020-07-01",
        value: 0.7994417863,
      },
      {
        date: "2020-07-02",
        value: 0.8029223093,
      },
      {
        date: "2020-07-05",
        value: 0.7991611479,
      },
      {
        date: "2020-07-06",
        value: 0.7984942427,
      },
      {
        date: "2020-07-07",
        value: 0.7967659047,
      },
      {
        date: "2020-07-08",
        value: 0.7904690531,
      },
      {
        date: "2020-07-09",
        value: 0.7943419652,
      },
      {
        date: "2020-07-12",
        value: 0.7938741283,
      },
      {
        date: "2020-07-13",
        value: 0.7980483516,
      },
      {
        date: "2020-07-14",
        value: 0.7911569381,
      },
      {
        date: "2020-07-15",
        value: 0.7961713685,
      },
      {
        date: "2020-07-16",
        value: 0.7969723486,
      },
      {
        date: "2020-07-19",
        value: 0.7911862334,
      },
      {
        date: "2020-07-20",
        value: 0.7869876781,
      },
      {
        date: "2020-07-21",
        value: 0.7870357575,
      },
      {
        date: "2020-07-22",
        value: 0.7882703777,
      },
      {
        date: "2020-07-23",
        value: 0.7838128877,
      },
      {
        date: "2020-07-26",
        value: 0.7767006803,
      },
      {
        date: "2020-07-27",
        value: 0.7763762055,
      },
      {
        date: "2020-07-28",
        value: 0.7708742004,
      },
      {
        date: "2020-07-29",
        value: 0.7686962446,
      },
      {
        date: "2020-07-30",
        value: 0.76006921,
      },
      {
        date: "2020-08-02",
        value: 0.7676360225,
      },
      {
        date: "2020-08-03",
        value: 0.7678283043,
      },
      {
        date: "2020-08-04",
        value: 0.7614729205,
      },
      {
        date: "2020-08-05",
        value: 0.7602212277,
      },
      {
        date: "2020-08-06",
        value: 0.7647710925,
      },
      {
        date: "2020-08-09",
        value: 0.7664286322,
      },
      {
        date: "2020-08-10",
        value: 0.7624798438,
      },
      {
        date: "2020-08-11",
        value: 0.7686262849,
      },
      {
        date: "2020-08-12",
        value: 0.7637370067,
      },
      {
        date: "2020-08-13",
        value: 0.7633370016,
      },
      {
        date: "2020-08-16",
        value: 0.7645743694,
      },
      {
        date: "2020-08-17",
        value: 0.7590710566,
      },
      {
        date: "2020-08-18",
        value: 0.7571021537,
      },
      {
        date: "2020-08-19",
        value: 0.7609535865,
      },
      {
        date: "2020-08-20",
        value: 0.7626391367,
      },
      {
        date: "2020-08-23",
        value: 0.7611631637,
      },
      {
        date: "2020-08-24",
        value: 0.761342475,
      },
      {
        date: "2020-08-25",
        value: 0.7607939605,
      },
      {
        date: "2020-08-26",
        value: 0.7581314586,
      },
      {
        date: "2020-08-27",
        value: 0.7508854385,
      },
      {
        date: "2020-08-30",
        value: 0.7504606365,
      },
      {
        date: "2020-08-31",
        value: 0.742262451,
      },
      {
        date: "2020-09-01",
        value: 0.7490093584,
      },
      {
        date: "2020-09-02",
        value: 0.754550072,
      },
      {
        date: "2020-09-03",
        value: 0.7543067049,
      },
      {
        date: "2020-09-06",
        value: 0.7597682679,
      },
      {
        date: "2020-09-07",
        value: 0.7675265168,
      },
      {
        date: "2020-09-08",
        value: 0.7747642912,
      },
      {
        date: "2020-09-09",
        value: 0.7729766225,
      },
      {
        date: "2020-09-10",
        value: 0.7795512063,
      },
      {
        date: "2020-09-13",
        value: 0.7762714719,
      },
      {
        date: "2020-09-14",
        value: 0.774428187,
      },
      {
        date: "2020-09-15",
        value: 0.7702670823,
      },
      {
        date: "2020-09-16",
        value: 0.7758752225,
      },
      {
        date: "2020-09-17",
        value: 0.7717231471,
      },
      {
        date: "2020-09-20",
        value: 0.7771952151,
      },
      {
        date: "2020-09-21",
        value: 0.7814565588,
      },
      {
        date: "2020-09-22",
        value: 0.7858792337,
      },
      {
        date: "2020-09-23",
        value: 0.7834091885,
      },
      {
        date: "2020-09-24",
        value: 0.7851383875,
      },
      {
        date: "2020-09-27",
        value: 0.7755612682,
      },
      {
        date: "2020-09-28",
        value: 0.7773286618,
      },
      {
        date: "2020-09-29",
        value: 0.7792535019,
      },
      {
        date: "2020-09-30",
        value: 0.7719792376,
      },
      {
        date: "2020-10-01",
        value: 0.7730008525,
      },
      {
        date: "2020-10-04",
        value: 0.7716689327,
      },
      {
        date: "2020-10-05",
        value: 0.7720050869,
      },
      {
        date: "2020-10-06",
        value: 0.7766610025,
      },
      {
        date: "2020-10-07",
        value: 0.7737781555,
      },
      {
        date: "2020-10-08",
        value: 0.7729292073,
      },
      {
        date: "2020-10-11",
        value: 0.7678447326,
      },
      {
        date: "2020-10-12",
        value: 0.7685670654,
      },
      {
        date: "2020-10-13",
        value: 0.7693191489,
      },
      {
        date: "2020-10-14",
        value: 0.7739357155,
      },
      {
        date: "2020-10-15",
        value: 0.7743377906,
      },
      {
        date: "2020-10-18",
        value: 0.7686720407,
      },
      {
        date: "2020-10-19",
        value: 0.773319221,
      },
      {
        date: "2020-10-20",
        value: 0.7657273034,
      },
      {
        date: "2020-10-21",
        value: 0.7636663565,
      },
      {
        date: "2020-10-22",
        value: 0.7648026316,
      },
      {
        date: "2020-10-25",
        value: 0.7678737626,
      },
      {
        date: "2020-10-26",
        value: 0.7667173766,
      },
      {
        date: "2020-10-27",
        value: 0.7731048009,
      },
      {
        date: "2020-10-28",
        value: 0.7726418319,
      },
      {
        date: "2020-10-29",
        value: 0.7711403659,
      },
      {
        date: "2020-11-01",
        value: 0.7728544456,
      },
      {
        date: "2020-11-02",
        value: 0.7694582123,
      },
      {
        date: "2020-11-03",
        value: 0.7674601143,
      },
      {
        date: "2020-11-04",
        value: 0.7629692113,
      },
      {
        date: "2020-11-05",
        value: 0.7618365628,
      },
      {
        date: "2020-11-08",
        value: 0.7592695447,
      },
      {
        date: "2020-11-09",
        value: 0.755276084,
      },
      {
        date: "2020-11-10",
        value: 0.7558643549,
      },
      {
        date: "2020-11-11",
        value: 0.7613009923,
      },
      {
        date: "2020-11-12",
        value: 0.7590605163,
      },
      {
        date: "2020-11-15",
        value: 0.7592476754,
      },
      {
        date: "2020-11-16",
        value: 0.753955563,
      },
      {
        date: "2020-11-17",
        value: 0.7530586451,
      },
      {
        date: "2020-11-18",
        value: 0.7553921569,
      },
      {
        date: "2020-11-19",
        value: 0.7535446346,
      },
      {
        date: "2020-11-22",
        value: 0.7468952189,
      },
      {
        date: "2020-11-23",
        value: 0.7502064897,
      },
      {
        date: "2020-11-24",
        value: 0.7496131203,
      },
      {
        date: "2020-11-25",
        value: 0.7489831933,
      },
      {
        date: "2020-11-26",
        value: 0.7502264721,
      },
      {
        date: "2020-11-29",
        value: 0.7499582638,
      },
      {
        date: "2020-11-30",
        value: 0.7503175134,
      },
      {
        date: "2020-12-01",
        value: 0.7499585612,
      },
      {
        date: "2020-12-02",
        value: 0.743626039,
      },
      {
        date: "2020-12-03",
        value: 0.7425117197,
      },
      {
        date: "2020-12-06",
        value: 0.7521025726,
      },
      {
        date: "2020-12-07",
        value: 0.7523774146,
      },
      {
        date: "2020-12-08",
        value: 0.7432488232,
      },
      {
        date: "2020-12-09",
        value: 0.7519603797,
      },
      {
        date: "2020-12-10",
        value: 0.7610620929,
      },
      {
        date: "2020-12-13",
        value: 0.7457654991,
      },
      {
        date: "2020-12-14",
        value: 0.7478995058,
      },
      {
        date: "2020-12-15",
        value: 0.7379604561,
      },
      {
        date: "2020-12-16",
        value: 0.7353421525,
      },
      {
        date: "2020-12-17",
        value: 0.7409087201,
      },
      {
        date: "2020-12-20",
        value: 0.7525671568,
      },
      {
        date: "2020-12-21",
        value: 0.7525671568,
      },
      {
        date: "2020-12-22",
        value: 0.7447340469,
      },
      {
        date: "2020-12-23",
        value: 0.7455203025,
      },
      {
        date: "2020-12-24",
        value: 0.7364471418,
      },
      {
        date: "2020-12-28",
        value: 0.7398968819,
      },
      {
        date: "2020-12-29",
        value: 0.7411942247,
      },
      {
        date: "2020-12-30",
        value: 0.7353391418,
      },
      {
        date: "2020-12-31",
        value: 0.7326460761,
      },
      {
        date: "2021-01-04",
        value: 0.7332465843,
      },
      {
        date: "2021-01-05",
        value: 0.736150273,
      },
      {
        date: "2021-01-06",
        value: 0.7346004215,
      },
      {
        date: "2021-01-07",
        value: 0.7346855653,
      },
      {
        date: "2021-01-08",
        value: 0.7357387755,
      },
      {
        date: "2021-01-11",
        value: 0.7418811149,
      },
      {
        date: "2021-01-12",
        value: 0.7354658334,
      },
      {
        date: "2021-01-13",
        value: 0.7314072004,
      },
      {
        date: "2021-01-14",
        value: 0.7336110195,
      },
      {
        date: "2021-01-15",
        value: 0.7341252165,
      },
      {
        date: "2021-01-18",
        value: 0.7383372016,
      },
      {
        date: "2021-01-19",
        value: 0.734421365,
      },
      {
        date: "2021-01-20",
        value: 0.7318651351,
      },
      {
        date: "2021-01-21",
        value: 0.7289439052,
      },
      {
        date: "2021-01-22",
        value: 0.7323984208,
      },
      {
        date: "2021-01-25",
        value: 0.7307439105,
      },
      {
        date: "2021-01-26",
        value: 0.7304455242,
      },
      {
        date: "2021-01-27",
        value: 0.7296929173,
      },
      {
        date: "2021-01-28",
        value: 0.7328012571,
      },
      {
        date: "2021-01-29",
        value: 0.7282712591,
      },
      {
        date: "2021-02-01",
        value: 0.7298907646,
      },
      {
        date: "2021-02-02",
        value: 0.7312769844,
      },
      {
        date: "2021-02-03",
        value: 0.7335025381,
      },
      {
        date: "2021-02-04",
        value: 0.7310186729,
      },
      {
        date: "2021-02-05",
        value: 0.7305182342,
      },
      {
        date: "2021-02-08",
        value: 0.7304199584,
      },
      {
        date: "2021-02-09",
        value: 0.7256113681,
      },
      {
        date: "2021-02-10",
        value: 0.7227673786,
      },
      {
        date: "2021-02-11",
        value: 0.7224417552,
      },
      {
        date: "2021-02-12",
        value: 0.7247522299,
      },
      {
        date: "2021-02-15",
        value: 0.7191441999,
      },
      {
        date: "2021-02-16",
        value: 0.7192044799,
      },
      {
        date: "2021-02-17",
        value: 0.7210613599,
      },
      {
        date: "2021-02-18",
        value: 0.7161535915,
      },
      {
        date: "2021-02-19",
        value: 0.7126451932,
      },
      {
        date: "2021-02-22",
        value: 0.7131789335,
      },
      {
        date: "2021-02-23",
        value: 0.7107634028,
      },
      {
        date: "2021-02-24",
        value: 0.7082990285,
      },
      {
        date: "2021-02-25",
        value: 0.7068139059,
      },
      {
        date: "2021-02-26",
        value: 0.7181998185,
      },
      {
        date: "2021-03-01",
        value: 0.7181448602,
      },
      {
        date: "2021-03-02",
        value: 0.7185982707,
      },
      {
        date: "2021-03-03",
        value: 0.7167247676,
      },
      {
        date: "2021-03-04",
        value: 0.7168854911,
      },
      {
        date: "2021-03-05",
        value: 0.7229016586,
      },
      {
        date: "2021-03-08",
        value: 0.7224675544,
      },
      {
        date: "2021-03-09",
        value: 0.7205649908,
      },
      {
        date: "2021-03-10",
        value: 0.7202741339,
      },
      {
        date: "2021-03-11",
        value: 0.7157657281,
      },
      {
        date: "2021-03-12",
        value: 0.7193078019,
      },
      {
        date: "2021-03-15",
        value: 0.7187080537,
      },
      {
        date: "2021-03-16",
        value: 0.7206523562,
      },
      {
        date: "2021-03-17",
        value: 0.7194759385,
      },
      {
        date: "2021-03-18",
        value: 0.7183932169,
      },
      {
        date: "2021-03-19",
        value: 0.7212429569,
      },
      {
        date: "2021-03-22",
        value: 0.723067248,
      },
      {
        date: "2021-03-23",
        value: 0.7253892115,
      },
      {
        date: "2021-03-24",
        value: 0.7293868922,
      },
      {
        date: "2021-03-25",
        value: 0.7292662261,
      },
      {
        date: "2021-03-26",
        value: 0.7257087082,
      },
      {
        date: "2021-03-29",
        value: 0.72233537,
      },
      {
        date: "2021-03-30",
        value: 0.7271782642,
      },
      {
        date: "2021-03-31",
        value: 0.7267292111,
      },
      {
        date: "2021-04-30",
        value: 0.7189455388180765,
      },
      {
        date: "2021-05-03",
        value: 0.7210063101959483,
      },
      {
        date: "2021-05-04",
        value: 0.7218201480742036,
      },
      {
        date: "2021-05-05",
        value: 0.7186505622657227,
      },
      {
        date: "2021-05-06",
        value: 0.7189718076285241,
      },
      {
        date: "2021-05-07",
        value: 0.7198772700887304,
      },
      {
        date: "2021-05-10",
        value: 0.7083162133289506,
      },
      {
        date: "2021-05-11",
        value: 0.7067625308134756,
      },
      {
        date: "2021-05-12",
        value: 0.7080211255982836,
      },
      {
        date: "2021-05-13",
        value: 0.7123830808707888,
      },
      {
        date: "2021-05-14",
        value: 0.7100800131980534,
      },
      {
        date: "2021-05-17",
        value: 0.7099975294408302,
      },
      {
        date: "2021-05-18",
        value: 0.703632793323515,
      },
      {
        date: "2021-05-19",
        value: 0.7061333114968883,
      },
      {
        date: "2021-05-20",
        value: 0.7080226173891666,
      },
      {
        date: "2021-05-21",
        value: 0.7045454545454545,
      },
      {
        date: "2021-05-24",
        value: 0.7075663282017687,
      },
      {
        date: "2021-05-25",
        value: 0.7059116112198305,
      },
      {
        date: "2021-05-26",
        value: 0.7059448851091666,
      },
      {
        date: "2021-05-27",
        value: 0.7055910805050009,
      },
      {
        date: "2021-05-28",
        value: 0.706349859990117,
      },
      {
        date: "2021-05-31",
        value: 0.7049668059995082,
      },
      {
        date: "2021-06-01",
        value: 0.7058077709611452,
      },
      {
        date: "2021-06-02",
        value: 0.7067536517314952,
      },
      {
        date: "2021-06-03",
        value: 0.7053007302863709,
      },
      {
        date: "2021-06-04",
        value: 0.7071882479161509,
      },
      {
        date: "2021-06-07",
        value: 0.7056816313106397,
      },
      {
        date: "2021-06-08",
        value: 0.7067804958134953,
      },
      {
        date: "2021-06-09",
        value: 0.7056416564165642,
      },
      {
        date: "2021-06-10",
        value: 0.7088302940693281,
      },
      {
        date: "2021-06-11",
        value: 0.7068865979381443,
      },
      {
        date: "2021-06-14",
        value: 0.7091974900924702,
      },
      {
        date: "2021-06-15",
        value: 0.7115130492236538,
      },
      {
        date: "2021-06-16",
        value: 0.7079346750247443,
      },
      {
        date: "2021-06-17",
        value: 0.7164697997821898,
      },
      {
        date: "2021-06-18",
        value: 0.7210035300050429,
      },
      {
        date: "2021-06-21",
        value: 0.7204608527457741,
      },
      {
        date: "2021-06-22",
        value: 0.7193542962838406,
      },
      {
        date: "2021-06-23",
        value: 0.7148774161158062,
      },
      {
        date: "2021-06-24",
        value: 0.7195291554959785,
      },
      {
        date: "2021-06-25",
        value: 0.7192468619246862,
      },
      {
        date: "2021-06-28",
        value: 0.7196473551637279,
      },
      {
        date: "2021-06-29",
        value: 0.7229811574697174,
      },
      {
        date: "2021-06-30",
        value: 0.7220212049814878,
      },
      {
        date: "2021-07-01",
        value: 0.7239397509256144,
      },
    ],
  },
]

module.exports = markets
