import React, { Fragment } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const FXDropDown = ({ selected, setSelected, data }) => {
  const markets = data

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block pt-3 px-3 text-gray-400 text-sm font-medium uppercase md:p-1">
            Select your market
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative pl-3 pr-10 py-2 w-full text-left focus:border-indigo-500 rounded-md focus:outline-none cursor-default focus:ring-indigo-500 focus:ring-1 sm:text-sm">
              <span className="block text-3xl font-bold truncate">
                {selected.flag} {selected.name}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 py-1 w-full max-h-60 text-base bg-white rounded-md focus:outline-none shadow-lg overflow-auto ring-black ring-opacity-5 ring-1 sm:text-sm"
              >
                {markets.map(market => (
                  <Listbox.Option
                    key={market.name}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative pl-3 pr-9 py-2 cursor-default select-none"
                      )
                    }
                    value={market}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            " flex truncate "
                          )}
                        >
                          <span className="w-6">{market.flag}</span>
                          <>{market.name}</>
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default FXDropDown
