import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import React, { useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"
import { useForm } from "react-hook-form"

const Label = ({ text, htmlFor, tooltip }) => {
  return (
    <label
      htmlFor={htmlFor}
      className="block text-gray-700 text-sm font-medium"
    >
      {tooltip && (
        <div className="align-center flex space-x-1">
          <ReactTooltip
            id={`${htmlFor}_tooltip`}
            effect="solid"
            multiline={true}
            backgroundColor={"#000"}
          >
            <span>{tooltip}</span>
          </ReactTooltip>
          <span>{text}</span>

          <span
            className="hidden mt-0.5 w-4 h-full text-gray-500 lg:block"
            data-tip
            data-for={`${htmlFor}_tooltip`}
          >
            <QuestionMarkCircleIcon />
          </span>
        </div>
      )}
      {!tooltip && text}
    </label>
  )
}

const FXWidgetForm = ({ market, fxRate, setFxRate, setLaunchDateIndex }) => {
  const defaultValues = {
    launchDate: null,
    localMSRP: null,
    usMSRP: null,
    exchangeRate: null,
  }

  const [formValues, setFormValues] = useState(defaultValues)
  const [fxRateTouched, setFXRateTouched] = useState(false)
  const { register, handleSubmit, watch, reset, errors } = useForm()
  const formInputs = watch()

  useEffect(() => {
    reset(defaultValues)
  }, [market])

  useEffect(() => {
    const { launchDate, localMSRP, usMSRP, exchangeRate } = formInputs

    if (!fxRateTouched && usMSRP && localMSRP) {
      const us = parseFloat(usMSRP)
      let local = parseFloat(localMSRP)
      local = local / (1 + market.vat)

      setFxRate(Math.round((local / us) * 100, 2) / 100)
    } else if (fxRateTouched && exchangeRate) {
      setFxRate(Math.round(exchangeRate * 100, 2) / 100)
    }

    if (launchDate) {
      let smallestIndex = 0
      let smallestDifference = Number.POSITIVE_INFINITY
      const lanchDateTransformed = new Date(launchDate)

      for (let i = 0; i < market.fxRates.length; i++) {
        const day = new Date(market.fxRates[i].date)
        const dateDiff = Math.abs(lanchDateTransformed - day)

        if (dateDiff <= smallestDifference) {
          smallestDifference = dateDiff
          smallestIndex = i
        }
      }

      setLaunchDateIndex(smallestIndex)
    }
  }, [formInputs, fxRateTouched])

  return (
    <>
      <div className="md: flex flex-col gap-y-4 p-4 w-full bg-white border rounded shadow-sm space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
        <div className="w-full lg:w-1/4">
          <Label
            htmlFor="launchDate"
            text="Launch Date"
            tooltip="Select the date when you launched your product in this market."
          />
          <div className="mt-1">
            <input
              type="date"
              defaultValue={null}
              name="launchDate"
              id="launchDate"
              min="2019-01-01"
              max="2021-05-11"
              className="block w-full border-gray-300 focus:border-indigo-500 rounded-md shadow-sm focus:ring-indigo-500 sm:text-sm"
              placeholder="6/1/2020"
              ref={register}
            />
          </div>
        </div>

        <div className="w-full lg:w-1/4">
          <Label
            htmlFor="localMSRP"
            text="Local MSRP"
            tooltip="Input the sticker price (MSRP) in the foreign market, including VAT or other taxes."
          />
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm">
                {market.currencySymbol}
              </span>
            </div>
            <input
              type="text"
              defaultValue={formValues.localMSRP || null}
              min="0"
              name="localMSRP"
              id="localMSRP"
              className="block pl-8 w-full border-gray-300 focus:border-indigo-500 rounded-md shadow-sm focus:ring-indigo-500 sm:text-sm"
              placeholder={market.placeholderValue}
              ref={register}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                {market.currency}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4">
          <Label
            htmlFor="usMSRP"
            text="US MSRP"
            tooltip="Enter your US sticker price (MSRP)"
          />

          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              defaultValue={formValues.usMSRP || null}
              name="usMSRP"
              min="0"
              id="usMSRP"
              className="block pl-8 w-full border-gray-300 focus:border-indigo-500 rounded-md shadow-sm focus:ring-indigo-500 sm:text-sm"
              placeholder="119.99"
              ref={register}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4">
          <label
            htmlFor="exchangeRate"
            className="block text-gray-700 text-sm font-medium"
            tooltip="Enter the sticker price (MSRP) in the foreign market, including VAT or other taxes."
          >
            Your Exchange Rate
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              name="exchangeRate"
              defaultValue={fxRate || null}
              min="0"
              id="exchangeRate"
              className="block w-full border-gray-300 focus:border-indigo-500 rounded-md shadow-sm focus:ring-indigo-500 sm:text-sm"
              placeholder={
                fxRate ||
                Math.round((market.placeholderValue / 119.99) * 100, 2) / 100
              }
              onFocus={() => setFXRateTouched(true)}
              onBlur={() => setFXRateTouched(false)}
              ref={register}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                {market.currency} / USD
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FXWidgetForm
