import React, { useEffect, useState } from "react"
import markets from "../data/fxEffectsData"
import FXWidgetChart from "./FXWidget/FXWidgetChart"
import FXDropDown from "./FXWidget/FXDropDown"
import FXWidgetResults from "./FXWidget/FXWidgetResults"
import FXWidgetForm from "./FXWidget/FXWidgetForm"
import FXWidgetCTA from "./FXWidget/FXWidgetCTA"

const FXEffectsWidget = () => {
  const [selected, setSelected] = useState(markets[0])
  const [formValues, setFormValues] = useState({
    launchDate: null,
    localMSRP: null,
    usMSRP: null,
    nate: null,
  })

  const [fxRate, setFxRate] = useState(null)
  const [launchDateIndex, setLaunchDateIndex] = useState(0)

  useEffect(() => {
    setFxRate(null)
  }, [selected])

  return (
    <>
      {!markets && <div />}
      {markets && (
        <div className="mx-auto max-w-7xl">
          <div className="my-16">
            <div className="md:flex md:-mr-4">
              <div className="flex flex-col w-full bg-gray-100 border rounded-l shadow-sm space-y-4 md:p-8 md:w-2/3">
                <div>
                  <FXDropDown
                    selected={selected}
                    setSelected={setSelected}
                    data={markets}
                  />
                </div>
                <FXWidgetForm
                  market={selected}
                  fxRate={fxRate}
                  setFxRate={setFxRate}
                  setLaunchDateIndex={setLaunchDateIndex}
                />
                <div className="p-4 w-full bg-white border rounded shadow-sm">
                  <h2 className="flex flex-col p-1 pb-4">
                    <span className="text-gray-700 text-sm font-medium">
                      Exchange Rate History
                    </span>
                    <span className="text-gray-400 text-xs">
                      1/1/2019 - 7/1/2021
                    </span>
                  </h2>
                  <div className="h-96">
                    <FXWidgetChart
                      country={selected}
                      fxRate={fxRate}
                      launchDateIndex={launchDateIndex}
                    />
                  </div>
                </div>
              </div>
              <FXWidgetResults
                fxRate={fxRate}
                market={selected}
                localMSRP={formValues.localMSRP || 0}
                usMSRP={formValues.usMSRP || 0}
                launchDateIndex={launchDateIndex}
              />
            </div>
          </div>

          <FXWidgetCTA
            url="/contact-sales"
            linkLabel="Get in touch"
            mainText="Get better at pricing. Try Pricelist today."
          />
        </div>
      )}
    </>
  )
}

export default FXEffectsWidget
