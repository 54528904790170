import Layout from "../components/Layout"
import React from "react"
import FXEffectsWidget from "../components/FXEffectsWidget"
import { PageHeading } from "../components/PageHeading"

const FXWidget = () => {
  return (
    <Layout
      title={`Product Pricing and Foreign Currency Rates Estimator | Pricelist`}
      keywords={`Pricelist, foreign exchange, exchange rates, currency rates, foreign currency, 
        fx rates, vat, value added tax, demand, demand estimate, purchasing power parity, international pricing strategy`}
    >
      <div className="relative px-0 sm:px-6 lg:px-8">
        <div className="mx-auto pb-8 max-w-prose text-lg border-b">
          <PageHeading
            title="Exchange Rates Volatility Effects on Prices"
            subtitle="Evaluate international pricing strategy"
          />
        </div>
        <div className="prose prose-indigo prose-base sm:prose-lg mt-6 mx-auto text-gray-700">
          <p className="text-center">
            Pricelist developed a new tool that provides insights into the
            effects of currency fluctuations on your product pricing and
            estimated demand across countries.
          </p>
        </div>
        <FXEffectsWidget />
        <div className="mb-16 mt-6 mx-auto max-w-7xl text-gray-700">
          <h2 className="py-5 text-xl font-bold">
            International Pricing Strategy Considerations
          </h2>

          <div className="flex flex-col gap-4 p-0.5 w-full text-gray-500 sm:flex-row">
            <ConsiderationBox title="Sales">
              <p className="pt-2 text-sm border-t border-gray-200">
                Currency rates have a direct effect on the end price in the
                foreign market.
              </p>
              <p className="pt-2 text-sm">
                Prices pegged at a currency value lower than the prevailing
                exchange rate make the abroad product cheaper and lead to higher
                sales relative to the domestic market.
              </p>
              <p className="pt-2 text-sm">
                The opposite is true when the pegged value is higher.
              </p>
            </ConsiderationBox>
            <ConsiderationBox title={`"Sticky" Prices`}>
              <p className="pt-2 text-sm border-t border-gray-200">
                Product prices tend to be "sticky" because it is difficult and
                costly to change them once they enter the market.
              </p>
              <p className="pt-2 text-sm">
                Product marketing invests in building awareness for a product at
                a specific price point. Consumers expect that price at the point
                of sales, regardless of currency movements between markets.
              </p>
            </ConsiderationBox>
            <ConsiderationBox title="Profit Margins">
              <p className="pt-2 text-sm border-t border-gray-200">
                Currency fluctuations affect profit margins. When end prices do
                not change and exchange rates decline, a company receives less
                money when they convert foreign sales into domestic currency.
              </p>
              <p className="pt-2 text-sm">
                In contrast, a stronger exchange rate brings additional margin
                per unit sold. However, the overall demand might be negatively
                affected.
              </p>
            </ConsiderationBox>
            <ConsiderationBox title="Cross-Border Arbitrage">
              <p className="pt-2 text-sm border-t border-gray-200">
                Cross-border arbitrage opportunities emerge when products do not
                adhere to a consistent pricing strategy across markets.{" "}
              </p>

              <p className="pt-2 text-sm">
                By using different assumptions about exchange rates, taxes, or
                markups across markets, companies risk disadvantaging customers
                in one country relative to another and destroying their brand
                goodwill.
              </p>
            </ConsiderationBox>
          </div>
          <div className="mt-12 border-t">
            <h2 className="py-5 text-gray-500 text-base font-medium">
              Sources, notes, and terms of use
            </h2>

            <ul className="text-gray-400 text-xs leading-loose list-inside list-disc">
              <li>
                Foreign currency data are provided by the European Central Bank.
              </li>
              <li>
                Value Added Tax and Goods and Services Tax rates were sourced
                from official national government resources.
              </li>
              <li>Purchasing Power Parity statistics were provided by OECD.</li>
              <li>
                Price estimates are provided as-is and do not represent any
                advice or recommendation by Pricelist.
              </li>
              <li>Pricelist does not store any data entered in the form.</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const ConsiderationBox = ({ title, children }) => (
  <div className="px-4 py-6 w-full bg-gray-50 bg-gradient-to-b rounded-sm shadow from-white to-gray-50 sm:w-1/4">
    <h3 className="pb-2 text-gray-700 text-base font-medium">{title}</h3>
    {children}
  </div>
)

export default FXWidget
